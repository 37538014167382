let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestGetGasPrices = async (user_token, looking_for) => {
  let response;
//   const user_token = window.localStorage.getItem("user_token");
  
  let url = `${API_URL}gas-prices?gas_price_city_name=${looking_for?.gas_price_city_name}&date=${looking_for?.date}`;

  await fetch(url, {
    headers: {
      Authorization: user_token,
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });

  return response;
};

export default requestGetGasPrices;
