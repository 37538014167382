import React from 'react';
import { ProtectedRoute } from '../../app/ProtectedRoute';
//components
import { FiHelpCircle } from "react-icons/fi";
import Layout from '../../components/layout/Layout';
import FilterRouteBar from './FilterRouteBar';
import CustomToolTip from '../../components/layout/CustomToolTip';


const RoutePlanning = ({ routes }) => {
    return (<Layout>
        <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
            <h1 className="heading-h1 fw-bold">
                Plan your route
              <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={"Plan your route"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
            </h1>
            <p className="subheading-top">Here, you can create your routes and find trips</p>
            <FilterRouteBar />
            <div className="divider-3"></div>
            {routes.Routes.map((item, index) =>
                <ProtectedRoute
                    path={item.path}
                    routes={item}
                    component={item.Component}
                    key={index}
                    exact={item.isExact} />
            )}
        </div>
    </Layout>);
}

export default RoutePlanning;