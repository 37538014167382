let API_KEY = process.env.REACT_APP_API_KEY_MAP;

const requestReverseGetPlace = async (lat, lng) => {
  let response;
  
  let url = `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lng}&type=city&limit=1&format=json&apiKey=${API_KEY}`;

  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });

  return response;
};

export default requestReverseGetPlace;
