import React, { useState } from 'react';
import { PropTypes } from "prop-types";
import imgEmpty from '../../assets/images/empty.svg';
import imgUser from "../../app/images/mask47.png";
import { FiSend, FiTrash2 } from 'react-icons/fi';
import { useEffect } from 'react';
import requestInfoUserByEmail from '../../services/user/requestInfoUserByEmail';
import { useSelector } from 'react-redux';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';

const MessageViewer = ({ message, handle, deleteConversation }) => {
    const authCheck = useSelector(selectGuardianState);
    const [author, setAuthor] = useState(null);
    useEffect(() => {
        const getInfoAuthor = async () => {
            const res = await requestInfoUserByEmail(authCheck, message?.conversation?.createdBy);
            if (res.state === 200) {
                setAuthor(res.data);                
            }
        }
        getInfoAuthor();
    }, [message])
    
    return (
        <div className='viewer-content'>
            {message?.conversation ?
                <div className='p-3'>
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-3">
                        <h2 className='heading-h2 fw-bold mb-0'>{message?.conversation?.friendlyName ?? 'Subject'}</h2>
                        <div>
                            <FiTrash2 className='icon-h2 me-2' onClick={() => deleteConversation(message?.conversation)} />
                            <FiSend className='icon-h2 ms-2' onClick={() => handle('answering')} />
                        </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap align-items-center mb-3">
                        <div className='me-2'>
                            <img src={imgUser} alt="" />
                        </div>
                        <div>
                            <p className='name-user-mail mb-0'>{author?.user_name ?? 'Created by'}</p>
                            <p className='text-user-mail mb-0'>{author?.user_last_name ?? ''}</p>
                        </div>
                    </div>
                    {/* <div className='mb-3'>
                        <img src={imgInboxTemplate} alt="" className='img-fluid' />
                    </div> */}
                    {message?.messages?.map((i, index) =>
                        <div key={index} className="border-bottom">
                            <p className='inbox-text mb-0'>
                                {i.body}
                            </p>
                            <small className='text-white'> <i>{i.author} - {i.dateCreated.simpleFormatDate()}</i> </small>
                        </div>
                    )}
                </div>
                :
                <div className='d-flex flex-column flex-wrap align-items-center justify-content-center h-100'>
                    <img src={imgEmpty} alt="" />
                    <h1 className="heading-h1 fw-bold my-3">Your inbox </h1>
                    <p className="subheading-top text-center">Here you will find all the alerts and notifications, <br /> receive and send messages</p>

                </div>
            }
        </div>);
}
MessageViewer.propTypes = {
    message: PropTypes.object,
}
export default MessageViewer;