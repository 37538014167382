export function useCookies(name, value, minutes) {
    const createCookie = () => {
        let expires;
        if (minutes) {
            let date = new Date();
            date.setTime(date.getTime() + (minutes * 24 * 60 * 60 * 1000));
            console.log(date);
            expires = "; expires=" + date.toGMTString();
        }
        else expires = "";
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    const readCookie = () => {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    const deleteCookie = () => {
        createCookie(name, "", -1);
    }


    return { createCookie, readCookie, deleteCookie };
}
