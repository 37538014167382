import React from 'react';
import { Form } from 'react-bootstrap';
import PlaceAutocomplete from './PlaceAutocomplete';

const CustomInput = ({ value, onChange, className, type, setCity, ...rest }) => {
    let options;
    if (rest?.options) {
        options = rest?.options.map((element, index) =>
            <option key={index} value={element}>{element}</option>
        );
    }

    switch (type) {
        case type === "text" ? "text" : (type === "password" ? 'password' : (type === "number" ? "number" : (type === "tel" ? "tel" : (type === "email" ? "email":"search")))):
            return (
                <input className={className}
                    type={type}
                    placeholder={rest?.placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    defaultValue={value}
                />

            );
        case "radio":
            return rest?.options.map((i, index) => (
                <div key={index}>
                    <label className="containerLabelRadio form-label fw-bold">{i.name}
                        <input
                            key={index}
                            type={type}
                            id={i.id}
                            defaultValue={i.id}
                            onChange={(e) => {onChange(e.target.value); console.log(parseInt(e.target.value) === i.id);} }
                            checked={parseInt(value) === i.id} />
                        <span className="checkmark"></span>
                    </label>
                    <div className='divider-3'></div>
                </div>
            ));
        case "checkbox":
            return (
                <Form.Check
                    type={type}
                    label={rest?.label}
                    defaultValue={false}
                    onChange={() => onChange()}
                />
            );
        case "select":
            return (
                <select className="form-select mb-2" onChange={(e) => onChange(e.target.value)} value={value}>
                    {options}
                </select>
            );
            case "autocomplete":
            return (
                <PlaceAutocomplete
                  className={"form-control"}
                  defaultValue={value}
                  setValue={setCity}
                />
            );
        default:
            return null;
    }
};

CustomInput.defaultProps = {
    className: 'form-control mb-2'
}

export default CustomInput;