import React, { useEffect } from "react";
import { FiHelpCircle } from "react-icons/fi";
import Layout from "../../components/layout/Layout";
import { useHistory } from "react-router-dom";
import { ProtectedRoute } from "../../app/ProtectedRoute";
import GeneralTabs from "../../components/GeneralTabs";
import DesignedRoutes from "./Tabs/DesignedRoutes/DesignedRoutes";
import BookRoutes from "./Tabs/BookRoutes/BookRoutes";
import CustomToolTip from "../../components/layout/CustomToolTip";

const MyRoutesIndex = ({ routes }) => {
  const history = useHistory();
  useEffect(() => {
    history.push(`/my-routes/my_designed_routes`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoTabs = {
    my_designed_routes: {
      id: 1,
      title: "My designed routes",
      child: <DesignedRoutes />,
    },
    book_routes: {
      id: 2,
      title: "Book routes",
      child: <BookRoutes />,
    },
  };
  return (
    <>
      <ProtectedRoute path={routes?.path} routes={routes?.Routes} exact={true}>
        <Layout>
          <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4 content-main-margin">
            <h1 className="heading-h1 fw-bold">
              Admin. your routes
              <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={"Admin. your routes"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
            </h1>
            <p className="subheading-top">
              Here, you can administrate your routes
            </p>
            <GeneralTabs
              data={infoTabs}
              dataRoute={routes}
              route={"my-routes"}
              icon={false}
            />
          </div>
        </Layout>
      </ProtectedRoute>
      {routes.Routes.map((item, index) => (
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact}
        />
      ))}
    </>
  );
};

export default MyRoutesIndex;
