/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectGuardianState } from "../../redux/auth/GuardianSlice";
import requestSaveRouteSearchDetail from "../../services/user_routes/requestSaveRouteSearchDetail";
import requestUpdateRouteSearchDetail from "../../services/user_routes/requestUpdateRouteSearchDetail";
import HoverableSaveButton from "../HoverableSaveButton";
import Swal from "sweetalert2";

const SaveSettings = (props) => {
	const {
		showSaveSettings,
		setShowSaveSettings,
		folderList = [],
		action = false,
	} = props;

	const default_details = useSelector((state) => state.route.default_details);
	const user_token = useSelector(selectGuardianState);

	const handleClose = () => setShowSaveSettings(false);

	const [name, setName] = useState();

	const saveDefaultDetails = async () => {
		if (!name) {
			return;
		}
		let form = { ...default_details, rsd_name: name };
		const response = await requestSaveRouteSearchDetail(user_token, form);
		response.state == 200 && action && action();
		response.state == 200 && alertSave(form.rsd_name);
		setName();
	};

	const updateDefaultDetails = async (id, rsd_name) => {
		let form = { ...default_details, rsd_name };
		name && (form.rsd_name = name);
		const response = await requestUpdateRouteSearchDetail(
			user_token,
			id,
			form
		);
		response.state == 200 && action && action();
		response.state == 200 && alertSave(form.rsd_name);
	};

	const alertSave = (rsd_name) => {
		let timerInterval;
		Swal.fire({
			title: "Settings saved in",
			input: "text",
			background: "#121A1F",
			inputPlaceholder: rsd_name,
			showCancelButton: false,
			showConfirmButton: false,
			timer: 3000,
			willClose: () => {
				clearInterval(timerInterval);
				handleClose();
			},
		});
	};

	return (
		<>
			<Modal
				show={showSaveSettings}
				onHide={handleClose}
				centered
				dialogClassName="modal-70w"
				className="modalSave"
			>
				<Modal.Header>
					<Modal.Title>Save settings</Modal.Title>
					<FiX className="btn-closeModal" onClick={handleClose} />
				</Modal.Header>

				<div className="d-flex flex-row flex-wrap w-100">
					<div className="col-md-12">
						<p className="letterModal">Save as</p>
						<HoverableSaveButton
							data={{}}
							onChange={(event) => setName(event.target.value)}
							placeholder="Default name"
							onSave={saveDefaultDetails}
							childHasToClose={() => handleClose()}
						/>

						<div className="d-flex flex-row flex-wrap w-100 contentSave align-content-start mt-3">
							{folderList?.map((item, index) => (
								<HoverableSaveButton
									key={index}
									onChange={(event) =>
										setName(event.target.value)
									}
									onSave={() =>
										updateDefaultDetails(
											item.rsd_id,
											item.rsd_name
										)
									}
									data={item}
								/>
							))}
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default SaveSettings;
