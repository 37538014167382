import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useCookies } from "../hooks/useCookies";
import { selectGuardianState } from "../redux/auth/GuardianSlice";

export const ProtectedRoute = ({ route, component: Component, ...rest }) => {
    const isLogged = useSelector(selectGuardianState);
    const { readCookie } = useCookies('tokenCounter');
    const checkCookies = readCookie();

    return (
        <Route {...rest}
            render={
                (props) => {
                    if (isLogged !== null && checkCookies) {
                        return <Component {...props} {...rest} />
                    }
                    return <Redirect to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }} />
                }
            }
        />
    )
}
