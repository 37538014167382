import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    notifications_list: [],
};
export const NotificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.notifications_list = [...state.notifications_list, action.payload];
        },
    },
});

export const {
    setNotification,
} = NotificationsSlice.actions;
export const selectnotifications_listState = (state) => state.notifications.notifications_list;


export default NotificationsSlice.reducer;
