import React, { useState, useEffect } from 'react';
import { FiPlus } from "react-icons/fi";
import VariableCostItem from '../DefaultSetting/VariableCostItem';
import { ProtectedRoute } from '../../../../app/ProtectedRoute';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import requestStandardVehicleValues from '../../../../services/default_settings/requestStandardVehicleValues';
import requestDeleteStandardVehicleValue from '../../../../services/default_settings/requestDeleteStandardVehicleValue';
import { selectGuardianState } from '../../../../redux/auth/GuardianSlice';

const DefaultSetting = ({ routes }) => {

    const [dataCost, setDataCost] = useState([]);
    const authCheck = useSelector(selectGuardianState);

    async function fetchData() {
        const res = await requestStandardVehicleValues(authCheck);
        if (res.state === 200) {
            return setDataCost(res.data);
        }
    }

    function deleteVariableCost(id) {
        if (!id) {
            return;
        }
        Swal.fire({
            title: 'Are you sure? ',
            text: "Do you want to remove this variable cost?",
            background: '#121A1F',
            showCancelButton: true,
            showDenyButton: false,
            reverseButtons: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'REMOVE',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // eslint-disable-next-line no-unused-vars
                const responseDelete = await requestDeleteStandardVehicleValue(id, authCheck);
                const deleteItem = dataCost.findIndex((dataCost) => dataCost.std_vehicle_val_id === id);
                dataCost.splice(deleteItem, 1);
                setDataCost(dataCost);
                fetchData();
            }
        })
    };

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='mt-3 mb-5'>
                <div className='mt-3 mb-5 flex-system-content'>
                    <div className="d-flex flex-row">
                        <div className="initial-element">Variable cost</div>
                        <div className='middle-element middle-element-text'>
                            {dataCost.map((item, index) =>
                                <VariableCostItem data={item} actionDelete={deleteVariableCost} key={index} />
                            )}
                            <Link to={`/settings/default_settings/variable_cost/create`}>
                                <div className="d-flex flex-row p-2 w-100">
                                    <FiPlus className='icon-settings me-3' />
                                    <p className='fs-6 fw-bold mb-0 p-0'>New variable cost</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {routes.Routes.map((item, index) => (
                <ProtectedRoute
                    path={item.path}
                    routes={item}
                    component={item.Component}
                    key={index}
                    exact={item.isExact} />
            ))}
        </>
    )
}

export default DefaultSetting