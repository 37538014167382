import React from "react";
import { FiCornerUpLeft } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ProtectedRoute } from "../../app/ProtectedRoute";
import FullLayout from "../../components/layout/FullLayout";

const InfoEfRouting = ({ routes }) => {
  const history = useHistory();
  let { url } = useRouteMatch();

  const goBack = () => {
    if (url) {
      if (url !== "/dashboard") {
        history.goBack();
      }
    }
    return;
  };

  return (
    <>
      <ProtectedRoute path={routes?.path} routes={routes?.Routes} exact={true}>
        <FullLayout>
          <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
            <div className="col-md-5">
              <div className="d-flex flex-row flex-wrap">
                <FiCornerUpLeft
                  onClick={() => goBack()}
                  className="go-back-arrow me-3"
                />
                <h1 className="heading-h1 fw-bold text-capitalize">
                  efRouting
                </h1>
              </div>
              <div className="d-flex flex-row flex-wrap">
                <p className="subheading-top mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  efficitur vulputate maximus. Fusce rutrum fringilla risus, vel
                  sodales augue convallis et. Phasellus porta mollis pharetra.
                </p>
                <div className="divider-3 mb-0"></div>
                <div className="col-md-12 d-flex flex-row flex-wrap flex-gaping mt-3">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-outline-success btnHelp w-100"
                    >
                      GUIDE: SIGN UP
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-outline-success btnHelp w-100"
                      onClick={() => history.push('/help/ef-routing/guide-efdata')}
                    >
                      GUIDE: EFDATA
                    </button>
                  </div>
                </div>
                <div className="col-md-12 d-flex flex-row flex-wrap flex-gaping mt-3">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-outline-success btnHelp w-100"
                    >
                      GUIDE: BEST PRACTICES
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-outline-success btnHelp w-100"
                    >
                      GUIDE: SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FullLayout>
      </ProtectedRoute>
      {routes.Routes.map((item, index) => (
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact}
        />
      ))}
    </>
  );
};

export default InfoEfRouting;
