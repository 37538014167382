import React from 'react';

const CustomSplash = () => {
    return (
        <>
            <div className='bg-splash d-flex flex-row align-items-center justify-content-center'>
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </>
    );
}

export default CustomSplash;