/* eslint-disable eqeqeq */
import requestGetParameterValues from "../../../services/parameters/requestGetParameterValues";

const getBenchmarkValues = async (user_token) => {
    const miles_response = await requestGetParameterValues('benchmark_miles', user_token);
    const co2_emission_response = await requestGetParameterValues('benchmark_co2_emission', user_token);
    const income_response = await requestGetParameterValues('benchmark_income', user_token);
    const profit_response = await requestGetParameterValues('benchmark_profit', user_token);

    if (miles_response?.state != 200 || co2_emission_response?.state != 200 || income_response?.state != 200 || profit_response?.state != 200) {
        return null;
    }

    return {
        miles: miles_response?.data ?? [],
        co2_emission: co2_emission_response?.data ?? [],
        income: income_response?.data ?? [],
        profit: profit_response?.data ?? []
    };
}

export default getBenchmarkValues;