/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useState } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import ChartComponent from "../../../components/ChartComponent";
import BenchmarkStatistical from "./BenchmarkStatistical";

const BenchmarkSection = (props) => {
	const { benchmarkValues, setCollapsed, collapsed = false, data = [{}], defaultIncomeTotals, incomeTotals, totals } = props;

	const [dropdownData, setDropdownData] = useState();
	const [item, setItem] = useState(data[0]);
	const [values, setValues] = useState();
	// eslint-disable-next-line no-unused-vars
	const [graphData, setGraphData] = useState();
	
	useEffect(() => {
		setDropdownData(data);
	}, [data]);

	const getMessageOrIcon = (value, key, variable = "message") => {
		if (value > 0) {
			return variable == "icon" ? 1 : `Higher than the ${key} national`;
		}
		if (value < 0) {
			return variable == "icon" ? 2 : `Lower than the ${key} national`;
		}
		if (value == 0) {
			return variable == "icon" ? 3 : `Of the ${key} national`;
		}
		return null;
	}

	useEffect(() => {
		if (!benchmarkValues) {
			return;
		}
		const median = benchmarkValues[item.id].find(element => element.pv_name == "median").pv_value ?? 0;
		const q_25 = benchmarkValues[item.id].find(element => element.pv_name == "q_25").pv_value ?? 0;
		const q_75 = benchmarkValues[item.id].find(element => element.pv_name == "q_75").pv_value ?? 0;
		const min = benchmarkValues[item.id].find(element => element.pv_name == "min").pv_value ?? 0;
		const avg = benchmarkValues[item.id].find(element => element.pv_name == "avg").pv_value ?? 0;
		const max = benchmarkValues[item.id].find(element => element.pv_name == "max").pv_value ?? 0;
		const calculated = {
			median,
			q_25,
			q_75,
			min,
			avg,
			max,
		};
		const values = {
			avg: { diff: Math.abs(item.data.avg - avg).toFixed(2), message: getMessageOrIcon(item.data.avg - avg, "average"), icon: getMessageOrIcon(item.data.avg - avg, "average", "icon") },
			median: { diff: Math.abs(item.data.median - median).toFixed(2), message: getMessageOrIcon(item.data.median - median, "median"), icon: getMessageOrIcon(item.data.median - median, "median", "icon") },
		};
		if (item.id == "profit" || item.id == "income") {
			values.min = { diff: Math.abs(item.data.min - avg).toFixed(2), message: getMessageOrIcon(item.data.min - avg, "average"), icon: getMessageOrIcon(item.data.min - avg, "average", "icon") };
			values.max = { diff: Math.abs(item.data.max - avg).toFixed(2), message: getMessageOrIcon(item.data.max - avg, "average"), icon: getMessageOrIcon(item.data.max - avg, "average", "icon") };
		}
		setGraphData(calculated);
		setValues(values);
	}, [item, data, benchmarkValues, totals]);

	const info = [
		{
			x: 'National',
			y: [graphData?.max, graphData?.q_75, graphData?.q_25, graphData?.min],
		},
		{
			x: 'Proposed route',
			y: [defaultIncomeTotals?.route_max_income, 0, 0, defaultIncomeTotals?.route_min_income],
		},
		{
			x: 'Custom route',
			y: [incomeTotals?.max_income, 0, 0, incomeTotals?.min_income?.toFixed(2)],
		},
	];

	const chartOptions = {
		chart: {
			// type: type,
			background: "transparent",
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			candlestick: {
				colors: {
					upward: "#209E59",
					downward: "#3E4D6B",
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		yaxis: {
			labels: {
				formatter: function (y) {
					return y?.toFixed(0) + "%";
				},
			},
			tooltip: {
				enabled: true,
			  }
		},
		xaxis: {
			type: "datetime",
		},
	};

	return (
		<div className="card-body p-3 ps-4 card-hover">
			<div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
				<p className="subheading-title fw-bold mb-0">Benchmark</p>
				{collapsed && (
					<div className="d-flex flex-row flex-wrap col-9 justify-content-between">
						<div className="drop-benchmarking">
							<Dropdown>
								<Dropdown.Toggle>
									<div className="d-flex flex-row flex-wrap align-items-center">
										<div className="me-2">
											<FiChevronDown className="btn-clean text-name-cargo icon-sidebar-drop" />
										</div>
										<div className="text-start">
											<p className="text-name-cargo fw-bold mb-0">
												{item.title}
											</p>
											<p className="text-name-cargo mb-0">
												{item.subtitle}
											</p>
										</div>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{dropdownData?.map((item, index) => (
										<Dropdown.Item
											key={index}
											onClick={() => setItem(dropdownData[index])}
										>
											<div className="d-flex flex-row flex-wrap align-items-center">
												<div className="ms-4">
													<p className="text-name-cargo fw-bold mb-0">
														{item.title}
													</p>
													<p className="text-name-cargo mb-0">
														{item.subtitle}
													</p>
												</div>
											</div>
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				)}
				<button
					type="button"
					aria-controls="one"
					className="btn-clean text-white"
					onClick={() => setCollapsed(!collapsed)}
				>
					{!collapsed ? <FiChevronDown /> : <FiChevronUp />}
				</button>
			</div>
			<Collapse in={collapsed}>
				<div id="three">
					<div className="d-flex flex-row flex-wrap">
						<div className="col-md-7">
							<ChartComponent
								data={info}
								chartOptions={chartOptions}
								type={"candlestick"}
							// height={"100%"}
							/>
						</div>
						<div className="col-md-5">
							<div className="d-flex flex-row flex-wrap">
								<div className="col-md-6 p-3">
									<BenchmarkStatistical
										title={`Your average ${item?.id} is`}
										value={values?.avg?.diff ?? "---"}
										iconType={values?.avg?.icon}
										value_description={values?.avg?.message}
									/>
								</div>
								<div className="col-md-6 p-3">
									<BenchmarkStatistical
										title={`Your average ${item?.id} is`}
										value={values?.median?.diff ?? "---"}
										iconType={values?.median?.icon}
										value_description={values?.median?.message}
									/>
								</div>
								{item.high_n_low &&
									<>
										<div className="col-md-6 p-3">
											<BenchmarkStatistical
												title={`Your 25% low ${item?.id} is`}
												value={values?.min?.diff ?? "---"}
												iconType={values?.min?.icon}
												value_description={values?.min?.message}
											/>
										</div>
										<div className="col-md-6 p-3">
											<BenchmarkStatistical
												title={`Your 25% high ${item?.id} is`}
												value={values?.max?.diff ?? "---"}
												iconType={values?.max?.icon}
												value_description={values?.max?.message}
											/>
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</Collapse>
		</div>
	);
};

export default BenchmarkSection;
