import { createSlice } from "@reduxjs/toolkit";

export const blackBoxRoutesSlice = createSlice({
  name: "blackBoxRoutes",
  initialState: {},
  reducers: {
    setBBRoutes: (state, action) => {
      let data = action.payload;
      state.blackBoxRoutes = data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBBRoutes } = blackBoxRoutesSlice.actions;
export default blackBoxRoutesSlice.reducer;
