/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiCalendar, FiSliders, FiChevronDown, FiArrowUpRight, FiArrowDownRight } from "react-icons/fi";
import Calendar from 'moedim';
import styled from 'styled-components';
import SaveSettings from '../../components/modals/SaveSettings';
import { useHistory, useLocation } from 'react-router-dom';
import SearchableDropdown from '../../components/SearchableDropdown';
import { setBBRoutes } from "../../redux/blackBoxRoutes/blackBoxRoutesSlice";
import { useDispatch, useSelector } from 'react-redux';
import requestRoutes from '../../services/black_box/requestRoutes';
import Swal from 'sweetalert2';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';
import requestGetRouteSearchDetails from '../../services/user_routes/requestGetRouteSearchDetails';
import { useParameter } from '../../hooks/useParameter';
import { useForm } from 'react-hook-form';
import { setDefaultDetails, setRoutePlanningFilter } from '../../redux/route/routeSlice';
import { selectUserDataState } from '../../redux/user/UserSlice';

const FilterRouteBar = () => {
    const dispatch = useDispatch();
    
    const history = useHistory();
    let location = useLocation();
    const user_token = useSelector(selectGuardianState);
    const userInfo = useSelector(selectUserDataState);

    const { handleSubmit } = useForm();

    const load_sizes = useParameter("load_sizes");
    const trailer_types = useParameter("trailer_types");

    const [dropdown, setDropdown] = useState(false);
    const [dateValue, setDateValue] = useState(new Date());
    const [daysValue, setDaysValue] = useState('');
    const [showSaveSettings, setShowSaveSettings] = useState(false);
    const [departure, setDeparture] = useState();
    const [destination, setDestination] = useState();
    const [defaultValues, setDefaultValues] = useState([]);
    const [rsdId, setRsdId] = useState(0);
    const [loadSizeValue, setLoadSizeValue] = useState();
    const [trailerValue, setTrailerValue] = useState();
    const [rangeLength, setRangeLength] = useState(55); 
    const [rangeWeight, setRangeWeight] = useState(25000);
    
    const getRouteSearchDetails = async () => {
        const response = await requestGetRouteSearchDetails(user_token);
        response.state == 200 && setDefaultValues(response.data);
    } 

    useEffect(() => {
        getRouteSearchDetails();
        if(userInfo?.std_vehicle_val) {
            console.log('userInfo', userInfo?.std_vehicle_val);     
            setDeparture({
                state: userInfo?.std_vehicle_val?.std_vehicle_val_typical_origin_state,
                city: userInfo?.std_vehicle_val?.std_vehicle_val_typical_origin_city,
            });   
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
        setLoadSizeValue(load_sizes[0]);
        setTrailerValue(trailer_types[0]);
    }, [load_sizes, trailer_types]);

    let { from } = location.state || { from: { pathname: "/route-planning/search/results" } };

    const StyledCalendar = styled(Calendar)`
        --moedim-primary: #1c8c4f;
        width: 277px;
        border: none;
        color: #cacaca;
        background: transparent;
        padding: 16px 0px 16px 0px;
    `;

    const search = async () => {
         history.replace(from);
        Swal.fire({
            background: '#121A1F',
            title: 'Wait a minute',
            text: 'looking for possible routes',
            showConfirmButton: false,
            timer: 2500,
            width: '305px',
        })
        let params = [];
        const departure_city_name = (departure?.city ?? '');
        const departure_state_name = (departure?.state ?? '');
        const destination_city_name = (destination?.city ?? '');
        const destination_state_name = (destination?.state ?? '');
        departure && (params.push({ key: 'departure_city_name', value: departure_city_name }));
        departure && (params.push({ key: 'departure_state_name', value: departure_state_name }));
        destination && (params.push({ key: 'destination_city_name', value: destination_city_name }));
        destination && (params.push({ key: 'destination_state_name', value: destination_state_name }));
        dateValue && (params.push({key: 'date', value: dateValue.getFullDate()}));
        daysValue && (params.push({key: 'days', value: daysValue}))    
        let response = await requestRoutes(params, user_token);
        if (!response.data) {
            return;
        }
        let data = response.data ?? [];
        dispatch(setBBRoutes(data));
        const default_details = getDefaultDetails();
        dispatch(setDefaultDetails(default_details));
        const route_planning_filter = {
            ...default_details,
            departure_city_name: departure_city_name,
            departure_state_name: departure_state_name,
            destination_city_name: destination_city_name,
            destination_state_name: destination_state_name,
            date: dateValue?.toFormatMDY(),
            days: daysValue,
        };
        dispatch(setRoutePlanningFilter(route_planning_filter));
    }

    const loadDefaultDetails = (event) => {
        const rsd_id = event.target.value;
        setRsdId(rsd_id);
        const data = defaultValues.find(e => e.rsd_id == rsd_id);
        if(!data){
            return;
        }
        const load_size = load_sizes.find(e => e.pv_id == data.rsd_load_size);
        setLoadSizeValue(load_size);
        const trailer_type = trailer_types.find(e => e.pv_id == data.rsd_trailer_type);
        setTrailerValue(trailer_type);
        setRangeLength(data.rsd_max_length);
        setRangeWeight(data.rsd_max_weight);
    }

    const getDefaultDetails = () => {
        return {
            rsd_id: rsdId,            
            rsd_load_size: loadSizeValue.pv_id,
            rsd_load_size_name: loadSizeValue.pv_name,
            rsd_trailer_type:trailerValue.pv_id,
            rsd_trailer_type_name:trailerValue.pv_name,
            rsd_max_length: rangeLength,
            rsd_max_weight: rangeWeight
        };
    }

    const saveDefaultDetails = async () => {
        let form = getDefaultDetails();
        dispatch(setDefaultDetails(form));
        setShowSaveSettings(true); 
    }
    
    const arrDays = []
    for (let i = 0; i < 21; i++) {
        
           arrDays.push( <option value={i+1}>{i+1} {(i+1) === 1 ? 'Day' : 'Days'}</option>)
        
    }
    return (
        <div className='filterRouteBar ps-3 pb-1 pt-0'>
            <table className="table table-borderless">
                <tbody style={{ verticalAlign: 'middle' }}>
                    <tr>
                        <td className='pe-1 adjustable-width'>
                            <SearchableDropdown onChange={(item) => setDeparture(item)} itemClass={'sectionBar d-flex flex-row flex-wrap align-items-start ms-1'} title={'Departure'} itemId={'departure'} value={departure ? `${departure?.city}, ${departure?.state}` : ''} icon={<FiArrowUpRight className='icons-filterRouteBar' />} />
                        </td>
                        <td className='pe-1 adjustable-width'>
                            <SearchableDropdown onChange={(item) => setDestination(item)} itemClass={'sectionBar d-flex flex-row flex-wrap align-items-start ms-3'} title={'Destination'} itemId={'destination'} icon={<FiArrowDownRight className='icons-filterRouteBar' />} />
                        </td>
                        <td className='adjustable-width'>
                            <Dropdown className='sectionBar ms-2'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FiCalendar className='icons-filterRouteBar' />
                                    <div className='text-start'>
                                        <p className='route-name fw-bold mb-0'>When</p>
                                        <p className='route-desc mb-0'>{dateValue.simpleFormatDate()} - {daysValue}</p>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <label className='letterRouteBar'>Departure date</label>
                                    <StyledCalendar className='calendar' value={dateValue} onChange={(d) => setDateValue(d)} />
                                    <button onClick={() => setDropdown(!dropdown)} type="button" className="btnDetails">
                                        <span className='letterDetails'>Advance details</span>
                                        <FiChevronDown className={`icon-details ${dropdown ? "icon-details-rotate" : ''}`} />
                                    </button>
                                    {dropdown &&
                                        <div className='w-100'>
                                            <span className='letterDetails'>Max days en route</span>
                                            <select className="selectDetails w-100" value={daysValue} onChange={(event) => setDaysValue(event.target.value)}>
                                                <option value="">Select number of Days</option>
                                                {
                                                   arrDays
                                                }
                                            </select>
                                        </div>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td className='adjustable-width'>
                            <Dropdown className='sectionBar ms-2'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FiSliders className='icons-filterRouteBar' />
                                    <div className='text-start'>
                                        <p className='route-name fw-bold mb-0'>Detail</p>
                                        <p className='route-desc mb-0'>{loadSizeValue?.pv_name} - {trailerValue?.pv_name}</p>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <label className='letterRouteBar'>Details</label>
                                    <span className='letterDetails'>Defaults details</span>
                                    <form onSubmit={handleSubmit(saveDefaultDetails)}>
                                        <select defaultValue={rsdId} className="selectDetails w-100" onChange={loadDefaultDetails}>
                                            <option value="0">Defaults details</option>
                                            {defaultValues.map((item, index) => <option key={`dd${index}`} value={item.rsd_id}>{item.rsd_name}</option>)}
                                        </select>
                                        <span className='letterDetails'>Load size</span>
                                        <div className='d-flex flex-row justify-content-between mb-3'>
                                            {load_sizes.map((item, index) => item.pv_name != 'DEADHEAD' && (
                                                <div key={`ls${index}`} className="btn-load-size">
                                                    <input type="radio" checked={item.pv_id == loadSizeValue?.pv_id} id={`lsc-${item.pv_id}`} name="rsd_load_size" onChange={() => setLoadSizeValue(item)} />
                                                    <label className="btn btn-default pt-1" htmlFor={`lsc-${item.pv_id}`}>{item.pv_name}</label>
                                                </div>
                                            ))}
                                        </div>
                                        <span className='letterDetails'>Trailer type</span>
                                        <select className="selectDetails w-100" defaultValue={trailerValue?.pv_id}
                                            onChange={(event) => {
                                                const data = trailer_types.find(e => e.pv_id == event.target.value);
                                                setTrailerValue(data);
                                            }}>
                                            {trailer_types.map((item,index) => (
                                                <option key={`tt${index}`} value={item.pv_id}>{item.pv_name}</option>
                                            ))}
                                        </select>
                                        <button onClick={() => setDropdown(!dropdown)} type="button" className="btnDetails">
                                            <span className='letterDetails'>Advance details</span>
                                            <FiChevronDown className={`icon-details ${dropdown ? "icon-details-rotate" : ''}`} />
                                        </button>
                                        {dropdown &&
                                            <div className='w-100'>
                                                <div className='content-btn'>
                                                    <span className='letterDetails'>Max length</span>
                                                    <span className='boxDetails'>{rangeLength}ft</span>
                                                </div>
                                                <input type="range" className='ranges' min="0" max="55" value={rangeLength} onChange={(event) => setRangeLength(event.target.value)} />
                                                <div className='content-btn'>
                                                    <span className='letterDetails'>Max weight</span>
                                                    <span className='boxDetails'>{rangeWeight}kg</span>
                                                </div>
                                                <input type="range" className='ranges' min="0" max="25000" value={rangeWeight} onChange={(event) => setRangeWeight(event.target.value)} />
                                            </div>
                                        }
                                        <button type="submit" className="btn btn-outline-secondary btn-route-planning w-100">SAVE SETTINGS</button>
                                    </form>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td>
                            <div className="d-flex flex-row flex-wrap justify-content-end">
                                <button className='sectionButton w-100' onClick={search}>SEARCH</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <SaveSettings showSaveSettings={showSaveSettings} setShowSaveSettings={setShowSaveSettings} folderList={defaultValues} action={getRouteSearchDetails} />
        </div>
    )
}

export default FilterRouteBar