let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestUserRouteFolders = async (user_token, userId) => {
  let response;
//   const user_token = window.localStorage.getItem("user_token");
  
  let url = `${API_URL}user-route-folder?user_id=${userId}`;

  await fetch(url, {
    headers: {
      Authorization: user_token,
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });

  return response;
};

export default requestUserRouteFolders;
