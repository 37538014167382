import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FiX } from "react-icons/fi";

export const SaveItem = ({ value, placeholder, onChange, onSave}) => {
    const [isShown, setIsShown] = useState(false);
    return (
        <div className="col-md-12 dropSave" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <input
                type="text"
                placeholder={placeholder}
                onChange={onChange}
                className="form-control-plaintext"
                defaultValue={value}
                disabled
            />
            {isShown && (
                <button className="btnHoverSave" onClick={onSave}>
                    SAVE
                </button>
            )}
        </div>
    );
}

const ModalSave = ({ showSaveSettings, setShowSaveSettings, title, folderList, setIndicatorCat, actionSaveToCat }) => {


    const handleClose = () => setShowSaveSettings(false);
    // eslint-disable-next-line no-unused-vars
    const [name, setName] = useState();

    const saveDefaultDetails = async () => {
        console.log(folderList);
    };

    return (
        <>
            <Modal
                show={showSaveSettings}
                onHide={handleClose}
                centered
                dialogClassName="modal-70w"
                className="modalSave"
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <FiX className="btn-closeModal" onClick={handleClose} />
                </Modal.Header>

                <div className="d-flex flex-row flex-wrap w-100">
                    <div className="col-md-12">
                        <p className="letterModal">Save as</p>
                        <SaveItem
                            value={''}
                            onChange={(event) => setName(event.target.value)}
                            placeholder="Default name"
                            onSave={saveDefaultDetails}
                        />

                        <div className="d-flex flex-row flex-wrap w-100 contentSave align-content-start mt-3">
                            {folderList?.map((item, index) => (
                                <SaveItem
                                    key={index}
                                    onChange={(event) => setName(event.target.value)}
                                    onSave={() => actionSaveToCat(item)}
                                    value={item.indicator_category_name}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

ModalSave.defaultProps = {
    title: 'Save As',
    folderList: [],
}

SaveItem.defaultProps = {
    value: '',
    placeholder: '',
    onChange: false,
    onSave: false,
}

export default ModalSave;
