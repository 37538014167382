/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useParameter } from "../../hooks/useParameter";
import { selectGuardianState } from "../../redux/auth/GuardianSlice";
import { selectUserDataState } from "../../redux/user/UserSlice";
import requestSaveStandardVehicleValue from "../../services/default_settings/requestSaveStandardVehicleValue";
import SimpleModal from "../modals/SimpleModal";

function ItemVariableCost({actionClose}) {

  const userInfo = useSelector(selectUserDataState);
  const { registerForm } = useSelector((state) => state.registerForm);
  const authCheck = useSelector(selectGuardianState);

  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [check, setCheck] = useState(registerForm?.check ?? false);
  const [checkTWo, setCheckTwo] = useState(registerForm?.checkTWo ?? false);
  const [checkThree, setCheckThree] = useState(registerForm?.checkThree ?? false);
  const [total, setTotal] = useState(0);
  const [stdVehValBased, setStdVehValBased] = useState([]);
  const [stdVehValBasedData, setStdVehValBasedData] = useState([]);
  const [instanceKey, setInstanceKey] = useState(0);
  const [dataObj, setDataObj] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
    reset,
    watch,
  } = useForm();

  const trailer_types = useParameter("trailer_types");
  const std_veh_val = useParameter("std_veh_val");

  const section_one = [
    "std_vehicle_val_avg_miles_driven_pyear",
  ];

  const section_two = [
    "std_vehicle_val_trailer_type",
    "std_vehicle_val_fuel_pyear",
    "std_vehicle_val_maintenance",
    "std_vehicle_val_equipment",
    "std_vehicle_val_tire",
    "std_vehicle_val_permits",
    "std_vehicle_val_insurance",
  ];

  const section_three = [
    "std_vehicle_val_annual_wages",
    "std_vehicle_val_annual_benefits",
  ];

  useEffect(() => {
    let data = {};
    std_veh_val.map((element) => {
      let value = element.pv_data?.value ?? "";
      data[element.pv_name] = value;
    });
    setStdVehValBasedData(data);
    setTotal(registerForm?.total ?? 0);
  }, [std_veh_val]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => totalHandler(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const totalHandler = (value) => {
    let total = 0;
    let divisor = value.std_vehicle_val_avg_miles_driven_pyear;
    if (!divisor) {
      return setTotal(0);
    }
    delete value.std_vehicle_val_name;
    delete value.std_vehicle_val_avg_miles_driven_pyear;
    delete value.std_vehicle_val_trailer_type;
    let keys = Object.keys(value);
    keys.map(
      (element) =>
        parseFloat(value[element]) &&
        parseFloat(value[element]) != "" &&
        parseFloat(value[element]) != 0 &&
        (total += parseFloat(value[element]) / divisor)
    );
    setTotal(total.toFixed(2));
  };

  const handleReset = () => setInstanceKey((i) => i + 1);

  const loadStdVehValBased = async ({ checked = false, fields = [] }) => {
    let data = checked
      ? fields.filter((x) => !stdVehValBased.includes(x))
      : stdVehValBased.filter((x) => !fields.includes(x));
    checked && (data = [...stdVehValBased, ...data]);
    setStdVehValBased(data);
    checked
      ? data.map((element) => setValue(element, stdVehValBasedData[element]))
      : fields.map((element) => resetField(element));
    handleReset();
  };

  const handleSubmitter = async (data) => {
    let std_vehicle_values = { ...data, std_vehicle_val_user_id: userInfo.user_id, std_vehicle_val_total: parseFloat(total) };
    setDataObj({ ...std_vehicle_values });
  };

  const onError = (errors) => {
    setOpen(true);
    setOpenTwo(true);
    setOpenThree(true);
  };

  const createSetting = async (data) => {
    if (data) {
      setDataObj({ ...dataObj, ...data });
      let std_vehicle_values = {...dataObj, ...data, std_vehicle_val_typical_origin: []}
      let formData = {std_vehicle_values};
      try {
          const responseData = await requestSaveStandardVehicleValue(formData, authCheck);
        if (responseData.state === 200) {
          Swal.fire({
            background: '#121A1F',
            title: 'Wait a minute',
            text: 'Standard Vehicle Value successfully created',
            showConfirmButton: false,
            timer: 3000,
            width: '305px',
          });
        }
      } catch (error) {
        console.error(error);
      }
      reset();
      actionClose();
    }
  }


  return (
    <div className="m-3">
      <div className="col-md-12 pt-2 mb-4">
        <h1 className="heading-h1 fs-2 mb-0 fw-bold">Variable cost</h1>
        <h6 className="subtitle-graph">Calculate the cost of the lane</h6>
      </div>
      <div className="col-md-12 mt-4">
        <button className="btn-secondaryOff w-100"
          onClick={() => {
            setOpen(true);
            setCheck(true);
            loadStdVehValBased({
              checked: true,
              fields: section_one
            })
            setOpenTwo(true);
            setCheckTwo(true);
            loadStdVehValBased({
              checked: true,
              fields: section_two
            })
            setOpenThree(true);
            setCheckThree(true);
            loadStdVehValBased({
              checked: true,
              fields: section_three
            })
          }}
        >USE STANDARD VALUES</button>
      </div>
      <form onSubmit={handleSubmit(handleSubmitter, onError)}>
        <div className="col-md-12 mt-3">
          <div className="btnDropOff w-100">
            <div className="d-flex flex-row">
              <button
                type="button"
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                className="btn-clean text-white p-0"
              >
                {!open ? (<FiChevronDown className="icon-details" />) : (<FiChevronUp className="icon-details" />)}
              </button>
              <p className="collapse-title">General</p>
            </div>
            <div className="me-1">
              <input
                className="form-check-input"
                type="checkbox"
                checked={check}
                onChange={(event) => {
                  setCheck(event.target.checked);
                  loadStdVehValBased({
                    checked: event.target.checked,
                    fields: section_one,
                  });
                  setOpen(true);
                }}
              />
            </div>
          </div>
          <Collapse in={open}>
            <div className="col-md-12 mt-2">
              <div>
                <span className="text-name-user">Avg. year mileage</span>
              </div>
              <div className="mt-2">
                <input
                  {...register("std_vehicle_val_avg_miles_driven_pyear", {
                    required: 'Average miles is required',
                  })}
                  type="number"
                  className={
                    errors.std_vehicle_val_avg_miles_driven_pyear
                      ? "form-control invalid"
                      : "form-control"
                  }
                  defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_avg_miles_driven_pyear}
                  placeholder="$100.000"
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className="col-md-12 mt-3">
          <div className="btnDropOff w-100">
            <div className="d-flex flex-row">
              <button
                type="button"
                onClick={() => setOpenTwo(!openTwo)}
                aria-expanded={openTwo}
                className="btn-clean text-white p-0"
              >
                {!openTwo ? (<FiChevronDown className="icon-details" />) : (<FiChevronUp className="icon-details" />)}
              </button>
              <p className="collapse-title">Vehicle based cost</p>
            </div>
            <div className="me-1">
              <input
                className="form-check-input"
                type="checkbox"
                checked={checkTWo}
                onChange={(event) => {
                  setCheckTwo(event.target.checked);
                  loadStdVehValBased({
                    checked: event.target.checked,
                    fields: section_two,
                  });
                  setOpenTwo(true);
                }}
              />
            </div>
          </div>
          <Collapse in={openTwo}>
            <div className="w-100">
              <div className="col-md-12 d-flex flex-column mt-3 w-100">
                <label className="text-name-user mb-2">Trailer type</label>
                <select
                  {...register("std_vehicle_val_trailer_type", {
                    required: 'Trailer type is required',
                    validate: value => value !== 0
                  })}
                  className={
                    errors.std_vehicle_val_trailer_type
                      ? "form-select invalid"
                      : "selectOffMenu w-100 border-end-0"
                  }
                >
                  <option disabled value="0">
                    Select trailer type
                  </option>
                  {trailer_types?.map((e, i) => (
                    <option key={i + '_' + instanceKey} value={e.pv_id} selected={registerForm?.std_vehicle_values?.std_vehicle_val_trailer_type === e.pv_id}>
                      {e.pv_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex flex-row justify-content-between mt-2 gap-2">
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Fuel per year</label>
                  <input
                    {...register("std_vehicle_val_fuel_pyear", {
                      required: 'Fuel per year is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_fuel_pyear
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_fuel_pyear}
                    placeholder={'$100'}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Maintenance</label>
                  <input
                    {...register("std_vehicle_val_maintenance", {
                      required: 'Maintenance is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_maintenance
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_maintenance}
                    placeholder={'$100'}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between mt-2 gap-2">
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Equipment</label>
                  <input
                    {...register("std_vehicle_val_equipment", {
                      required: 'Equipment is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_equipment
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_equipment}
                    placeholder={'$100'}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Tires</label>
                  <input
                    {...register("std_vehicle_val_tire", {
                      required: 'Tires is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_tire
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_tire}
                    placeholder={'$100'}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between mt-2 gap-2">
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Permits</label>
                  <input
                    {...register("std_vehicle_val_permits", {
                      required: 'Permits is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_permits
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_permits}
                    placeholder={'$100'}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Insurance</label>
                  <input
                    {...register("std_vehicle_val_insurance", {
                      required: 'Insurance is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_insurance
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_insurance}
                    placeholder={'$100'}
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="col-md-12 mt-3">
          <div className="btnDropOff w-100">
            <div className="d-flex flex-row">
              <button
                type="button"
                onClick={() => setOpenThree(!openThree)}
                aria-expanded={openThree}
                className="btn-clean text-white p-0"
              >
                {!openThree ? (<FiChevronDown className="icon-details" />) : (<FiChevronUp className="icon-details" />)}
              </button>
              <p className="collapse-title">Driver based costs</p>
            </div>
            <div className="me-1">
              <input
                className="form-check-input"
                type="checkbox"
                checked={checkThree}
                onChange={(event) => {
                  setCheckThree(event.target.checked);
                  loadStdVehValBased({
                    checked: event.target.checked,
                    fields: section_three,
                  });
                  setOpenThree(true);
                }}
              />
            </div>
          </div>
          <Collapse in={openThree}>
            <div className="w-100">
              <div className="d-flex flex-row justify-content-between mt-2 gap-2">
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Anual wages</label>
                  <input
                    {...register("std_vehicle_val_annual_wages", {
                      required: 'Annual wages is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_annual_wages
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_annual_wages}
                    placeholder={'$100'}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-name-user mb-2">Anual benefits</label>
                  <input
                    {...register("std_vehicle_val_annual_benefits", {
                      required: 'Annual benefits is required',
                    })}
                    type="number"
                    step={'0.01'}
                    className={
                      errors.std_vehicle_val_annual_benefits
                        ? "form-control invalid"
                        : "form-control form-modal"
                    }
                    defaultValue={registerForm?.std_vehicle_values?.std_vehicle_val_annual_benefits}
                    placeholder={'$100'}
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="divider-4"></div>
        <div className="col-md-12 cardOffMenu d-flex justify-content-center">
          <span className="title-calculator">$ {total ?? 0}</span>
        </div>
        <div className="col-md-12 mt-4">
          <button
            type="submit"
            className="btn btn-outline-success btn-route-lg w-100"
            onClick={() => setShowModal(!showModal)}
          >
            CREATE NEW SETTINGS
          </button>
        </div>
      </form>
      <SimpleModal
        actionModal={createSetting}
        formName={'std_vehicle_val_name'}
        showSmallModal={showModal}
        setShowSmallModal={setShowModal}
        title={"New variable cost"}
        letterBtn={"CREATE NEW SETTING"}
        subTitle={"Setting name"}
        buttons={false}
      // titleAlert={"Created setting"}
      // textAlert={"You have a new variable cost settings"}
      />

    </div>
  );
}

export default ItemVariableCost;
