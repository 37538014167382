import React, { useState } from "react";
import logo from "../../../app/images/90466.svg";
import GeneralInformation from "./GeneralInformation";
import PersonalInformation from "./PersonalInformation";
import VariableCost from "./VariableCost";
import FormCardWizard from "../../../components/FormCardWizard";
import FormCompleted from "./FormCompleted";
import { Link, useHistory } from "react-router-dom";
import auth, { getAuthenticatedUser } from "../../../auth";

const SignUp = () => {

  auth.configure({
		oauth: {
			domain: "routing.auth.us-east-1.amazoncognito.com",
			scope: ["openid", "email", "profile"],
			redirectSignIn: `${window.location.origin}/sign-up`,
			redirectSignOut: `${window.location.origin}/`,
			responseType: "token",
		},
	});

  let history = useHistory();

  const [formStep, setFormStep] = useState(0);

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);

  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);

  const goToOneFormStep = (goTo) => setFormStep((currentStep) => currentStep = goTo);

  const redirectToLogin = async () => {
    const authenticated_user = await getAuthenticatedUser();
		if (authenticated_user == null) {
			return history.replace("/login");
		}
    auth.signOut();
  }

  return (
    formStep > 2 ? <FormCompleted goToStep={goToOneFormStep} currentStep={formStep} /> : 
    <div className="content-login singUp">
      <div className="brand-login brand-hidden d-md-flex flex-column flex-wrap justify-content-center col-lg-5 gap-2 labels-group">
        <div>
        <Link onClick={redirectToLogin} > 
          <img src={logo} alt="" className="logo-login" />
        </Link>
        <span className="dialog-login">More than a loadboard</span>
        </div>
      </div>
      <div className="form-sign-in col-xl-7 col-md-8">
        <FormCardWizard currentStep={formStep} prevFormStep={prevFormStep}>
          {formStep >= 0 && (
            <GeneralInformation
              formStep={formStep}
              nextFormStep={nextFormStep}
            />
          )}
          {formStep >= 1 && (
            <PersonalInformation formStep={formStep} nextFormStep={nextFormStep} />
          )}
          {formStep >= 2 && (
            <VariableCost formStep={formStep} nextFormStep={nextFormStep} />
          )}
        </FormCardWizard>
      </div>
    </div>


  );
};

export default SignUp;
