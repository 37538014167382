let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestSaveRouteSearchDetails = async (user_token, form) => {
	let response;
	await fetch(`${API_URL}route-search-detail?by_user_id=1`, {
        method: "POST",
		headers: {
			Authorization: user_token,
            Accept: "application/json",
            "Content-Type": "application/json",
		},
        body: JSON.stringify(form)
	})
		.then((response) => response.json())
		.then((data) => {
			response = data;
		})
		.catch((error) => {
			response = {
				state: 500,
				data: null,
				error,
				message: "Connection error, try again later",
			};
		});
	return response;
};

export default requestSaveRouteSearchDetails;
