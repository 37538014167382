import formatRouteData from "./helpers/formatRouteData";

let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestUserRouteUpdate = async (form, user_token, id) => {
  const body = formatRouteData(form);
  let response;

  await fetch(`${API_URL}user-route/${id}`, {
    method: "PUT",    
    headers: {
      Authorization: user_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });
  return response;
};

export default requestUserRouteUpdate;
