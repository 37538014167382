import React from 'react';
import { useState } from 'react';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { selectGuardianState } from '../../../../redux/auth/GuardianSlice';
import { selectClientState } from '../../../../redux/inbox/ConversationSlice';
import { selectUserDataState } from '../../../../redux/user/UserSlice';
import requestDeleteConversation from '../../../../services/inbox/requestDeleteConversation';
import InboxToolBar from '../../InboxToolBar';
import MessageItem from '../../MessageItem';
import MessageViewer from '../../MessageViewer';
import NewMessageOffCanvas from '../../NewMessageOffCanvas';

const Featured = () => {
    const [elToView, setElToView] = useState();
    const [showNewMessage, setShowNewMessage] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [newMessage, setNewMessage] = useState(null);
    const [conversationType, setConversationType] = useState();

    const authCheck = useSelector(selectGuardianState);
    const userInfo = useSelector(selectUserDataState);
    const globalClient = useSelector(selectClientState);
    const [conversationList, setConversationList] = useState([]);

    setTimeout(async () => {
        let conversationsPaginator = await globalClient.getSubscribedConversations();
        const conversations = conversationsPaginator.items;
        const arrConversations = conversations.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
        const arrToFeatured = arrConversations.filter((el) => el.attributes.featured === true);
        setConversationList(arrToFeatured);
    }, 3000);

    const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        background: '#121A1F',
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const handleShow = (type) => {
        if (type === 'creating') {
            setElToView({});
        }
        setConversationType(type);
        setShowNewMessage(!showNewMessage);
    };

    const initConversation = (message) => {
        setNewMessage(message);
        if (conversationType === 'answering') {
            elToView.conversation.sendMessage(message.message_body);
            toViewer(elToView.conversation);
        } else {
            setTimeout(async () => {
                const conversation = await globalClient.createConversation();
                await conversation.add(message.message_for);
                await conversation.updateFriendlyName(message.message_subject);
                await conversation.updateAttributes({...conversation.attributes, user_creator_data: userInfo ?? {}, });
                await conversation.join();
                await conversation.sendMessage(message.message_body);
            }, 500);
        }
    }

    const toViewer = async (conversation) => {
        // get the messages paginator the latest 30 messages
        console.log(conversation.sid);
        let messagesPaginator = await conversation.getMessages();
        // get messages
        const messages = messagesPaginator.items;
        let objConversation = { conversation: conversation, messages: messages };

        setElToView(objConversation)
    };

    const deleteConversation = async (conversation) => {
        // await conversation.delete(); 
        const deleteConversation = await requestDeleteConversation(authCheck, conversation.sid);

        if (deleteConversation.state === 200) {
            const arrConv = [...conversationList];
            const deleting = arrConv.filter(function (el) { return el.sid !== conversation.sid; });
            setNewMessage(null);
            setElToView(null);
            console.log(deleting);
            setConversationList(deleting);
            Toast.fire({
                icon: 'success',
                title: 'Conversation has been deleted'
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Something went wrong!'
            });
        }
    }

    const removeToFeatured = async (conversation) => {
        await conversation.updateAttributes({...conversation.attributes, featured: false });
        console.log(conversation);
    }

    return (
        <div className='py-4'>
            <InboxToolBar onShowNewMsg={handleShow} />
            <div className="d-flex flex-row flex-wrap mt-3 inbox-panel gap-3">
                <div className="card">
                    <div className="card-body p-3">
                        {(!conversationList || conversationList.length === 0) &&
                            <div className="row align-items-center justify-content-center h-100">
                                <h2 className="heading-h2 fw-bold my-3 text-center">Your conversations list is empty. </h2>
                            </div>
                        }
                        <Suspense fallback={<h2 className="heading-h2 fw-bold my-3">Loading Conversations </h2>}>
                            {conversationList.map((i, index) =>
                                <MessageItem key={index} userName={i.createdBy} category={i.category} title={i.friendlyName ?? 'Title'} time={i.dateCreated.simpleFormatDate()} conversation={i} onClick={toViewer} isOnFeatured onUnFeatured={removeToFeatured} />
                            )}
                        </Suspense>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <MessageViewer message={elToView} handle={handleShow} deleteConversation={deleteConversation} />
                    </div>
                </div>
            </div>
            <NewMessageOffCanvas mailer={initConversation} show={showNewMessage} handle={handleShow} placement={'bottom'} className={'new-message-pane'} title={conversationType !== 'answering' ? 'New message' : 'Answer message'} backdropClassName={'alternative-back-drop'} />
        </div>
    );
}

export default Featured;