import React, { useEffect, useState } from "react";
import imgUser from "../../../../app/images/user.png";
import facebook from "../../../../app/images/facebook.png";
import instagram from "../../../../app/images/instagram.png";
import linkedin from "../../../../app/images/linkedin.png";
import twitter from "../../../../app/images/twitter.png";
import { FiChevronRight, FiAlertCircle, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDataState, setUserData } from "../../../../redux/user/UserSlice";
import { ProtectedRoute } from "../../../../app/ProtectedRoute";
import { Link } from "react-router-dom";
import requestUpdateUser from "../../../../services/user/requestUpdateUser";
import { selectGuardianState } from "../../../../redux/auth/GuardianSlice";

const InfoAccount = ({ routes }) => {
  const userInfo = useSelector(selectUserDataState);
  const user_city = JSON.parse(userInfo?.user_city ?? null);
  const user_networks = JSON.parse(userInfo?.user_networks ?? null);
  const authCheck = useSelector(selectGuardianState);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(user_networks);
  let usr_id = userInfo?.user_id;

  useEffect(() => {
    const sendData = async () => {
      let data = { user_networks: JSON.stringify(formValues) }
      try {
        const responseData = await requestUpdateUser(data, authCheck, usr_id);
        if (responseData.state === 200) {
          dispatch(setUserData(responseData.data));
        }
      } catch (error) {
        console.error(error);
      }
    };
    sendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <>
      <div className="mt-3 mb-5 flex-system-content">
        <div className="d-flex flex-row">
          <div className="initial-element">Photo</div>
          <div className="p-2 middle-element d-flex flex-row align-items-center">
            <div className="img-user-settings">
              <img src={imgUser} alt="" />
            </div>
            <div className="multiLineLabel ms-3">
              <span className="text-settings">
                The photo helps personalize your account
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Name</div>
          <div className="middle-element middle-element-text">
            {userInfo?.user_name ?? "---"} {userInfo?.user_last_name ?? "---"}
          </div>
          <div className="final-element text-end">
            <Link to={`/settings/account/name`}>
              <FiChevronRight className="icon-settings" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Account type</div>
          <div className="middle-element middle-element-text">Analyst</div>
          <div className="final-element text-end">
            <FiChevronRight className="icon-settings" />
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Gender</div>
          <div className="middle-element middle-element-text">
            {userInfo?.user_gender ?? "To be defined"}
          </div>
          <div className="final-element text-end">
            <Link to={`/settings/account/gender`}>
              <FiChevronRight className="icon-settings" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">E-mail</div>
          <div className="middle-element middle-element-text">
            {userInfo?.user_email ?? "---"}
          </div>
          <div className="final-element text-end">
            <Link to={`/settings/account/email`}>
              <FiChevronRight className="icon-settings" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Password</div>
          <div className="middle-element middle-element-text">
            <div className="d-flex flex-row flex-warp justify-content-between">
              <span>{ userInfo.user_google_token ? "Your account is associated with google" : "********" }</span>
              {!userInfo.user_google_token &&
                <div className="d-flex flex-row flex-wrap align-items-center">
                  <div>
                    <span className="text-sett">Last modification: {userInfo?.user_last_password_modification ?? "--/--/----"}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          {!userInfo.user_google_token &&
            <div className="final-element text-end">
              <Link to={`/settings/account/password`}>
                <FiChevronRight className="icon-settings" />
              </Link>
            </div>
          }
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Phone</div>
          <div className="middle-element middle-element-text">
            <div className="d-flex flex-row flex-warp justify-content-between">
              <span>{userInfo?.user_cellphone ?? "---"}</span>
              <div className="d-flex flex-row flex-wrap align-items-center">
                <FiAlertCircle className="info-icon-dashboard" />
                <span className="ms-2">Must be verified</span>
              </div>
            </div>
          </div>
          <div className="final-element text-end">
            <Link to={`/settings/account/phone`}>
              <FiChevronRight className="icon-settings" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Location</div>
          <div className="middle-element middle-element-text">
            {user_city?.name_formatted ?? "---"}
          </div>
          <div className="final-element text-end">
            <Link to={`/settings/account/location`}>
              <FiChevronRight className="icon-settings" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Job information</div>
          <div className="middle-element middle-element-text">
            {userInfo?.user_job && userInfo?.user_company_name
              ? userInfo.user_job + ", " + userInfo.user_company_name
              : "------"}
          </div>
          <div className="final-element text-end">
            <Link to={`/settings/account/job_information`}>
              <FiChevronRight className="icon-settings" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="initial-element">Social media</div>
          <div className="middle-element middle-element-text">
            <div className="d-flex flex-row gap-3">
              <div className="contentSocial">
                <div>
                  <img src={facebook} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    name='facebook'
                    onBlur={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                    defaultValue={(typeof user_networks?.facebook === 'string' && user_networks?.facebook === '') ? 'Facebook' : (user_networks?.facebook ? user_networks?.facebook : 'Facebook')}
                  />
                </div>
              </div>
              <div className="contentSocial">
                <div>
                  <img src={instagram} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    name='instagram'
                    onBlur={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                    defaultValue={(typeof user_networks?.instagram === 'string' && user_networks?.instagram === '') ? 'Instagram' : (user_networks?.instagram ? user_networks?.instagram : 'Instagram')}
                  />
                </div>
              </div>
              <div className="contentSocial">
                <div>
                  <img src={twitter} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    name='twitter'
                    onBlur={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                    defaultValue={(typeof user_networks?.twitter === 'string' && user_networks?.twitter === '') ? 'Twitter' : (user_networks?.twitter ? user_networks?.twitter : 'Twitter')}
                  />
                </div>
              </div>
              <div className="contentSocial">
                <div>
                  <img src={linkedin} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    name='linkedin'
                    onBlur={(e) => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                    defaultValue={(typeof user_networks?.linkedin === 'string' && user_networks?.linkedin === '') ? 'Linkedin' : (user_networks?.linkedin ? user_networks?.linkedin : 'Linkedin')}
                  />
                </div>
              </div>
              <div className="contentSocial-add">
                <div>
                  <FiPlus />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {routes.Routes.map((item, index) => (
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact}
        />
      ))}
    </>
  );
};

export default InfoAccount;
