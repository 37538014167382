/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useState } from "react";
import {
	FiArrowDownRight,
	FiArrowUpRight,
	FiCircle,
	FiMinus,
	FiTruck,
} from "react-icons/fi";
import SearchableDropdown from "../../../components/SearchableDropdown";

const LaneCard = (props) => {
	const {
		data,
		index,
		loadSizes = false,
		position = "",
		onUpdateItem = false,
		onRemoveItem = false,
		hasError = false,
	} = props;

	const icon = {
		first: <FiArrowUpRight className="dragging-handle letterDeparture" />,
		last: <FiArrowDownRight className="dragging-handle letterDeparture" />,
		"": <FiCircle className="dragging-handle handle-icon" />,
	};

	const [hoverable, setHoverable] = useState(
		data.lane_action == "DEADHEAD" || data.lane_action == ""
	);

	const [lane, setLane] = useState(data);
	const [type, setType] = useState(data?.lane_action);
	const [loadSize, setLoadSize] = useState({ id: data?.lane_load_size, name: data?.lane_load_size_name });

	useEffect(() => {
		setLane(data);
	}, [data]);

	const onChangeType = (type) => {
		setType(type);
		data.lane_action = type;
		setHoverable(false);
		onUpdateItem(data, index);
	};

	const onChangeLoadSize = (item) => {
		setLoadSize({
			id: item.pv_id,
			name: item.pv_name,
		});
		data.lane_load_size = item.pv_id;
		data.lane_load_size_name = item.pv_name;
		onUpdateItem(data, index);
	};

	const setPoints = (item) => {
		data.lane_place = `${item.city}, ${item.state}`;
		data.lane_place_id = (item.place_id ?? "").replace("c00208", "");
		onUpdateItem(data, index);
	};

	return (
		<div>
			<SearchableDropdown
				value={lane.lane_place}
				onUpdateValue={setType}
				onChange={setPoints}
				index={index}
				spacing_top={"1.8rem"}
				itemClass={
					"contentDeparture mb-3 " +
					(hasError ? "border-danger " : "") +
					(hoverable ? "hoverable-button-content" : "")
				}
				hoverOnLane={
					position == "" ? (
						<div className="d-flex flex-row flex-wrap align-items-center">
							<div
								className="btn-group"
								role="group"
								aria-label="Basic example"
							>
								<input
									type="radio"
									className="btn-check"
									checked={lane?.lane_action == "PICK UP"}
									onChange={(e) => onChangeType("PICK UP")}
									name={`lane_action_${index}`}
									id={`option${index}`}
									autoComplete="off"
								/>
								<label
									className="btn btn-default btn-clean-text"
									htmlFor={`option${index}`}
								>
									PICK UP
								</label>

								<input
									type="radio"
									className="btn-check"
									checked={lane?.lane_action == "DROP OFF"}
									onChange={(e) => onChangeType("DROP OFF")}
									name={`lane_action_${index}`}
									id={`option${"id" + index}`}
									autoComplete="off"
								/>
								<label
									className="btn btn-default btn-clean-text"
									htmlFor={`option${"id" + index}`}
								>
									DROP OFF
								</label>
							</div>
							<button
								className="btn-clean btn-clean-text btn-minus-delete"
								onClick={() => onRemoveItem(index)}
							>
								<FiMinus />
							</button>
						</div>
					) : (
						false
					)
				}
				icon={icon[position]}
			/>
			{loadSizes && (
				<div className={styles.bar}>
					<span className="letterLoad">Load size</span>
					<div className="contentBorder me-2">
						{loadSizes.map((item) => (
							<div
								className="button-radio"
								// key={`load_size${index}`}
							>
								<input
									type="radio"
									id={`${index} - ${item.pv_id}`}
									checked={item.pv_id == lane?.lane_load_size}
									name={`check_substitution_${index}`}
									onChange={(e) => onChangeLoadSize(item)}
								/>
								<label
									className="btn btn-default"
									htmlFor={`${index} - ${item.pv_id}`}
								>
									{item.pv_name}
								</label>
							</div>
						))}
					</div>
					<button className="btn-clean">
						<FiTruck className="icon-FiTruck" />
					</button>
				</div>
			)}
		</div>
	);
};

const styles = {
	bar: "d-flex flex-row justify-content-between align-items-center mb-3",
};

export default LaneCard;
