import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiHelpCircle } from "react-icons/fi";
import Layout from '../../components/layout/Layout';
import { ProtectedRoute } from '../../app/ProtectedRoute';
import GeneralTabs from '../../components/GeneralTabs';
import Principal from './Tabs/Principal/Principal';
import Featured from './Tabs/Featured/Featured';
import News from './Tabs/News/News';
import CustomToolTip from '../../components/layout/CustomToolTip';

const InboxIndex = ({ routes }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/inbox/principal`);
  }, []);


  const infoTabs = {
    "principal": {
      id: 1,
      title: 'Principal',
      subTitle: null,
      child: <Principal />,
      new_count: null
    },
    "news": {
      id: 2,
      title: 'News',
      subTitle: 'Joaquin, Christian',
      child: <News />,
      new_count: '10 News'
    },
    "featured": {
      id: 3,
      title: 'Featured',
      subTitle: 'Joaquin, Christian',
      child: <Featured />,
      new_count: '10 News'
    },
  };

  return (
    <>
      <ProtectedRoute path={routes?.path}
        routes={routes?.Routes}
        exact={true}>
        <Layout>
          <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4 content-main-margin">
            <h1 className="heading-h1 fw-bold">
              Your inbox
              <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={"Your inbox"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
            </h1>
            <p className="subheading-top">Here, you can text with your collaborators</p>
            <GeneralTabs data={infoTabs} dataRoute={routes} route={'inbox'} icon={true} />
          </div>
        </Layout>
      </ProtectedRoute>
      {routes.Routes.map((item, index) =>
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact} />
      )}
    </>
  )
}

export default InboxIndex