/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FiMapPin, FiMinus } from "react-icons/fi";
import OutsideAlerter from './OutsideAlerter';


const SearchableDropdown = (props) => {
    const { title = null, icon = null, itemClass, hoverOnLane = false, onChange, inputClass = 'departure-field', itemId = null, spacing_top = '3rem', value = '', } = props;
    
    const [openMenu, setOpenMenu] = useState(false);
    const [searching, setSearching] = useState(value);
    const [results, setResults] = useState([]);
    const [randomNumber, setRandomNumber] = useState(0);
    
    useEffect(()=>{
		setSearching(value);
	},[value])

    const openSwitch = () => {
        setOpenMenu(false);
    }

    const fetching = async (value) => {
        if (value) {
            const resp = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&filter=countrycode:us&lang=en&apiKey=${process.env.REACT_APP_API_KEY_MAP}`);
            const places = await resp.json();
            const currentItems = places.features.filter((item) => item.properties.city != null);
            console.log(currentItems);
            setResults(currentItems);
        }
    }

    const selectItem = async (item) => {
        // const place_id = await searchCityId(item);
        // item.place_id = place_id;
        // console.log('item', item);
        onChange(item);
        setSearching(item.city ?? item.state);
        setOpenMenu(false);
    }

    const writingHandler = () => {

        const timer = setTimeout(() => {
            fetching(searching)
        }, 500);

        if (searching) {
            setOpenMenu(true);
        } else {
            setOpenMenu(false);
        }
        return () => clearTimeout(timer);
    };

    return (
        <Dropdown as={'div'} className={itemClass}>
            {icon && icon}
            <div className={hoverOnLane ? 'text-start lh-1 w-100 d-flex flex-row flex-wrap justify-content-between align-items-center' : 'text-start lh-1 w-75'}>
                {title && <p className='route-name fw-bold mb-0'>{title}</p>}
                <input type="text" autoComplete="off" className={inputClass} onKeyUp={writingHandler} onChange={(event) => setSearching(event.target.value)} id={itemId} value={searching} />
                 <label htmlFor="departure">{ searching == '' && 'California' }</label>
                {hoverOnLane && hoverOnLane}
            </div>
            <OutsideAlerter position={'absolute'} listener={openMenu} trigger={openSwitch}>
                <ul className={openMenu ? 'dropdown-menu show' : 'dropdown-menu'} style={{ top: spacing_top }}>
                    <Dropdown.ItemText className="text-item-drop">Departure region</Dropdown.ItemText>
                    {results.map((item, index) =>
                        <Dropdown.Item as={'div'} className="dropdown-item" key={`s_dropdown${index}`} onClick={() => selectItem(item.properties)}>
                            <div className="d-flex flex-row flex-wrap align-items-center">
                                <FiMapPin className='icon-drop me-2' />
                                <div>
                                    <p className="text-heading-places text-spacing-places mb-2">{item.properties.formatted }</p>
                                    <p className="text-description-places mb-0">{item.properties.state}</p>
                                </div>
                            </div>
                        </Dropdown.Item>
                    )}
                </ul>
            </OutsideAlerter>
        </Dropdown>);
}

export default SearchableDropdown;