import React, { useState } from 'react';
import { Search } from 'react-feather';
import { FiList, FiPlus } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import { selectUserDataState } from '../../redux/user/UserSlice';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const InboxToolBar = ({onShowNewMsg}) => {
    const [selectAll, setSelectAll] = useState(false);
    const { idTab } = useParams();
    const searchingMessage = (value) => console.log(value);
    const onClickFunToParent = () => onShowNewMsg('creating');
    const userInfo = useSelector(selectUserDataState);

    return (
        <>
            <div className="d-flex flex-row gap-3">
                <CustomButton className={'btn btn-default d-flex flex-row flex-wrap align-items-center justify-content-between fw-bold'} title={'New message'} icon={<FiPlus className='btnSettings'/>} onClick={onClickFunToParent} btnDisabled={(userInfo.user_role_id === 2 && idTab === 'news') ? true:false} />
                <div className='toolbar-element'>
                    <CustomInput type={'checkbox'} onChange={() => setSelectAll(!selectAll)} />
                </div>
                <div className="toolbar-element p-1">
                    <div className='dropSort'>
                        <DropdownButton
                            drop={'down'}
                            title={<FiList />}
                        >
                            <Dropdown.Item>Sort by name</Dropdown.Item>
                            <Dropdown.Item>Sort by date</Dropdown.Item>
                            <Dropdown.Item>Sort by mailer</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                <div className="toolbar-element flex-search-field">
                    <Search className="form-control-feedback m-1 me-2" />
                    <CustomInput type={'search'} onChange={() => searchingMessage()} placeholder={'Search message'} className={'form-control-plaintext'} value={'searching'}/>
                </div>
            </div>
        </>);
}

export default InboxToolBar;