import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: [],
};
export const UserRouteFoldersSlice = createSlice({
  name: "user_route_folders",
  initialState,
  reducers: {
    setUserRouteFolders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserRouteFolders } = UserRouteFoldersSlice.actions;
export const selectUserRouteFoldersState = (state) => state.user_route_folders.value;

export default UserRouteFoldersSlice.reducer;
