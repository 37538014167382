import React, { useEffect, useState } from 'react';
import { FiGlobe } from "react-icons/fi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory, useParams } from 'react-router-dom';

const GeneralTabs = (props) => {

    const { idTab } = useParams();
    const history = useHistory();
    const [activeTabs, setActiveTabs] = useState(idTab ?? 'account');

    useEffect(() => {
        if (!idTab) {
            history.push(`/${props.route}/${activeTabs}`);
        }
        setActiveTabs(idTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = tab => {
        if (idTab !== tab) {
            history.push(`/${props.route}/${tab}`);
            setActiveTabs(tab)
        }
    }

    return (
        <Tabs
            activeKey={idTab ? idTab : 'account'}
            className='tabsContent'
            fill
            onSelect={(e) => toggle(e)}
        >
            {Object.entries(props.data).map((item) =>
                <Tab title={
                    <div className='d-flex flex-row align-item-center'>
                        {props.icon &&
                            <div className='me-2 d-flex flex-row flex-wrap align-items-center'>
                                <FiGlobe />
                            </div>
                        }
                        <div className='d-flex flex-column text-start me-3'>
                            <label>{item[1].title}</label>
                            <p className={item[1].subTitle ? 'subheading-top text-spacing mb-0' : 'subheading-top text-spacing mb-0 d-none'}>{item[1].subTitle}</p>
                        </div>
                        <div className={item[1].new_count ? 'contentNews mt-1' : 'contentNews mt-1 d-none'}>
                            <label>{item[1].new_count}</label>
                        </div>
                    </div>
                } key={item[0]} eventKey={item[0]} >
                    {item[1].child}
                </Tab>
            )}
        </Tabs>
    )
}

export default GeneralTabs