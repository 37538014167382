/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FiArrowDownRight, FiChevronDown, FiMapPin } from "react-icons/fi";
import { TbEqual } from "react-icons/tb";
import { useSelector } from 'react-redux';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';
import requestGetMiles from '../../services/tools/requestGetMiles';
import requestGetMPG from '../../services/tools/requestGetMPG';
import requestPlaces from '../../services/tools/requestPlaces';
import OutsideAlerter from '../OutsideAlerter';
import requestGetGasOnCity from '../../services/tools/requestGetGasOnCity';

export const SearchableDropdown = ({ title, icon, itemClass, hoverOnLane, onChange, inputClass, itemId, spacing_top, value, displayLabel }) => {

    const [openMenu, setOpenMenu] = useState(false);
    const [searching, setSearching] = useState(value);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setSearching(value);
    }, [value])

    const openSwitch = () => {
        setOpenMenu(false);
    }


    const fetching = async (value) => {
        if (value) {
            const resp = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&filter=countrycode:us&type=city&lang=en&apiKey=${process.env.REACT_APP_API_KEY_MAP}`);
            const places = await resp.json();
            const currentItems = places.features.filter((item) => item.properties.result_type == 'city');
            setResults(currentItems);
        }
    }

    const selectItem = async (item) => {
        onChange(item);
        setSearching(item.city ?? item.state);
        setOpenMenu(false);
    }

    const writingHandler = () => {
        const timer = setTimeout(() => {
            fetching(searching)
        }, 500);
        if (searching) {
            setOpenMenu(true);
        } else {
            setOpenMenu(false);
        }
        return () => clearTimeout(timer);
    };

    return (
        <Dropdown as={'div'} className={itemClass}>
            {icon && icon}
            <div className='text-start lh-1'>
                {title && <p className='route-name fw-bold mb-0'>{title}</p>}
                <input type="text" autoComplete="off" className={inputClass} onKeyUp={writingHandler} onChange={(event) => setSearching(event.target.value)} id={itemId} value={searching} />
                {displayLabel &&
                    <label htmlFor="departure">{searching == '' && 'California'}</label>
                }
                {hoverOnLane && hoverOnLane}
            </div>
            <OutsideAlerter position={'absolute'} listener={openMenu} trigger={openSwitch}>
                <ul className={openMenu ? 'dropdown-menu show' : 'dropdown-menu'} style={{ top: spacing_top }}>
                    {results.map((item, index) =>
                        <Dropdown.Item as={'div'} className="dropdown-item" key={`s_dropdown${index}`} onClick={() => selectItem(item.properties)}>
                            <div className="d-flex flex-row flex-wrap align-items-center">
                                <FiMapPin className='icon-drop me-2' />
                                <div>
                                    <p className="text-heading-places mb-2">{item.properties.city}</p>
                                    <p className="text-description-places mb-0">{item.properties.state}</p>
                                </div>
                            </div>
                        </Dropdown.Item>
                    )}
                </ul>
            </OutsideAlerter>
        </Dropdown>);
}

const ItemCostEstimator = ({ isShowing }) => {
    const [dropTrip, setDropTrip] = useState(false);
    const [dropValues, setDropValues] = useState(false);
    const [pickUpPlace, setPickUpPlace] = useState(null);
    const [dropOff, setDropOff] = useState(null);
    const [miles, setMiles] = useState(0);
    const [MPG_value, setMPG_value] = useState(0);
    const [galPrice, setGalPrice] = useState(0);
    const [preResults, setPreResults] = useState({ revenue: 0, dispatch_fee_option: 1, dispatch_fee: 5 });

    const authCheck = useSelector(selectGuardianState);

    /* Checking if pickUpPlace and dropOff are not null. If they are not null, it will create an object
    with the lon and lat of pickUpPlace and dropOff. Then it will call the getMiles function with
    the object as a parameter. */
    useEffect(() => {
        if (isShowing) {
            if (pickUpPlace && dropOff) {
                let obj = {
                    origin: { lng: pickUpPlace.lon, lat: pickUpPlace.lat },
                    destination: { lng: dropOff.lon, lat: dropOff.lat },
                }
                getMiles(obj);
                opMpgPerGal();
            }
        }

    }, [pickUpPlace, dropOff])

    useEffect(() => {
        if (isShowing) {
            // getGasPriceOnCity();
            opMpgPerGal();
        }
    }, [miles])

    /* Calling the calculateAdditionalCost function when the preResults.revenue or
    preResults.dispatch_fee_option changes. */
    useEffect(() => {
        calculateAdditionalCost(preResults.dispatch_fee, (preResults?.other_fees ? preResults?.other_fees : 0))
    }, [preResults.revenue, preResults.dispatch_fee_option])

    /* Calling the calculateSubtotal function when the revenue or additional_cost changes. */
    useEffect(() => {
        calculateSubtotal();
    }, [preResults.revenue, preResults?.additional_cost])



    /**
     * GetMiles() is an async function that takes an object as an argument, and then uses that object
     * to make an API call to get the distance between two points.
     */
    const getMiles = async (objPlaces) => {
        let coordinates = { ...objPlaces };
        try {
            const res = await requestGetMiles(coordinates, authCheck);
            if (res.state === 200) {
                setMiles(res.data);
                setPreResults({ ...preResults, miles: miles });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const opMpgPerGal = async () => {
        try {
            if (!pickUpPlace?.city && !pickUpPlace?.state) {
                return;
            }
            const res_mpg = await requestGetMPG(authCheck);
            const res_gas = await requestGetGasOnCity(authCheck, pickUpPlace?.city, pickUpPlace?.state);
            if (res_mpg.state !== 200 && res_gas !== 200) {
                return;
            }
            let mpg = res_gas.data / res_mpg.data;
            mpg = mpg.toFixed(2);
            setMPG_value(mpg);
            setPreResults({ ...preResults, mpg: mpg });
            calculateFuelCost(mpg, null);

        } catch (error) {
            console.log(error);
        }
    }


    const { register, handleSubmit, reset } = useForm({ shouldUseNativeValidation: true });
    const onSubmit = async (data) => {
        console.log(data);
    };

    /**
     * If cost is not null, then calculate revenue by multiplying miles by cost. If cost is null, then
     * calculate cost by dividing revenue by miles.
     */
    const calculateRevenue = (cost = null, revenue = null) => {
        if (cost) {
            let revenue = miles * cost;
            setPreResults({ ...preResults, cost_per_mile: cost, revenue: revenue.toFixed(2) });
        } else {
            let cost = revenue / miles;
            setPreResults({ ...preResults, cost_per_mile: cost, revenue: revenue });
        }
        calculateAdditionalCost()
    }

    /**
     * If mpg is not null, then set the fuel_cost to miles * mpg and set the state of preResults to the
     * new values of mpg and fuel_cost.
     */
    const calculateFuelCost = (mpg = null, fuel_cost = null) => {
        if (mpg) {
            console.log(true, miles);
            let fuel_cost = miles * mpg;
            setPreResults({ ...preResults, mpg: mpg, fuel_cost: fuel_cost.toFixed(2) });
        }
    }

    /**
     * If dispatch_fee_option is 2, then additional_cost is the sum of dispatch_fee_input, revenue, and
     * other_fees_input.
     * 
     * If dispatch_fee_option is 1, then additional_cost is the sum of (revenue * dispatch_fee_input) /
     * 100, and other_fees_input.
     */
    const calculateAdditionalCost = (dispatch_fee_input = null, other_fees_input = 0) => {
        if (dispatch_fee_input || preResults?.dispatch_fee) {
            if (preResults.dispatch_fee_option == 2) {
                let additional_cost = ((dispatch_fee_input ? parseInt(dispatch_fee_input) : 0) + parseInt(preResults?.revenue)) + (other_fees_input ? parseInt(other_fees_input) : 0);
                setPreResults({ ...preResults, additional_cost: additional_cost.toFixed(2), dispatch_fee: dispatch_fee_input, other_fees: other_fees_input });
            }

            if (preResults.dispatch_fee_option == 1) {
                let percentage = (preResults.revenue * parseInt(dispatch_fee_input ? dispatch_fee_input : 0)) / 100;
                let additional_cost = (percentage) + (other_fees_input ? parseInt(other_fees_input) : 0);
                setPreResults({ ...preResults, additional_cost: additional_cost.toFixed(2), dispatch_fee: dispatch_fee_input, other_fees: other_fees_input });
            }
        }
    }

    /**
     * I'm trying to calculate the percentage of each value in the subtotal and then calculate the
     * percentage of the subtotal.
     */
    const calculateSubtotal = () => {
        let linehaul_revenue = parseInt(preResults?.revenue) ?? 0;
        let estimated_fuel_cost = parseInt(preResults?.fuel_cost) ?? 0;
        let additional_cost = parseInt(preResults?.additional_cost) ?? 0;

        const subSum = linehaul_revenue + estimated_fuel_cost + additional_cost;
        const profit_detail = linehaul_revenue - estimated_fuel_cost - additional_cost;

        const linehaul_revenue_percent = (linehaul_revenue / subSum) * 100;
        const estimated_fuel_cost_percent = (estimated_fuel_cost / subSum) * 100;
        const additional_cost_percent = (additional_cost / subSum) * 100;

        const subTotalToPercent = (profit_detail / subSum) * 100;
        setPreResults(
            {
                ...preResults,
                linehaul_revenue_percent: (linehaul_revenue_percent ? linehaul_revenue_percent.toFixed(2) : 0),
                estimated_fuel_cost_percent: (estimated_fuel_cost_percent ? estimated_fuel_cost_percent.toFixed(2) : 0),
                additional_cost_percent: (additional_cost_percent ? additional_cost_percent.toFixed(2) : 0),
                profit: (profit_detail ? profit_detail : 0),
                profit_percent: (subTotalToPercent ? subTotalToPercent.toFixed(2) : 0),
            })

    }

    return (
        <div className="m-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-12 pt-2 mb-4">
                    <h1 className="heading-h1 fs-2 fw-bold mb-0">Cost estimator</h1>
                    <h6 className="subtitle-graph">Calculate the cost of the lane</h6>
                </div>
                <div className='col-md-12'>
                    <div onClick={() => setDropTrip(!dropTrip)} className="btnDropOff w-100">
                        <span className='letterDropOff'>Trip</span>
                        <FiChevronDown className={`icon-details ${dropTrip ? "icon-details-rotate" : ''}`} />
                    </div>
                    {dropTrip &&
                        <div className='w-100'>
                            <div className='d-flex flex-row flex-wrap justify-content-between mt-2 gap-1'>
                                <div className='col-md-12'>
                                    <label className='text-name-user mb-2'>Pick up</label>
                                    <SearchableDropdown onChange={(item) => setPickUpPlace(item)} itemClass={'drop-places mb-2'} itemId={'origin'} />
                                </div>
                                <div className='col-md-12'>
                                    <label className='text-name-user mb-2'>Drop off</label>
                                    <SearchableDropdown onChange={(item) => setDropOff(item)} itemClass={'drop-places mb-2'} itemId={'destination'} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='col-md-12 mt-3'>
                    <div onClick={() => setDropValues(!dropValues)} className="btnDropOff w-100">
                        <span className='letterDropOff'>Values</span>
                        <FiChevronDown className={`icon-details ${dropValues ? "icon-details-rotate" : ''}`} />
                    </div>
                    {dropValues &&
                        <div className='w-100'>
                            <div className='col-md-12 mt-2'>
                                <span className='text-name-user'>Linehaul revenue</span>
                            </div>
                            <div className="divider-5"></div>
                            <div className='col-md-12 d-flex flex-row mt-2'>
                                <div className="col-md-4">
                                    <label className='form-labelOff'>Miles</label>
                                    <input {...register("tool_miles")} type="number" className="form-control formOff" value={miles ?? 0} onChange={(e) => setMiles(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-labelOff'>($)/Mile</label>
                                    <input type="number" className="form-control formOff" defaultValue={preResults?.cost_per_mile ?? 0} onChange={(e) => calculateRevenue(e.target.value, null)} />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-labelOff'>Revenue</label>
                                    <input type="number" className="form-control formOffColor" value={preResults.revenue} onChange={(e) => calculateRevenue(null, e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <span className='text-name-user'>Estimated fuel cost</span>
                            </div>
                            <div className="divider-5"></div>
                            <div className='col-md-12 d-flex flex-row mt-2'>
                                <div className="col-md-4">
                                    <label className='form-labelOff'>Miles</label>
                                    <input onChange={(e) => setMiles(e.target.value)} type="number" value={miles ?? 0} className="form-control formOff" />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-labelOff'>(MPG x $/gl)</label>
                                    <input type="number" onChange={(e) => calculateFuelCost(e.target.value, null)} value={MPG_value ?? 0} className="form-control formOff" onBlur={(e) => e.target.value ? true : setMPG_value(0)} />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-labelOff'>Fuel cost</label>
                                    <input type="number" onChange={(e) => calculateFuelCost(null, e.target.value)} value={preResults?.fuel_cost ?? 0} className="form-control formOffColor" />
                                </div>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <span className='text-name-user'>Additional cost</span>
                            </div>
                            <div className="divider-5"></div>
                            <div className='col-md-12 d-flex flex-row mt-2'>
                                <div className="col-md-4">
                                    <label className='form-labelOff mt-2'>Miles</label>
                                    <input onChange={(e) => setMiles(e.target.value)} type="number" value={miles ?? 0} className="form-control formOff" />
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-labelOff mt-1'>Dispatch fee</label>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control formOff border-end-0" onChange={(e) => calculateAdditionalCost(e.target.value)} defaultValue={preResults?.dispatch_fee ?? 0} />
                                        <select className="selectOffMenu h-auto p-0" onChange={(e) => { setPreResults({ ...preResults, dispatch_fee_option: e.target.value }); console.log(e.target.value); }} defaultValue={preResults.dispatch_fee_option} >
                                            <option value={1} >%</option>
                                            <option value={2} >$</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className='form-labelOff mt-1'>Other fees</label>
                                    <input type="number" className="form-control formOff border-end-0" onChange={(e) => calculateAdditionalCost(preResults?.dispatch_fee, e.target.value)} defaultValue={preResults?.other_fees ?? 0} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="divider-4"></div>
                <div className="col-md-12 w-100">
                    <table className="table table-borderless tableOff">
                        <thead>
                            <tr className='text-theadOff'>
                                <th scope="col">Item</th>
                                <th scope="col" className='text-end'>Cost</th>
                                <th scope="col" className='text-end'>Percent</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='text-tbodyOff' >
                                <td>Linehaul revenue</td>
                                <td className='text-successOff text-end'>{preResults?.revenue ?? 0}</td>
                                <td className='text-end'>{preResults?.linehaul_revenue_percent ?? 0}%</td>
                            </tr>
                            <tr className='text-tbodyOff' >
                                <td>Estimated fuel cost</td>
                                <td className='text-successOff text-end'>{preResults?.fuel_cost ?? 0} </td>
                                <td className='text-end'>{preResults?.estimated_fuel_cost_percent ?? 0}%</td>
                            </tr>
                            <tr className='text-tbodyOff' >
                                <td>Additional cost</td>
                                <td className='text-successOff text-end'>{preResults?.additional_cost ?? 0}</td>
                                <td className='text-end'>{preResults?.additional_cost_percent ?? 0}%</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="divider-4"></div>
                    <div className='col-md-12 d-flex flex-row flex-wrap justify-content-between align-items-center'>
                        <label className='text-name-user w-100 fw-bold'>Profit</label>
                        <div>
                            <span className='text-tbodyOff'>${preResults?.revenue ?? 0} - {preResults?.fuel_cost ?? 0} - {preResults?.additional_cost ?? 0} </span>
                        </div>
                        <div>
                            <span className='text-tbodyOff fw-bold'>{preResults?.profit_percent ?? 0}%</span>
                        </div>
                    </div>
                </div>
                <div className="divider-4"></div>
                <div className='col-md-12 d-flex flex-row justify-content-between align-items-center mt-2'>
                    <div>
                        <span className='route-name'>$0.00/mi</span>
                    </div>
                    <div>
                        <span className='letter-success'>${preResults?.profit ?? 0}</span>
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <button type="button" className="btn btn-outline-success btn-route-lg w-100">ADD VALUES</button>
                </div>
            </form>
        </div>
    );
}

SearchableDropdown.defaultProps = {
    title: null,
    icon: null,
    itemClass: '',
    hoverOnLane: false,
    onChange: false,
    inputClass: 'form-control-plaintext place-field',
    itemId: null,
    spacing_top: '0.5rem',
    value: '',
    displayLabel: false
}

export default ItemCostEstimator;