import React, { useState } from 'react';
import { FiMoreHorizontal, FiPlus } from "react-icons/fi";

//modal
import OverlayDetails from './modals/OverlayDetails';

const CustomRoute = () => {

    const [show, setShow] = useState(false);

    return (
        <div className="h-100">
            <div className="card card-graph-bg h-100">
                <div className="card-body px-3">
                    <div className="d-flex flex-row flex-wrap mb-2">
                        <div className="col-12 d-flex flex-row flex-wrap justify-content-between align-items-center">
                            <button className='btnCustom mt-1' type="button" onClick={() => setShow(!show)}>Custom route</button>
                            <FiMoreHorizontal className='icon-route-planning' onClick={() => setShow(!show)} />
                        </div>
                        <div className="col-12 d-flex flex-row flex-wrap justify-content-center justify-self-center mt-3">
                            <FiPlus className='icon-more' onClick={() => setShow(!show)} />
                        </div>
                    </div>
                </div>
            </div>
            <OverlayDetails show={show} setShow={setShow} calendar={true}/>
        </div>

    );
}

export default CustomRoute