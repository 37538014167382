/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import FullLayout from '../../../../components/layout/FullLayout';
import { FiCornerUpLeft, FiChevronDown, FiChevronUp, FiHelpCircle } from "react-icons/fi";
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useQuery } from '../../../../hooks/useQuery';
import { useParameter } from "../../../../hooks/useParameter";
import PlaceAutocomplete from "../../../../components/PlaceAutocomplete";
import CustomToolTip from "../../../../components/layout/CustomToolTip";
import { selectGuardianState } from '../../../../redux/auth/GuardianSlice';
import requestSaveStandardVehicleValue from "../../../../services/default_settings/requestSaveStandardVehicleValue";
import requestShowStandardVehicleValues from "../../../../services/default_settings/requestShowStandardVehicleValues";
import requestEditStandardVehicleValues from "../../../../services/default_settings/requestEditStandardVehicleValues";
import { selectUserDataState } from "../../../../redux/user/UserSlice";
import Swal from "sweetalert2";
import requestReverseGetPlace from "../../../../services/default_settings/requestReverseGetPlace";


const VariableCostSettings = () => {

  const history = useHistory();
  let { url } = useRouteMatch();
  let { idVariable } = useParams();
  let idStd = useQuery();
  let sectId = idStd.get('idStd');

  const userInfo = useSelector(selectUserDataState);
  const { registerForm } = useSelector((state) => state.registerForm);
  const authCheck = useSelector(selectGuardianState);

  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [check, setCheck] = useState(registerForm?.check ?? false);
  const [checkTWo, setCheckTwo] = useState(registerForm?.checkTWo ?? false);
  const [total, setTotal] = useState(0);
  const [typicalOrigin, setTypicalOrigin] = useState(registerForm?.std_vehicle_values?.std_vehicle_val_typical_origin ?? {});
  const [stdVehValBased, setStdVehValBased] = useState([]);
  const [stdVehValBasedData, setStdVehValBasedData] = useState([]);
  const [instanceKey, setInstanceKey] = useState(0);
  const [dataShow, setDataShow] = useState([]);
  const [objPlace, setObjPlace] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    resetField,
    watch,
  } = useForm({
    defaultValues: { ...dataShow }
  });

  const load_sizes = useParameter("load_sizes");
  const trailer_types = useParameter("trailer_types");
  const std_veh_val = useParameter("std_veh_val");

  async function fetchShowData(id) {
    const res = await requestShowStandardVehicleValues(authCheck, id);
    if (res.state === 200) {
      setOpen(true);
      setOpenTwo(true);
      setCheck(true);
      setCheckTwo(true);
      Object.entries(res.data).forEach((i) => {
        if (i[0] !== 'std_vehicle_val_typical_origin') {
          setValue(i[0], i[1])
        }
      }
      )
      return setDataShow(res.data);
    }
  }

  useEffect(() => {
    if (idVariable === 'edit') {
      fetchShowData(sectId);
    }
    if (dirtyFields.std_vehicle_val_typical_origin === true) {
      setTypicalOrigin({});
    }
  }, []);

  useEffect(() => {
    if (dataShow) {
      const fetchPlace = async () => {
        const res_place = await requestReverseGetPlace(dataShow.std_vehicle_val_typical_origin_lat, dataShow.std_vehicle_val_typical_origin_lng);
        let object_place = {
          city_name: res_place.results[0].city,
          country: res_place.results[0].country,
          country_code: res_place.results[0].country_code,
          lat: res_place.results[0].lat,
          lng: res_place.results[0].lon,
          name_formatted: res_place.results[0].formatted,
          place_id: res_place.results[0].place_id,
          result_type: res_place.results[0].result_type,
          state_code: res_place.results[0].state_code,
          state_name: res_place.results[0].state,
        }
        setValue('std_vehicle_val_typical_origin', object_place.name_formatted);
        setObjPlace(object_place);
        setTypicalOrigin(object_place);
      }
      fetchPlace();
    }
  }, [dataShow]);

  console.log(dataShow);

  useEffect(() => {
    setValue('std_vehicle_val_load_size', registerForm?.std_vehicle_values?.std_vehicle_val_load_size);
    setValue('std_vehicle_val_trailer_type', registerForm?.std_vehicle_values?.std_vehicle_val_trailer_type);
  }, [load_sizes, trailer_types])

  const section_one = [
    'std_vehicle_val_load_size',
    'std_vehicle_val_trailer_type',
    'std_vehicle_val_fuel_pyear',
    'std_vehicle_val_maintenance',
    'std_vehicle_val_equipment',
    'std_vehicle_val_tire',
    'std_vehicle_val_permits',
    'std_vehicle_val_insurance'
  ];

  const section_two = [
    'std_vehicle_val_annual_wages',
    'std_vehicle_val_annual_benefits',
  ];

  useEffect(() => {
    let data = {};
    std_veh_val.map(element => {
      let value = element.pv_data?.value ?? '';
      data[element.pv_name] = value;
    });
    setStdVehValBasedData(data);
    setTotal(registerForm?.total ?? 0);
  }, [std_veh_val]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => totalHandler(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const totalHandler = (value) => {
    if (value == 'std_vehicle_val_typical_origin') {
      return;
    }
    let total = 0;
    let divisor = value.std_vehicle_val_avg_miles_driven_pyear;
    if (!divisor) {
      return setTotal(0);
    }
    delete value.std_vehicle_val_name;
    delete value.std_vehicle_val_avg_miles_driven_pyear;
    delete value.std_vehicle_val_typical_origin;
    delete value.std_vehicle_val_load_size;
    delete value.std_vehicle_val_trailer_type;
    let keys = Object.keys(value);
    keys.map(element => parseFloat(value[element]) && parseFloat(value[element]) != "" && parseFloat(value[element]) != 0 && (total += parseFloat(value[element]) / divisor))
    setTotal(total.toFixed(2));
  }

  const handleReset = () => setInstanceKey(i => i + 1)

  const loadStdVehValBased = async ({ checked = false, fields = [] }) => {
    let data = checked ? fields.filter(x => !stdVehValBased.includes(x)) : stdVehValBased.filter(x => !fields.includes(x));
    checked && (data = [...stdVehValBased, ...data]);
    setStdVehValBased(data);
    checked ? data.map(element => setValue(element, stdVehValBasedData[element])) : fields.map(element => resetField(element));
    handleReset()
  }

  const handleSubmitter = async (data) => {
    let std_vehicle_values = { ...data, std_vehicle_val_typical_origin: typicalOrigin, std_vehicle_val_user_id: userInfo.user_id, std_vehicle_val_total: parseFloat(total) };
    let formData = { std_vehicle_values };
    try {
      if (idVariable === 'create') {
        const responseData = await requestSaveStandardVehicleValue(formData, authCheck);
        Swal.fire({
          background: '#121A1F',
          title: 'Wait a minute',
          text: 'Standard Vehicle Value successfully created',
          showConfirmButton: false,
          timer: 3000,
          width: '305px',
        });
        history.push('/settings/default_settings/');
      } else {
        const responseData = await requestEditStandardVehicleValues(formData, authCheck, sectId);
        Swal.fire({
          background: '#121A1F',
          title: 'Wait a minute',
          text: 'Standard Vehicle Value successfully updated',
          showConfirmButton: false,
          timer: 3000,
          width: '305px',
        });
        history.push('/settings/default_settings/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onError = (errors) => {
    setOpen(true);
    setOpenTwo(true);
  };

  const goBack = () => {
    if (url) {
      if (url !== '/dashboard') {
        history.goBack()
      }
    }
    return;
  }

  return (
    <FullLayout>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100 mt-5">
        <div className='col-md-5'>
          <div className='d-flex flex-row flex-wrap'>
            <FiCornerUpLeft onClick={() => goBack()} className="go-back-arrow me-3" />
            <h1 className="heading-h1 fw-bold text-capitalize">Variable cost</h1>
          </div>
          <div className='d-flex flex-row flex-wrap mb-3'>
            <p className='subheading-top mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut efficitur vulputate maximus. Fusce rutrum fringilla risus, vel sodales augue convallis et. Phasellus porta mollis pharetra.</p>
          </div>
          <form className='d-flex flex-row flex-wrap' onSubmit={handleSubmit(handleSubmitter, onError)}>
            <div className="mb-3 col-md-12">
              <label className="form-label">Name this variable cost</label>
              <input
                {...register("std_vehicle_val_name", { required: 'Name variable cost is required' })}
                type="text"
                className={errors.std_vehicle_val_name ? "form-control invalid" : "form-control"}
                placeholder="Xxxxxxxxxx"
                defaultValue={dataShow?.std_vehicle_val_name ? dataShow?.std_vehicle_val_name : ''}
              />
            </div>
            <div className="col-md-12">
              <button
                type="button"
                onClick={() => {
                  setValue('std_vehicle_val_avg_miles_driven_pyear', 100000);
                  setOpen(true);
                  setOpenTwo(true);
                  setCheck(true);
                  loadStdVehValBased({
                    checked: true,
                    fields: section_one
                  })
                  setCheckTwo(true);
                  loadStdVehValBased({
                    checked: true,
                    fields: section_two
                  })
                }}
                className="w-100 btn btn-lg btn-secondary mb-3"
              >
                USE STANDARD VALUES
              </button>
              <p className="orLogin pe-2">Customize your variable cost</p>
            </div>
            <div className="col-12 d-flex flex-row flex-wrap form-variableCost">
              <div className="mb-3 col-md-6">
                <label className="form-label">Average miles driven per year</label>
                <input
                  {...register("std_vehicle_val_avg_miles_driven_pyear", {
                    required: 'Average miles is required',
                  })}
                  type="number"
                  className={
                    errors.std_vehicle_val_avg_miles_driven_pyear
                      ? "form-control invalid"
                      : "form-control"
                  }
                  defaultValue={dataShow?.std_vehicle_val_avg_miles_driven_pyear ? dataShow?.std_vehicle_val_avg_miles_driven_pyear : (registerForm?.std_vehicle_values?.std_vehicle_val_avg_miles_driven_pyear)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Typical origin *</label>
                <PlaceAutocomplete
                  extra={{
                    ...register("std_vehicle_val_typical_origin", {
                      required: 'Typical origin is required',
                    }),
                  }
                  }
                  className={
                    errors.std_vehicle_val_typical_origin
                      ? "form-control invalid"
                      : "form-control"
                  }
                  setValue={setTypicalOrigin}
                  defaultValue={dataShow?.std_vehicle_val_typical_origin ? objPlace.name_formatted : (registerForm?.std_vehicle_values?.std_vehicle_val_typical_origin?.name_formatted)}
                />
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2 collapse-border-bottom">
                  <div className="d-flex flex-row flex-wrap align-items-center">
                    <button
                      type="button"
                      onClick={() => setOpen(!open)}
                      aria-expanded={open}
                      className="btn-clean text-white"
                    >
                      {!open ? <FiChevronDown /> : <FiChevronUp />}
                    </button>
                    <p className="collapse-title">Vehicle based costs</p>
                  </div>
                  <div>
                    <div className="form-check form-check-reverse">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={check}
                        onChange={(event) => {
                          setCheck(event.target.checked);
                          loadStdVehValBased({
                            checked: event.target.checked,
                            fields: section_one
                          })
                          setOpen(true);
                        }}
                      />
                      <label className="form-check-label collapse-label">
                        Standard values
                      </label>
                    </div>
                  </div>
                </div>
                <Collapse in={open}>
                  <div id="collapse-text-1">
                    <div className="d-flex flex-row flex-wrap justify-content-between">
                      <div className="mb-3 col-md-6 pe-2">
                        <label className="form-label" >Load size</label>
                        <select
                          {...register("std_vehicle_val_load_size", {
                            required: 'Load size is required',
                            validate: value => value != 0
                          })}
                          className={
                            errors.std_vehicle_val_load_size
                              ? "form-select invalid"
                              : "form-select"
                          }
                        >
                          <option disabled value="0">
                            Select load size
                          </option>
                          {load_sizes?.map((e, i) => (
                            <option key={i} value={e.pv_id} selected={dataShow?.std_vehicle_val_load_size ? dataShow?.std_vehicle_val_load_size : (registerForm?.std_vehicle_values?.std_vehicle_val_load_size == e.pv_id)}>
                              {e.pv_name}
                            </option>

                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-6 ps-1">
                        <label className="form-label">Trailer type</label>
                        <select
                          {...register("std_vehicle_val_trailer_type", {
                            required: 'Trailer type is required',
                            validate: value => value !== 0
                          })}
                          className={
                            errors.std_vehicle_val_trailer_type
                              ? "form-select invalid"
                              : "form-select"
                          }
                        >
                          <option disabled value="0">
                            Select trailer type
                          </option>
                          {trailer_types?.map((e, i) => (
                            <option key={i + '_' + instanceKey} value={e.pv_id} selected={dataShow?.std_vehicle_val_trailer_type ? dataShow?.std_vehicle_val_trailer_type : (registerForm?.std_vehicle_values?.std_vehicle_val_trailer_type == e.pv_id)}>
                              {e.pv_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap form-singUp">
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Fuel per year</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_fuel_pyear", {
                              required: 'Fuel per year is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_fuel_pyear
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_fuel_pyear ? dataShow?.std_vehicle_val_fuel_pyear : (registerForm?.std_vehicle_values?.std_vehicle_val_fuel_pyear)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Maintenance</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_maintenance", {
                              required: 'Maintenance is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_maintenance
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_maintenance ? dataShow?.std_vehicle_val_maintenance : (registerForm?.std_vehicle_values?.std_vehicle_val_maintenance)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label me-2">Equipment</label>
                        <CustomToolTip
                          classTooltip={'tooltip-content'}
                          classText={'text-contentTooltip'}
                          width={'421px'}
                          icon={<FiHelpCircle className="text-tooltip me-2" />}
                          title={'Equipment'}
                          content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi. '}
                          direction="bottom" >
                          <FiHelpCircle className="info-icon-help mb-1" />
                        </CustomToolTip>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_equipment", {
                              required: 'Equipment is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_equipment
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_equipment ? dataShow?.std_vehicle_val_equipment : (registerForm?.std_vehicle_values?.std_vehicle_val_equipment)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap form-singUp">
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Tires</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_tire", {
                              required: 'Tires is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_tire
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_tire ? dataShow?.std_vehicle_val_tire : (registerForm?.std_vehicle_values?.std_vehicle_val_tire)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Permits</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_permits", {
                              required: 'Permits is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_permits
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_permits ? dataShow?.std_vehicle_val_permits : (registerForm?.std_vehicle_values?.std_vehicle_val_permits)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Insurance</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_insurance", {
                              required: 'Insurance is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_insurance
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_insurance ? dataShow?.std_vehicle_val_insurance : (registerForm?.std_vehicle_values?.std_vehicle_val_insurance)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2 collapse-border-bottom">
                  <div className="d-flex flex-row flex-wrap align-items-center">
                    <button
                      type="button"
                      onClick={() => setOpenTwo(!openTwo)}
                      aria-expanded={openTwo}
                      className="btn-clean text-white"
                    >
                      {!openTwo ? <FiChevronDown /> : <FiChevronUp />}
                    </button>
                    <p className="collapse-title">Driver based costs</p>
                  </div>
                  <div>
                    <div className="form-check form-check-reverse">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={checkTWo}
                        onChange={(event) => {
                          setCheckTwo(event.target.checked);
                          loadStdVehValBased({
                            checked: event.target.checked,
                            fields: section_two
                          })
                          setOpenTwo(true);
                        }}
                      />
                      <label className="form-check-label collapse-label">
                        Standard values
                      </label>
                    </div>
                  </div>
                </div>
                <Collapse in={openTwo}>
                  <div id="collapse-text-2">
                    <div className="d-flex flex-row flex-wrap form-singUp">
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Annual wages</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_annual_wages", {
                              required: 'Annual wages is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_annual_wages
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_annual_wages ? dataShow?.std_vehicle_val_annual_wages : (registerForm?.std_vehicle_values?.std_vehicle_val_annual_wages)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Annual benefits</label>
                        <div className="input-group">
                          <span className="input-group-text groupText">$</span>
                          <input
                            {...register("std_vehicle_val_annual_benefits", {
                              required: 'Annual benefits is required',
                            })}
                            type="number"
                            step={'0.01'}
                            className={
                              errors.std_vehicle_val_annual_benefits
                                ? "form-control invalid"
                                : "form-control groupInput"
                            }
                            defaultValue={dataShow?.std_vehicle_val_annual_benefits ? dataShow?.std_vehicle_val_annual_benefits : (registerForm?.std_vehicle_values?.std_vehicle_val_annual_benefits)}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
              <p className="orLogin pe-2 w-100">This is your total variable cost</p>
              <div className="mb-3 col-md-12">
                <p className="total-field">$ {total ? total : (!total ? dataShow?.std_vehicle_val_total : 0)}
                </p>
              </div>
              <div className="mb-3 col-md-6">
                <button type="submit" className="w-100 btn btn-lg btn-secondary mb-3">
                  CANCEL
                </button>
              </div>
              <div className="mb-3 col-md-6">
                <button type="submit" className="w-100 btn btn-lg btn-success mb-3">
                  {idVariable === 'create' ? 'SAVE CHANGES' : 'UPDATE CHANGES'}
                </button>
              </div>

            </div>
          </form>
          <div className="mb-5">
            {Object.keys(errors).length > 0 &&
              Object.entries(errors).map((item) =>

                <p className="text-danger text-error mb-1" role="alert" key={item[0]}>
                  {item[1].message}
                </p>

              )}
          </div>
        </div>
      </div>
    </FullLayout>
  )
}

export default VariableCostSettings