import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FiMoreVertical, FiPlus } from "react-icons/fi";
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import { Nav } from 'react-bootstrap';
import SimpleModal from '../../components/modals/SimpleModal';
import { useSelector } from 'react-redux';
import { selectCategoryState } from '../../redux/indicators/CategoriesSlice';

const IndicatorsBar = ({ settingItem, getTitle, ...rest }) => {
    const scrollRef = useHorizontalScroll();
    const category_list = useSelector(selectCategoryState);

    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [arr, setArr] = useState(settingItem);

    const [sectionTitle, setSectionTitle] = useState(rest?.setSectionTitle?.indicator_category_name ?? 'Name Category');

    useEffect(() => {
        setSectionTitle(rest?.setSectionTitle?.indicator_category_name);
    }, [rest?.setSectionTitle]);

    useEffect(() => {
     if (settingItem) {
        setArr(settingItem);
     }
    }, [settingItem]);


    const orderByDate = [...category_list].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
    return (
        <div className='setting'>
            <div className='settingSearch d-flex justify-content-between'>
                <span className='btnSettings'><span className='fw-bold'>Category</span> - {sectionTitle}</span>
                <div className='dropSettings'>
                    <DropdownButton
                        drop={'down'}
                        title={<FiMoreVertical className='icon-route-planning' />}
                    >
                        <Dropdown.Item>Sort by</Dropdown.Item>
                        <Dropdown.Item>View</Dropdown.Item>
                        {rest?.category?.indicator_category_user_id &&
                        <>
                            <Dropdown.Item onClick={() => setShowModalEdit(!showModalEdit)}>Edit folder name</Dropdown.Item>
                            <Dropdown.Item onClick={() => rest.deleteCategory()}>Remove folder</Dropdown.Item>
                        </>
                        }
                    </DropdownButton>
                </div>
            </div>
            <Nav ref={scrollRef} className="flex-row settingBar flex-nowrap">
                {orderByDate.map((item, index) =>
                    <Nav.Item key={index} className='sectionSettings'>
                        <Nav.Link eventKey={item.indicator_category_id} as={'div'} className='btnSettings text-decoration-none' onClick={(e) => { setSectionTitle(e.target.textContent); getTitle(e, item) }}>{item.indicator_category_name}</Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
            <button className='btnBar' onClick={() => setShowModalAdd(!showModalAdd)}> <label>Add folder</label>
                <FiPlus className="icon-h1" />
            </button>
            <SimpleModal
                actionModal={rest.createCategory}
                showSmallModal={showModalAdd}
                setShowSmallModal={setShowModalAdd}
                title={'New folder'}
                formName={'indicator_category_name'}
                letterBtn={'CREATE FOLDER'}
                subTitle={'Folder name'} buttons={false}
                titleAlert={'Created folder'}
                textAlert={'Now, you can save your settings'} />
            <SimpleModal
                actionModal={rest.editCategory}
                showSmallModal={showModalEdit}
                setShowSmallModal={setShowModalEdit}
                title={'Edit folder'}
                formName={'indicator_category_name'}
                letterBtn={'EDIT FOLDER'}
                subTitle={'Folder name'} buttons={false}
                titleAlert={'folder was updated'}
                textAlert={'Now, you can save your settings'} />
        </div>
    )
}

export default IndicatorsBar