import React, { useEffect, useState } from "react";
import RouteStatisticCard from "./RouteStatisticCard";

const StatisticCardsSection = (props) => {
	const { totals, benchmarkValues } = props;

	const [cardData, setCardData] = useState([
		{
			title: "Total profit",
			type: 1,
			values: "$ ---",
			iconType: 1,
			description: [
				{
					value: "---",
					value_description: "--- ---",
				},
			],
			detail_info: {
				title: "Profit detail",
				type: 1,
				detail_info_values: [
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
				],
			},
		},
		{
			title: "Total cost",
			type: 2,
			values: "$ ---",
			iconType: 0,
			description: [
				{ value: "-$ ---", value_description: "fuel" },
				{ value: "-% ---", value_description: "tolls" },
			],
			detail_info: {
				title: "Cost detail",
				type: 2,
				detail_info_values: [
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
				],
			},
		},
		{
			title: "Total mileage",
			type: null,
			values: "--- mi",
			iconType: 3,
			description: [
				{
					value: "--- d",
					value_description: "operative time",
				},
			],
			detail_info: {
				title: "Mileage",
				type: null,
				detail_info_values: [
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
				],
			},
		},
		{
			title: "Total emissions (co2)",
			type: null,
			values: "--- kgs",
			iconType: 1,
			description: [
				{
					value: "--- kgs",
					value_description: "--- ---",
				},
			],
			detail_info: {
				title: "Emissions (co2) detail",
				type: null,
				detail_info_values: [
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
					{ value: 100, value_description: "01 day" },
				],
			},
		},
	]);

	useEffect(() => {
		if (!totals || !benchmarkValues) {
			return;
		}
		let data = cardData;

		/*---total profit---*/
		const avg_national_profit =
			benchmarkValues["profit"]?.find(
				(element) => element.pv_name == "avg"
			).pv_value ?? 0;
		const profit_diff = totals?.avg_profit - avg_national_profit;
		data[0].values = "$" + (totals?.avg_profit?.toFixed(2) ?? 0);
		data[0].description[0].value = "$" + Math.abs(profit_diff)?.toFixed(2);
		data[0].description[0].value_description =
			(profit_diff > 0 ? "above" : "below") + " the national avg.";
		data[0].iconType = profit_diff > 0 ? 1 : 2;
		data[0].type = totals?.avg_profit > 0 ? 1 : 2;

		/*---total cost---*/
		const tolls_pge = (totals?.tolls * 100) / totals?.total_variable_cost;
		data[1].values = "$" + (totals?.total_variable_cost?.toFixed(2) ?? 0);
		data[1].description[0].value = "-$" + totals?.fuel_cost?.toFixed(2);
		data[1].description[1].value = "-%" + tolls_pge;

		/*---total mileage---*/
		data[2].values = Math.round(totals?.miles ?? 0) + " mi";
		data[2].description[0].value =
			(totals?.operative_time?.secondsToDays() ?? 0) + "d";

		/*---total emissions---*/
		const avg_national_emissions =
			benchmarkValues["profit"]?.find(
				(element) => element.pv_name == "avg"
			).pv_value ?? 0;
		const emissions_diff = totals?.co2_emission - avg_national_emissions;
		data[3].values = Math.round(totals?.co2_emission ?? 0) + " kgs";
		data[3].description[0].value =
			"$" + Math.abs(emissions_diff)?.toFixed(2);
		data[3].description[0].value_description =
			(emissions_diff > 0 ? "above" : "below") + " the national avg.";
		data[3].iconType = emissions_diff > 0 ? 1 : 2;

		setCardData(data);
	}, [totals, benchmarkValues]);

	return cardData?.map((item) => (
		<div className="col-md-3 card-stat" key={item.title}>
			<RouteStatisticCard data={item} />
		</div>
	));
};

export default StatisticCardsSection;
