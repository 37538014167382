const API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestConfirmForgotPassword = async (form) => {
    form = JSON.stringify(form);
    let response;
    await fetch(`${API_URL}confirmForgotPassword`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: form,
    })
        .then((response) => response.json())
        .then((data) => {
            response = data;
        })
        .catch((error) => {
            response = {
                state: 500,
                data: null,
                error,
                message: "Connection error, try again later",
            };
        });

    return response;
};

export default requestConfirmForgotPassword;