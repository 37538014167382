import React from "react";
import { FiCornerUpLeft } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import FullLayout from "../../../../components/layout/FullLayout";

const StepOne = () => {
  const history = useHistory();
  let { url } = useRouteMatch();

  const goBack = () => {
    if (url) {
      if (url !== "/dashboard") {
        history.goBack();
      }
    }
    return;
  };

  return (
    <>
        <FullLayout>
          <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
            <div className="col-md-5">
              <div className="d-flex flex-row flex-wrap">
                <FiCornerUpLeft
                  onClick={() => goBack()}
                  className="go-back-arrow me-3"
                />
                <h1 className="heading-h1 fw-bold text-capitalize">
                  Confirm plan
                </h1>
              </div>
              <div className="d-flex flex-row flex-wrap">
                <p className="subheading-top mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div className="divider-3 mb-0"></div>
                <div className="col-md-12 d-flex flex-row flex-wrap flex-gaping mt-4">
                  <div className="col-md-6">
                    <button type="button" className="btn btn-secondary w-100">
                      CANCEL
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button type="button" className="btn btn-success w-100"  onClick={() => history.push('/settings/payment/payments/step_two')}>
                      GO TO PAY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FullLayout>
    </>
  );
};

export default StepOne;
