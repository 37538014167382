const API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestForgotPassword = async (user_email) => {
    const form = JSON.stringify({ user_email });
    let response;
    await fetch(`${API_URL}forgotPassword`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: form,
    })
        .then((response) => response.json())
        .then((data) => {
            response = data;
        })
        .catch((error) => {
            response = {
                state: 500,
                data: null,
                error,
                message: "Connection error, try again later",
            };
        });

    return response;
};

export default requestForgotPassword;