/* eslint-disable eqeqeq */
let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestGetIndicators = async (user_token, params = [], id_indicator_cat = null) => {
  let response;
//   const user_token = window.localStorage.getItem("user_token");
let url = `${API_URL}indicator`;
  
 if (id_indicator_cat) {
   url = `${API_URL}indicator?indicator_category_id=${id_indicator_cat}`;
 } else {
  for (let i = 0; i < params.length; i++) {
    url = url + (i == 0 ? '?' : '&') + `${params[i]['key']}=${params[i]['value']}`;
  }
 }

  await fetch(url, {
    headers: {
      Authorization: user_token,
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });

  return response;
};

export default requestGetIndicators;
