import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: {},
};
export const UserSlice = createSlice({
  name: "user_data",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.value = action.payload;
    },
    deleteUserData: (state) => {
      state.value = {};
    },
  },
});

export const { setUserData, deleteUserData } = UserSlice.actions;
export const selectUserDataState = (state) => state.user_data.value;

export default UserSlice.reducer;
