/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from "react";
import FullLayout from "../../../../components/layout/FullLayout";
import { FiCornerUpLeft } from "react-icons/fi";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../../components/CustomInput";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import ModalVerify from "../../ModalVerify";
import {selectUserDataState, setUserData } from "../../../../redux/user/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParameter } from "../../../../hooks/useParameter";
import requestUpdateUser from "../../../../services/user/requestUpdateUser";
import requestValidatePasswordUser from "../../../../services/user/requestValidatePasswordUser";
import requestUpdatePasswordUser from "../../../../services/user/requestUpdatePasswordUser";
import { selectGuardianState } from "../../../../redux/auth/GuardianSlice";
import { selectCognitoSlice } from "../../../../redux/auth/CognitoSlice";
import Swal from "sweetalert2";
import requestReverseGetPlace from "../../../../services/default_settings/requestReverseGetPlace";
import { getAuthenticatedUser } from "../../../../auth";

export const FormButton = ({ type, title, section }) => { 
  if(section == "email" || section == "password" || section == "phone") {
    title = "CONTINUE";
  }
  return (
    <button type={type} className="btn btn-success w-100">
      {title}
    </button>
  );
};

FormButton.defaultProps = {
  type: "submit",
  title: "SAVE CHANGES",
};

export const CustomSection = ({
  label,
  name,
  control,
  rules,
  defaultValue,
  type,
  placeholder,
}) => {
  return (
    <section className="col-md-12 ps-0">
      {label && <label className="form-label mb-1">{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <div>
            <CustomInput
              value={field.value}
              onChange={field.onChange}
              type={type}
              placeholder={placeholder}
            />
          </div>
        )}
      />
    </section>
  );
};

const EditInfoUser = ({ routes }) => {
  const mounted = useRef(false);
  let { idEdit } = useParams();
  const history = useHistory();
  let { url } = useRouteMatch();
  const dispatch = useDispatch();
  const [wizardStp, setWizardStp] = useState();
  const [showModalVerify, setShowModalVerify] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState("");
  const userInfo = useSelector(selectUserDataState);
  const authCheck = useSelector(selectGuardianState);
  const cognitoToken = useSelector(selectCognitoSlice);
  const [isPasswordValidated, setIsPasswordValidated] = useState();
  const user_gender = useParameter("user_gender");
  const [itemCity, setItemCity] = useState({});
  const [objPlace, setObjPlace] = useState({});
  const city_info = JSON.parse(userInfo?.user_city ?? null);

  useEffect(() => {
		if (!mounted.current) {
      mounted.current = true;
			const loadStep = async () => {
        const authenticated_user = await getAuthenticatedUser();
        const step = authenticated_user == null ? 1 : 2;
        setWizardStp(step);
        setIsPasswordValidated(step == 2);
      };
      loadStep();
		}
	}, []);

  const arr = [];

  let objWhy = [
    { id: 1, name: "I lost my password" },
    { id: 2, name: "Personal reason" },
    { id: 3, name: "Other" },
  ];

  user_gender.forEach((element) => {
    let obj = { id: element.pv_id, name: element.pv_name };
    arr.push(obj);
  });

  const dynamicForm = {
    name: {
      firstName: {
        label: "First name",
        type: "text",
        placeholder: "Enter your first name",
        classDiv: "col-md-12",
        name: "user_name",
        defaultValue: userInfo.user_name,
        rules: {
          required: "Name is required",
        },
        wizard: null,
      },
      lastName: {
        label: "Last name",
        type: "text",
        name: "user_last_name",
        placeholder: "Enter your last name",
        classDiv: "col-md-12 ps-0",
        defaultValue: userInfo.user_last_name,
        rules: {
          required: "Last name is required",
        },
        wizard: null,
      },
    },
    gender: {
      gender_types: {
        label: null,
        type: "radio",
        name: "user_gender",
        options: arr,
        classDiv: "col-md-12",
        defaultValue: userInfo.user_gender,
        rules: {
          required: true,
        },
        wizard: null,
      },
    },
    email: {
      pass: {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Enter your password",
        classDiv: "col-md-12",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 1,
      },
      why: {
        label: "Why",
        type: "radio",
        name: "user",
        options: objWhy,
        classDiv: "col-md-12",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 2,
      },
      new_email: {
        label: "Your New E-mail",
        type: "email",
        name: "user_email",
        placeholder: "xxxxxxxxx",
        classDiv: "col-md-12",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 3,
      },
    },
    password: {
      pass: {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Xxxxxxxx",
        classDiv: "col-md-12",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 1,
      },
      pass_new: {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Xxxxxxxx",
        classDiv: "col-md-12",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 2,
      },
      pass_new_confirmation: {
        label: "Confirm password",
        type: "password",
        name: "password_confirmation",
        placeholder: "Xxxxxxxx",
        classDiv: "col-md-12 ps-0",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 2,
      },
    },
    phone: {
      pass: {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: "Xxxxxxxx",
        classDiv: "col-md-12",
        defaultValue: "",
        rules: {
          required: true,
        },
        wizard: 1,
      },
      phone_number: {
        label: "Phone number *",
        type: "number",
        name: "user_cellphone",
        placeholder: "(XXX) XXX XXXX",
        classDiv: "col-md-12",
        defaultValue: userInfo.user_cellphone,
        rules: {
          required: "Phone number is required",
        },
        wizard: 2,
      },
    },
    location: {
      address_line_one: {
        label: "Address line 1",
        type: "text",
        name: "user_address_line1",
        placeholder: "413 Russell Park",
        classDiv: "col-md-6",
        defaultValue: userInfo.user_address_line1,
        rules: {
          required: true,
        },
        wizard: null,
      },
      address_line_two: {
        label: "Address line 2",
        type: "text",
        name: "user_address_line2",
        placeholder: "413 Russell Park",
        classDiv: "col-md-6",
        defaultValue: userInfo.user_address_line2,
        rules: {
          required: true,
        },
        wizard: null,
      },
      city: {
        label: "City",
        type: "autocomplete",
        name: "user_city",
        classDiv: "col-md-10 ps-0",
        defaultValue:  objPlace.name_formatted,
        rules: {
          required: false,
        },
        wizard: null,
      },
      zid_code: {
        label: "Zip code",
        type: "number",
        name: "user_zip_code",
        placeholder: "XXXX",
        classDiv: "col-md-2",
        defaultValue: userInfo.user_zip_code,
        rules: {
          required: true,
        },
        wizard: null,
      },
    },
    job_information: {
      company: {
        label: "Company",
        type: "text",
        name: "user_company_name",
        placeholder: "Company name",
        classDiv: "col-md-6",
        defaultValue: userInfo.user_company_name,
        rules: {
          required: true,
        },
        wizard: null,
      },
      role: {
        label: "Role",
        type: "select",
        name: "user_job",
        options: [
          "Independent dispatcher",
          "Carrier dispatcher",
          "Owner operator",
          "Other",
        ],
        classDiv: "col-md-6",
        defaultValue: userInfo.user_job,
        rules: {
          required: true,
        },
        wizard: null,
      },
    },
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (city_info) {
      const fetchPlace = async () => {
        const res_place = await requestReverseGetPlace(city_info.lat, city_info.lng);
        let object_place = {
          city_name: res_place.results[0].city,
          country: res_place.results[0].country,
          country_code: res_place.results[0].country_code,
          lat: res_place.results[0].lat,
          lng: res_place.results[0].lon,
          name_formatted: res_place.results[0].formatted,
          place_id: res_place.results[0].place_id,
          result_type: res_place.results[0].result_type,
          state_code: res_place.results[0].state_code,
          state_name: res_place.results[0].state,
        }
        setValue('user_city', object_place.name_formatted);
        setObjPlace(object_place);
        setItemCity(object_place);
      }
      fetchPlace();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchShowWh = watch("why", false);
  const formInputs = Object.keys(dynamicForm[idEdit]).map((e, index) => {
    const { rules, defaultValue, label, wizard, classDiv, name } =
    dynamicForm[idEdit][e];
    return (
      <>
        {wizard ? (
          wizardStp == wizard && (
            <section key={index} className={classDiv}>
              <label className="form-label mb-1">{label}</label>
              <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field }) => (
                  <div>
                    <CustomInput
                      value={field.value}
                      onChange={field.onChange}
                      {...dynamicForm[idEdit][e]}
                    />
                  </div>
                )}
              />
              {errors[e] && (
                <p className="text-danger text-error text-start" role="alert">
                  This field is required
                </p>
              )}
            </section>
          )
        ) : (
          <section key={index} className={classDiv}>
            <label className="form-label mb-1">{label}</label>
            <Controller
              name={name}
              control={control}
              rules={rules}
              defaultValue={defaultValue}
              render={({ field }) => (
                <div>
                  <CustomInput
                    value={field.value}
                    setCity={setItemCity}
                    onChange={field.onChange}
                    {...dynamicForm[idEdit][e]}
                  />
                </div>
              )}
            />
            {errors[e] && (
              <p className="text-danger text-error text-start" role="alert">
                This field is required
              </p>
            )}
          </section>
        )}
      </>
    );
  });

  async function validatePassword(dataPassword) {
    const res = await requestValidatePasswordUser(dataPassword, authCheck);
    if (res.state === 200) {
      return true;
    } else {
      return false;
    }
  }

  async function updatePassword(dataNewPassword) {
    const res = await requestUpdatePasswordUser(dataNewPassword, authCheck);
    if (res.state === 200) {
      return true;
    } else {
      return false;
    }
  }

  const onSubmit = async (data) => {
    let usr_id = userInfo.user_id;
    try {
      switch (idEdit) {
        case "email":
          const validate = await validatePassword({ ...data, user_id: usr_id });
          if (validate) {
            setWizardStp(wizardStp + 1);
            return console.log("The password is correct");
          } else {
            return Swal.fire({
              background: "#121A1F",
              title: "Wait a minute",
              text: "The password is incorrect",
              showConfirmButton: false,
              timer: 2000,
              width: "305px",
            });
          }
          // eslint-disable-next-line no-unreachable
          break;
        case "password":
          if (isPasswordValidated === true) {
            const updatePass = await updatePassword({ password: data.password, password_confirmation: data.password_confirmation});
            if (updatePass) {
              Swal.fire({
                background: "#121A1F",
                title: "Wait a minute",
                text: "The password was updated",
                showConfirmButton: false,
                timer: 2000,
                width: "305px",
              });
              setIsPasswordValidated(false);
              history.push("/settings/account/");
            } else {
              Swal.fire({
                background: "#121A1F",
                title: "Wait a minute",
                text: "The passwords do not match",
                showConfirmButton: false,
                timer: 2000,
                width: "305px",
              });
            }
          } else {
            const validatePass = await validatePassword({ ...data, user_id: usr_id });
            if (validatePass) {
              setWizardStp(wizardStp + 1);
              setIsPasswordValidated(true);
              setValue("password", "");
            } else {
              Swal.fire({
                background: "#121A1F",
                title: "Wait a minute",
                text: "The password is incorrect",
                showConfirmButton: false,
                timer: 2000,
                width: "305px",
              });
              setIsPasswordValidated(false);
            }
          }
          break;
        case "phone":
          if (isPasswordValidated === true) {
            const formData = { ...data };
            const updateNumber = await requestUpdateUser(
              { user_cellphone: formData.user_cellphone, cognito_token: cognitoToken },
              authCheck,
              usr_id
            );
            if (updateNumber) {
              Swal.fire({
                background: "#121A1F",
                title: "Wait a minute",
                text: "successfully updated",
                showConfirmButton: false,
                timer: 3000,
                width: "305px",
              });
              dispatch(setUserData(updateNumber.data));
              setIsPasswordValidated(false);
              history.push("/settings/account/");
            }
          } else {
            const validatePass = await validatePassword({
              ...data,
              user_id: usr_id,
            });
            if (validatePass) {
              setWizardStp(wizardStp + 1);
              setIsPasswordValidated(true);
              console.log("The password is correct");
            } else {
              Swal.fire({
                background: "#121A1F",
                title: "Wait a minute",
                text: "The password is incorrect",
                showConfirmButton: false,
                timer: 2000,
                width: "305px",
              });
              setIsPasswordValidated(false);
            }
          }
          break;
        default:
          const jsonCity = itemCity;
          const formDataJsonString = JSON.stringify(jsonCity);
          let item_city = { ...data, user_city: formDataJsonString };

          const responseDataLocation = await requestUpdateUser(
            item_city,
            authCheck,
            usr_id
          );
          Swal.fire({
            background: "#121A1F",
            title: "Wait a minute",
            text: "successfully updated",
            showConfirmButton: false,
            timer: 3000,
            width: "305px",
          });
          dispatch(setUserData(responseDataLocation.data));
          history.push("/settings/account/");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const goBack = () => {
    if (url) {
      if (url !== "/dashboard") {
        history.goBack();
      }
    }
    return;
  };
  const onError = (errors) => {
    console.log(errors);
  };

  return (
    <FullLayout>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
        <div className="col-md-5">
          <div className="d-flex flex-row flex-wrap">
            <FiCornerUpLeft
              onClick={() => goBack()}
              className="go-back-arrow me-3"
            />
            <h1 className="heading-h1 fw-bold text-capitalize">{idEdit}</h1>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <p className="subheading-top mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              efficitur vulputate maximus. Fusce rutrum fringilla risus, vel
              sodales augue convallis et. Phasellus porta mollis pharetra.
            </p>
          </div>
          <div className="divider-3"></div>
          <form
            className="d-flex flex-row flex-wrap flex-gaping"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {formInputs}
            {wizardStp === 2 && watchShowWh === "Other" && (
              <CustomSection
                name={"why_text"}
                control={control}
                rules={{}}
                defaultValue={""}
                type={"text"}
                placeholder={"xxxxx"}
              />
            )}
            <div className="col-md-12 d-flex flex-row flex-wrap flex-gaping mt-4">
              <div className="col-md-6">
                <button type="button" className="btn btn-secondary w-100">
                  CANCEL
                </button>
              </div>
              <div className="col-md-6">
                <FormButton section={idEdit} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalVerify
        showModalVerify={showModalVerify}
        setShowModalVerify={setShowModalVerify}
        message={message}
      />
    </FullLayout>
  );
};

export default EditInfoUser;
