import React, { useEffect, useState } from "react";
import { FiFilter, FiMoreVertical, FiPlus } from "react-icons/fi";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import SmallModal from "../../../../components/modals/SmallModal";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch, useSelector } from "react-redux";
import { selectGuardianState } from "../../../../redux/auth/GuardianSlice";
import { selectUserDataState } from "../../../../redux/user/UserSlice";
import { selectUserRouteFoldersState, setUserRouteFolders } from "../../../../redux/userRouteFolders/UserRouteFoldersSlice";
import FilterResults from "../../../../components/modals/FilterResults";
import Swal from "sweetalert2";


import requestUserRouteFolders from "../../../../services/user_route_folders/requestUserRouteFolders";
import requestCreateUserRouteFolder from "../../../../services/user_route_folders/requestCreateUserRouteFolder";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useMemo } from "react";

//services
import requestDeleteUserRouteFolder from "../../../../services/user_route_folders/requestDeleteUserRouteFolder";
import requestEditUserRouteFolder from "../../../../services/user_route_folders/requestEditUserRouteFolder";

const DesignedRoutesBar = ({ idFolder, setIdFolder }) => {
	const scrollRef = useHorizontalScroll();
	const authCheck = useSelector(selectGuardianState);
	const userInfo = useSelector(selectUserDataState);
	const user_route_folders = useSelector(selectUserRouteFoldersState);

	const dispatch = useDispatch();

	const [nameFolder, setNameFolder] = useState('All routes');
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showFilter, setShowFilter] = useState(false);

	let history = useHistory();
	let { state } = useLocation();

	let { from } = state || { from: { pathname: "/my-routes/my_designed_routes" } };

	async function fetchData() {
		const response = await requestUserRouteFolders(authCheck, userInfo.user_id);
		if (response.state !== 200) {
			return;
		}
		dispatch(setUserRouteFolders(response.data));
	}

	useEffect(() => {
		fetchData();
	}, [showModalAdd, showModalEdit]);

	async function createFolder(nameFolder) {
		const formData = {
			urf_name: nameFolder,
			urf_user_id: userInfo.user_id,
		};
		const response = await requestCreateUserRouteFolder(formData, authCheck);
		setShowModalAdd(false);
	}

	async function editFolder(nameFolder) {
		const formData = {
			urf_name: nameFolder,
			urf_user_id: userInfo.user_id
		};
		const responseData = await requestEditUserRouteFolder(formData, authCheck, idFolder);
		setShowModalEdit(false);
	}

	const deleteFolder = async () => {
		if (!idFolder) {
			return;
		}
		Swal.fire({
			title: 'Are you sure? ',
			text: "Do you want to remove this folder?",
			background: '#121A1F',
			showCancelButton: true,
			showDenyButton: false,
			reverseButtons: true,
			cancelButtonText: 'CANCEL',
			confirmButtonText: 'REMOVE',
		}).then(async (result) => {
			if (result.isConfirmed) {
				const responseDelete = await requestDeleteUserRouteFolder(idFolder, authCheck);
				history.replace(from);
				fetchData();
			}
		});
	};

	return (
		<div className="setting">
			<div className="settingResults d-flex justify-content-between">
				<span className="btnSettings"><span className="fw-bold">My routes</span> - {nameFolder}</span>
				<div className={nameFolder === 'All routes' ? 'dropSettings d-none' : 'dropSettings'}>
					<DropdownButton
						drop={'down'}
						title={<FiMoreVertical className='icon-route-planning' />}
					>
						<Dropdown.Item onClick={() => setShowModalEdit(!showModalEdit)}>Edit folder name</Dropdown.Item>
						<Dropdown.Item onClick={deleteFolder}>Remove folder</Dropdown.Item>
					</DropdownButton>
				</div>
			</div>
			<div className="settingBar" ref={scrollRef}>
				{user_route_folders?.map((item) =>
					<button className='sectionSettings text-decoration-none' onClick={() => { setIdFolder(item.urf_id); setNameFolder(item?.urf_name) }}>
						<div className='btnSettings'>
							{item?.urf_name}
						</div >
					</button>
				)}
			</div>
			<button className="btnBar me-3" onClick={() => setShowFilter(!showFilter)}>
				<FiFilter className="icon-h1" />
			</button>
			<button
				className="btnBar"
				onClick={() => setShowModalAdd(!showModalAdd)}
			>
				 <label>Add folder</label>
				<FiPlus className="icon-h1" />
			</button>

			<SmallModal
				actionModal={createFolder}
				showSmallModal={showModalAdd}
				setShowSmallModal={setShowModalAdd}
				title={"New folder"}
				letterBtn={"CREATE FOLDER"}
				titleAlert={"Created folder"}
				textAlert={"Now, you can save your settings"}
			/>
			<SmallModal
				values={nameFolder}
				actionModal={editFolder}
				showSmallModal={showModalEdit}
				setShowSmallModal={setShowModalEdit}
				title={'Edit folder'}
				letterBtn={'UPDATE FOLDER'}
				titleAlert={'Changes saved'}
				textAlert={'Now, you can use this settings'}
			/>
			< FilterResults showFilter={showFilter} setShowFilter={setShowFilter} />
		</div>
	);
};
SmallModal.defaultProps = {
	subTitle: 'Folder name',
	buttons: false
};

export default DesignedRoutesBar;
