import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cognito_token: null,
};
export const CognitoSlice = createSlice({
  name: "cognito",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.cognito_token = action.payload;
    },
    removeToken: (state) => {
      state.cognito_token = null;
    },
  },
});

export const { setToken, removeToken } = CognitoSlice.actions;
export const selectCognitoSlice = (state) => state.cognito.cognito_token;

export default CognitoSlice.reducer;
