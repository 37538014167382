let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestGetParameterValues = async (reference, user_token) => {
	let response;
	await fetch(`${API_URL}parameter-values?parameter_reference=${reference}`, {
		headers: {
			Authorization: user_token,
			Accept: "application/json",
		},
	})
		.then((response) => response.json())
		.then((data) => {
			response = data;
		})
		.catch((error) => {
			response = {
				state: 500,
				data: null,
				error,
				message: "Connection error, try again later",
			};
		});

	return response;
};

export default requestGetParameterValues;
