import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);
Auth.configure({
	oauth: {
		domain: "routing.auth.us-east-1.amazoncognito.com",
		scope: ["openid", "email", "profile"],
		redirectSignIn: `${window.location.origin}/`,
		redirectSignOut: `${window.location.origin}/`,
		responseType: "token",
	},
});

const auth = Auth;

const getAuthenticatedUser = async () => {
	try {
		const user = await auth.currentAuthenticatedUser();
		return user;
	} catch (error) {
		return null;
	}
};

export { getAuthenticatedUser };

export default auth;
