import React from 'react';
import { FiTrendingUp, FiTrendingDown, FiBarChart } from "react-icons/fi";

const BenchmarkStatistical = ({ title, value, iconType, value_description }) => {
    return (<>
        <p className='subheading-top'>{title}</p>
        <p className='statistical-heading fw-bold'>$ {value}</p>
        {iconType === 1 ? <FiTrendingUp className="statistical-icon me-2" /> : (iconType === 2 ? <FiTrendingDown className="statistical-red-icon me-2" /> : <FiBarChart className="statistical-icon me-2" />)}
        <span className="statistical-description me-1" >
            {value_description}
        </span>

    </>);
}

export default BenchmarkStatistical;