import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FiMinus, FiPaperclip, FiSend } from 'react-icons/fi';
import CustomButton from '../../components/CustomButton';

const NewMessageOffCanvas = ({ mailer, show, handle, title, ...rest }) => {
    const { register, handleSubmit, reset } = useForm();
    const onSubmit = async data => { mailer(data); reset(); handle(); };

    return (
        <Offcanvas show={show} onHide={handle} {...rest}>
            <Offcanvas.Header className='p-0' closeButton closeVariant="white">
                <Offcanvas.Title as={'div'} className="d-flex flex-fill flex-row flex-wrap align-items-center justify-content-between" >
                    <h2 className='heading-h2 fw-bold'>{title}</h2>
                    <FiMinus className='btnSettings' />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-row flex-wrap align-items-center message-content-for">
                        <span className='label-for me-2' >For:</span>
                        <input {...register("message_for",)} type={'email'} className='form-control-plaintext input-for' defaultValue={''} list="recipients" autoComplete='off' />
                        <datalist id="recipients">
                            <option>support@efrouting.com</option>
                            <option>admin@efrouting.com</option>
                        </datalist>

                    </div>
                    <div className="d-flex flex-row flex-wrap align-items-center message-content-subject">
                        <span className='label-for me-2' >Subject:</span>
                        <input {...register("message_subject",)} type={'text'} className='form-control-plaintext input-for' defaultValue={''} autoComplete='off' />
                    </div>
                    <div>
                        <textarea {...register("message_body")} className='form-control-plaintext textarea-message-pane' id="" cols="30" rows="5"></textarea>
                    </div>
                    <div className='offcanvas-footer'>
                        <CustomButton type={'submit'} className={'btn btn-success-send'} icon={<FiSend />} title={'SEND'} />
                        <FiPaperclip className='btnSettings' />
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default NewMessageOffCanvas;