import React, { useEffect, useState } from "react";
import {
  FiArrowDownRight,
  FiArrowUpRight,
  FiMoreVertical,
} from "react-icons/fi";
import CustomToolTip from "../../../../components/layout/CustomToolTip";
import { useHover } from "../../../../hooks/useHover";
import { useHistory } from "react-router-dom";
import OutsideAlerter from "../../../../components/OutsideAlerter";
import { useSelector } from "react-redux";
import { selectUserRouteFoldersState } from "../../../../redux/userRouteFolders/UserRouteFoldersSlice";

const RouteItem = ({ data, actionDelete, addUserRoute }) => {
  const history = useHistory();

  const user_route_folders = useSelector(selectUserRouteFoldersState);

  const [hoverRef, isHovered] = useHover();
  const [show, setShow] = useState(false);
  const [subDrop, setSubDrop] = useState(false);
  const openSwitch = () => { setShow(false); setSubDrop(false); };
  const openSwitchSubItem = () => setSubDrop(false);

  useEffect(() => {
    if (!isHovered && show) {
      console.log(['Test']);
      setShow(false);
      setSubDrop(false);
    }
  }, [isHovered])



  return (
    <>
      <div
        className="col-md-12"
        ref={hoverRef}
        onClick={(e) => {
          if (Object.keys(e.target.dataset).length === 0) {
            history.replace("/route-planning/results/user/" + data?.route_id);
          }
        }}
      >
        <div className="d-flex flex-row flex-wrap mb-3">
          <div className="col-md-12 card card-graph-bg ">
            <div className="card-body p-3 bg-hover-card">
              <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                <div className="form-check">
                  <input
                    className="form-check-input check-to-compare me-2"
                    type="checkbox"
                    value={1}
                    defaultChecked={false}
                  />
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content={data?.route_name ?? "---"} direction="top">
                    <p className="route-name fw-bold mb-0">
                      {data?.route_name ?? "---"}
                    </p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">Route Name</p>
                </div>
                <div className="d-flex flex-row flex-wrap align-items-start">
                  <div className="icon-route-top h-auto me-2">
                    <FiArrowUpRight />
                  </div>
                  <div className="text-spacing-myRoute">
                    <CustomToolTip content={data?.route_departure_city ?? "---"} direction="top">
                      <p className="route-name fw-bold mb-0">
                        {data?.route_departure_city ?? "---"}
                      </p>
                    </CustomToolTip>
                    <p className="route-desc mb-0">
                      {data?.route_departure_state ?? "---"}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row flex-wrap align-items-start">
                  <div className="icon-route-top h-auto me-2">
                    <FiArrowDownRight />
                  </div>
                  <div className="text-spacing-myRoute">
                    <CustomToolTip content={data?.route_destination_city ?? "---"} direction="top">
                      <p className="route-name fw-bold mb-0">
                        {data?.route_destination_city ?? "---"}
                      </p>
                    </CustomToolTip>
                    <p className="route-desc mb-0">
                      {data?.route_destination_city ?? "---"}
                    </p>
                  </div>
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content={"Xxxxx"} direction="top">
                    <p className="route-name fw-bold mb-0">Xxxxx</p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">FTL</p>
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content={data?.route_operative_time?.secondsToDays() + 'Days' ?? 0} direction="top">
                    <p className="route-name fw-bold mb-0">
                      {data?.route_operative_time?.secondsToDays() ?? 0} Days
                    </p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">On route</p>
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content={data?.route_miles?.toFixed(2) + 'mi' ?? 0} direction="top">
                    <p className="route-name fw-bold mb-0">
                      {data?.route_miles?.toFixed(2) ?? 0} mi
                    </p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">Miles</p>
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content= {'$' + data?.route_avg_income?.toFixed(2) ?? 0} direction="top">
                    <p className="route-name fw-bold mb-0">
                      $ {data?.route_avg_income?.toFixed(2) ?? 0}
                    </p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">Income</p>
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content={'$' + data?.route_avg_profit?.toFixed(2) ?? 0} direction="top">
                    <p className="route-name fw-bold mb-0">
                      $ {data?.route_avg_profit?.toFixed(2) ?? 0}
                    </p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">Profit</p>
                </div>
                <div className="text-spacing-myRoute">
                  <CustomToolTip content={data?.route_co2_emission?.toFixed(2) + 'kgs' ?? 0} direction="top">
                    <p className="route-name fw-bold mb-0">
                      {data?.route_co2_emission?.toFixed(2) ?? 0} kgs
                    </p>
                  </CustomToolTip>
                  <p className="route-desc mb-0">Emissions (co2)</p>
                </div>
                {isHovered ? (
                  <div className="hoverable-option" data-id="no-onclick">
                    <FiMoreVertical
                      onClick={() => setShow(!show)}
                      className="icon-dropRoute"
                      data-id="no-onclick"
                    />
                    {show && (
                      <OutsideAlerter
                        classNameElement={"box-drop"}
                        position={"absolute"}
                        top={"1rem"}
                        left={"52.5rem"}
                        listener={show}
                        trigger={openSwitch}
                        data-id="no-onclick"
                      >
                        <ul>
                          <li onClick={() => setSubDrop(!subDrop)} data-id="no-onclick">Move to</li>
                          <li
                            onClick={(e) => {
                              if (Object.keys(e.target.dataset).length === 0) {
                                history.replace(
                                  "/route-planning/results/user/" +
                                  data?.route_id
                                );
                              }
                            }}

                          >
                            Update route
                          </li>
                          <li onClick={() => actionDelete(data.route_id)} data-id="no-onclick">Remove route</li>
                        </ul>
                        {subDrop && (
                          <OutsideAlerter
                            classNameElement={"box-drop sub-drop frequency"}
                            position={"absolute"}
                            top={"0rem"}
                            left={"-10rem"}
                            listener={subDrop}
                            trigger={openSwitchSubItem}
                            data-id="no-onclick"
                          >
                            <ul>
                              {user_route_folders?.map(item =>
                                <li data-id="no-onclick" onClick={() => addUserRoute(data?.route_id, item?.urf_id,)}>{item?.urf_name}</li>
                              )}
                            </ul>
                          </OutsideAlerter>
                        )}
                      </OutsideAlerter>
                    )}
                  </div>
                ) : (
                  <div className="text-spacing-myRoute">
                    <CustomToolTip content={"-"} direction="top">
                      <p className="route-name fw-bold mb-0 text-end">24 oct</p>
                    </CustomToolTip>
                    <p className="route-desc mb-0 text-end">2022</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RouteItem;
