import React from "react";
import {FiHelpCircle, FiUser,FiMap,FiDollarSign, FiGitCommit} from "react-icons/fi";
import { Search } from "react-feather";
import ef from "../../app/images/ef.png";
import Layout from "../../components/layout/Layout";
import { useHistory } from "react-router-dom";
import { ProtectedRoute } from "../../app/ProtectedRoute";
import CustomToolTip from "../../components/layout/CustomToolTip";

const help = ({ routes }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  const contentCard = [
    {
      title: "efRouting",
      subtitle: "Basic articles that will help you make the most of efRouting",
      icon: <img src={ef} />,
      link: '/help/ef-routing',
    },
    {
      title: "Account, Profile and Network",
      subtitle: "Learn how to manage your account and creative network",
      icon: <FiUser className="icon-help" />,
      link: null,
    },
    {
      title: "Dispatch plans",
      subtitle: "Learn how to manage and book your dispatch plans",
      icon: <FiMap className="icon-help" />,
      link: null,
    },
    {
      title: "Pricing",
      subtitle: "How to create a paid plan...",
      icon: <FiDollarSign className="icon-help" />,
      link: null,
    },
    {
      title: "Lanes",
      subtitle: "How to create a lanes...",
      icon: <FiGitCommit className="icon-help" />,
      link: null,
    },
    {
      title: "efData",
      subtitle: "How to use efData...",
      icon: <img src={ef} />,
      link: null,
    },
    {
      title: "FaQs",
      subtitle: "Frecuently ask",
      icon: <FiHelpCircle className="icon-help" />,
      link: '/help/faqs',
    },
  ];

  return (
    <>
      <ProtectedRoute path={routes?.path} routes={routes?.Routes} exact={true}>
        <Layout>
          <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
            <h1 className="heading-h1 fw-bold fs-2">
              Help center
              <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={"Help center"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
            </h1>
            <p className="subheading-top">Here, you can administrate your routes</p>
            <div className="filterHelpBar pb-1 pt-0 d-flex flex-row">
              <div className="flex-fill">
                <div className="form-group has-search h-100">
                  <Search className="form-control-search mt-2 ms-3" />
                  <input
                    type="text"
                    className="form-control inputSearchHelp h-100 border-0"
                    placeholder="Enter your search term here..."
                  />
                </div>
              </div>
              <div style={{ width: "162px" }}>
                <button className="searchButton w-100">SEARCH</button>
              </div>
            </div>
            <div className="divider-3"></div>
            <div className="d-flex flex-row flex-wrap gap-3 cards-content-help">
              {contentCard.map((item, index) => (
                <div className="card p-3 pt-2" key={index} onClick={() => history.push(item.link)} >
                  <div className="card-body">
                    <div className="d-flex flex-row">
                      <div>{item.icon}</div>
                      <div className="text-start ms-3">
                        <p className="text-ef-white fw-bold mb-2 lh-1">{item.title} </p>
                        <p className="text-sett mb-0">{item.subtitle}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Layout>
      </ProtectedRoute>
      {routes.Routes.map((item, index) => (
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact}
        />
      ))}
    </>
  );
};

export default help;
