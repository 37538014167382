let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestStandardVehicleValues = async (user_token) => {
  let response;
  
  let url = `${API_URL}user/standard-vehicle-value`;

  await fetch(url, {
    headers: {
      Authorization: user_token,
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });

  return response;
};

export default requestStandardVehicleValues;
