import React, { useState } from 'react';
import { Fade } from 'react-bootstrap';
import { FiChevronDown, FiTrendingUp, FiTrendingDown, FiClock } from "react-icons/fi";
import MenuItems from '../../../components/MenuItems';
import OutsideAlerter from '../../../components/OutsideAlerter';
const RouteStatisticCard = ({ data }) => {

    const [open, setOpen] = useState(false);


    const setIcon = () => {
        switch (data.iconType) {
            case 1:
                return <FiTrendingUp className="statistical-icon me-2" />;
            case 2:
                return <FiTrendingDown className="statistical-red-icon me-2" />;
            case 3:
                return <FiClock className="statistical-description me-2" />;
            default:
                return '';
        }
    };

    const openSwitch = () => setOpen(false);

    return (<div className="card card-graph-bg">
        <div className="card-body px-3 ps-4 card-hover">
            <div className="d-flex flex-row flex-wrap mb-0">
                <div className="col-md-12 d-flex flex-row flex-wrap align-items-center justify-content-between mb-1">

                    <span className='text-card-top'>{data.title}</span>
                    <button type="button"
                        aria-controls="example-collapse-text"
                        className="btn-clean text-white"
                        onClick={() => setOpen(!open)}
                    >
                        <FiChevronDown />
                    </button>
                </div>

            </div>
            <h3 className={data.type === 1 ? 'statistical-heading fw-bold text-green mb-1' : (data.type === 2 ? 'statistical-heading fw-bold text-red mb-1' : 'statistical-heading fw-bold mb-1')}>{data.values}</h3>
            <div>
                {setIcon()}
                {data.description.map((item) =>
                    <span className="statistical-description me-1" key={item.value}>
                        <span className={data.type === 2 && typeof item.value === 'string' ? 'red-values' : ''}>{item.value ?? ''}</span> {item.value_description}
                    </span>
                )}

            </div>
        </div>
        <OutsideAlerter classNameElement={"w-100"} position={'absolute'} listener={open} trigger={openSwitch}>
            <Fade in={open} className="position-absolute dropdown-detail" mountOnEnter={true} unmountOnExit={true} appear={true}>
                <div id="example-fade-text" className="card card-graph-bg">
                    <div className="card-body">
                        <div className="d-flex flex-row flex-wrap align-items-start justify-content-between mb-3">
                            <div>
                                <p className="route-name fw-bold mb-0">
                                    {data.detail_info.title}
                                </p>
                                {data.detail_info.type && <p className='route-subname mb-0'>Actual</p>}
                            </div>
                            <MenuItems />
                        </div>
                        {data.detail_info.detail_info_values.map((item, index) =>
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center" key={index}>
                                <p className='route-category-text'>{item.value_description}</p>
                                <p className={data.detail_info.type === 1 ? 'route-category-text text-green' : (data.detail_info.type === 2 ? 'route-category-text text-red' : 'route-category-text')}>${item.value}</p>
                            </div>
                        )}
                    </div>
                </div>
            </Fade>
        </OutsideAlerter>

    </div>);
}

export default RouteStatisticCard;