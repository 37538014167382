import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { selectGuardianState } from "../../redux/auth/GuardianSlice";
import requestSaveNotification from "../../services/notifications/requestSaveNotification";
import { Dropdown } from "react-bootstrap";
const NotificationsModal = (props) => {
  const {
    showNotificationsModal,
    setShowNotificationsModal,
    categoryList,
    itemIndicator,
  } = props;

  const authCheck = useSelector(selectGuardianState);
  const [itemPv, setItemPv] = useState({ id_pv: 67, title:'Limit event over'});
  const { register, handleSubmit, reset } = useForm();

  const handleClose = () => {
    setShowNotificationsModal(false);
    reset();
  };

  const onSubmit = async (data) => {
    const formData = {
      indicators: [
        {
          uid_indicator_id: itemIndicator,
          uid_active: 1,
          uid_conditional: itemPv.id_pv ?? 62,
          uid_value: data.uid_value,
        },
      ],
    };
    try {
      const responseData = await requestSaveNotification(formData, authCheck);
      if (responseData) {
        Swal.fire({
          background: "#121A1F",
          title: "Wait a minute",
          text: "Successfully saved",
          showConfirmButton: false,
          timer: 1000,
          width: "305px",
        });
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error(error);
    }
    handleClose();
  };

  return (
    <>
      <Modal
        show={showNotificationsModal}
        onHide={handleClose}
        centered
        dialogClassName="modal-70w"
        className="modalSave"
      >
        <Modal.Header>
          <Modal.Title>Notify every time</Modal.Title>
          <FiX className="btn-closeModal" onClick={handleClose} />
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-row flex-wrap w-100">
            <div className="col-md-12 mb-3">
              <p className="letterSettings">Category</p>
              <select className="select-notification w-100">
                {categoryList?.map((item, index) => (
                  <option key={index}>{item.indicator_category_name}</option>
                ))}
              </select>
            </div>
            <div className="col-md-12 mb-3">
              <p className="letterSettings">Conditional</p>
              <div className="drop-conditional w-100">
                <Dropdown>
                  <Dropdown.Toggle className="w-100">
                    <p className="text-name-cargo mb-0">{itemPv.title}</p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    {props.conditional_indicators?.map((item, index) => (
                      <Dropdown.Item key={index} className="w-100" onClick={() => setItemPv({id_pv: item?.pv_id, title: item?.pv_name})}>
                        <div className="d-flex flex-row flex-wrap align-items-center">
                          <div className="">
                            <label className="text-name-cargo fw-bold mb-0">
                              {item.pv_name}
                            </label>
                            <p className="text-name-cargo  mb-0">
                              {item.pv_description}
                            </p>
                          </div>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <p className="letterSettings">Value</p>
              <input
                {...register("uid_value")}
                type="text"
                className="form-control formModal w-100"
                placeholder="100"
              />
            </div>
            <div className="col-md-12 mb-3">
              <button type="submit" className="btn btn-success w-100">
                PROGRAM NOTIFICATION
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default NotificationsModal;
