import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedIndicatorsState, updateSelectedIndicators } from '../../../redux/indicators/IndicatorsSlice';
import IndicatorsDetailBar from '../IndicatorsDetailBar';
import MixingIndicator from './MixingIndicator';

const MixingTemplate = () => {
    const selectedIndicators = useSelector(selectSelectedIndicatorsState);

    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [arrSelected, setArrSelected] = useState(selectedIndicators);
    const [objSearch, setObjSearch] = useState({});
 

    const updateState = (e, element) => {
        let obj = {...element, indicator_type: e.target.id};
        // setArrSelected(arrSelected, obj);
        dispatch(updateSelectedIndicators(obj));
        console.log(obj);
    }

    const setOptionSearch = (data) => {
        if (!data) {
            return;
        }
        setObjSearch(data);
    }

    return (
        <>
            <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
                <IndicatorsDetailBar showIndicatorType setSearch={setOptionSearch} />
                <div className="d-flex flex-row flex-wrap w-100 merge-cards-content gap-3 mt-3">
                    {arrSelected.map((i, index) =>
                        <MixingIndicator key={index} item={i} type={i.indicator_type} update_action={updateState} objSearch={objSearch} />
                    )}
                </div>
            </div>
        </>
    );
}

export default MixingTemplate;