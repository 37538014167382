import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    selected_values: [],
    external: 0,
    indicators_arr_list: [],
};
export const IndicatorsSlice = createSlice({
    name: "indicator_list",
    initialState,
    reducers: {
        setSelectedIndicators: (state, action) => {
            state.selected_values = [...state.selected_values, action.payload];
        },

        setIndicatorsList: (state, action) => {
            state.indicators_arr_list = action.payload;
        },

        deleteIndicatorsList: (state, action) => {
            state.indicators_arr_list = [];
        },

        setFromExternalAction: (state, action) => {
            state.external = action.payload;
        },


        updateSelectedIndicators: (state, action) => {
            let arr = state.selected_values;
            let objIndex = arr.findIndex((obj => obj.id === action.payload.id));
            arr[objIndex] = action.payload;
            state.selected_values = arr;
        },

        deleteSelectedIndicators: (state, action) => {
            const filtered = state.selected_values.filter(function (el) { return el.id !== action.payload.id; });
            state.selected_values = filtered;
        },
    },
});

export const {
    setSelectedIndicators,
    deleteSelectedIndicators,
    updateSelectedIndicators, 
    setFromExternalAction,
    setIndicatorsList,
    deleteIndicatorsList
} = IndicatorsSlice.actions;
export const selectSelectedIndicatorsState = (state) => state.indicator_list.selected_values;
export const selectExternalAction = (state) => state.indicator_list.external;
export const selectIndicatorsList = (state) => state.indicator_list.indicators_arr_list;



export default IndicatorsSlice.reducer;
