/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import {
	FiChevronDown,
	FiArrowUpRight,
	FiMoreHorizontal,
	FiArrowDownRight,
	FiPlus,
	FiChevronUp,
} from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import GeneralMap from "../../../components/GeneralMap";
import Alerts from "../../../components/Alerts";
import SmallModal from "../../../components/modals/SmallModal";
import OverlayDetails from "../../../components/modals/OverlayDetails";
import { useParams } from "react-router-dom";
import requestRouteDetails from "../../../services/black_box/requestRouteDetails";
import { useSelector } from "react-redux";
import { selectGuardianState } from "../../../redux/auth/GuardianSlice";
import requestUserRouteDetails from "../../../services/user_routes/requestUserRouteDetails";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";

import requestUserRouteUpdate from "../../../services/user_routes/requestUserRouteUpdate";
import { useParameter } from "../../../hooks/useParameter";
import OperativeRouteAnalysisTable from "./OperativeRouteAnalysisTable";
import FinancialRouteAnalysisTable from "./FinancialRouteAnalysisTable";
import requestSaveUserRoute from "../../../services/user_routes/requestSaveUserRoute";
import requestGetIndicators from "../../../services/indicators/requestGetIndicators";
import requestGetParameterValues from "../../../services/parameters/requestGetParameterValues";
import BenchmarkSection from "./BenchmarkSection";
import getBenchmarkValues from "./getBenchmarkValues";
import StatisticCardsSection from "./StatisticCardsSection";
import requestGetRouteLines from "../../../services/tools/requestGetRouteLines";

const CalculatedRoute = () => {
	const default_details = useSelector((state) => state.route.default_details);
	const route_planning_filter = useSelector(
		(state) => state.route.route_planning_filter
	);

	const [loading, setLoading] = useState(false);
	const [openOne, setOpenOne] = useState(false);
	const [openTwo, setOpenTwo] = useState(false);
	const [openThree, setOpenThree] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [benchmarkValues, setBenchmarkValues] = useState();
	const [routeDetail, setRouteDetail] = useState();
	const [proposedRoute, setProposedRoute] = useState();
	const [waypoints, setWaypoints] = useState([]);
	const [totals, setTotals] = useState();
	const [coordinates, setCoordinates] = useState();
	const [lines, setLines] = useState([]);
	const [tower, setTower] = useState([37.7752701, -122.4312043]);
	const [dataIndicators, setDataIndicators] = useState([]);
	const [dropdownData, setDropdownData] = useState([
		{
			id: "profit",
			title: "Profit per mile:",
			subtitle: "Current route",
			data: { min: 0, avg: 0, max: 0, median: 0 },
			high_n_low: true,
		},
		{
			id: "income",
			title: "Revenue per mile:",
			subtitle: "Current route",
			data: { min: 0, avg: 0, max: 0, median: 0 },
			high_n_low: true,
		},
		{
			id: "miles",
			title: "Emissions per mile:",
			subtitle: "Current route",
			data: { min: 0, avg: 0, max: 0, median: 0 },
		},
		{
			id: "co2_emission",
			title: "Miles per day:",
			subtitle: "Current route",
			data: { min: 0, avg: 0, max: 0, median: 0 },
		},
	]);

	let { by, routeId } = useParams();
	const user_token = useSelector(selectGuardianState);

	let history = useHistory();
	let location = useLocation();

	const load_sizes = useParameter("load_sizes");

	useEffect(() => {
		if (!routeId || load_sizes.length == 0) {
			return;
		}

		const getData = async () => {
			const response =
				by === "user"
					? await requestUserRouteDetails(routeId, user_token)
					: await requestRouteDetails(routeId, user_token);
			if (response.state != 200) {
				return;
			}
			const data = { ...response.data };
			const values = await getBenchmarkValues(user_token);
			setProposedRoute(data);
			setRouteDetail(data);
			setBenchmarkValues(values);
		};

		getData();
	}, [routeId, load_sizes]);

	useEffect(() => {
		let totals = {
			miles: 0,
			fuel_gal: 0,
			driving_time: 0,
			operative_time: 0,
			co2_emission: 0,
			fuel_cost: 0,
			tolls: 0,
			total_variable_cost: 0,
			min_income: 0,
			avg_income: 0,
			max_income: 0,
			min_profit: 0,
			avg_profit: 0,
			max_profit: 0,
		};
		const lanes = routeDetail?.get_lanes ?? [];
		for (let i = 0; i < lanes.length; i++) {
			totals.miles += parseFloat(lanes[i].lane_miles ?? 0);
			totals.fuel_gal += parseFloat(lanes[i].lane_fuel_gal ?? 0);
			totals.driving_time += parseFloat(lanes[i].lane_driving_time ?? 0);
			totals.operative_time += parseFloat(
				lanes[i].lane_operative_time ?? 0
			);
			totals.co2_emission += parseFloat(lanes[i].lane_co2_emission ?? 0);
			totals.fuel_cost += parseFloat(lanes[i].lane_fuel_cost ?? 0);
			totals.tolls += parseFloat(lanes[i].lane_tolls ?? 0);
			totals.total_variable_cost += parseFloat(
				lanes[i].lane_sub_total_variable_cost ?? 0
			);
			totals.min_income += parseFloat(lanes[i].lane_min_income ?? 0);
			totals.avg_income += parseFloat(lanes[i].lane_avg_income ?? 0);
			totals.max_income += parseFloat(lanes[i].lane_max_income ?? 0);
			totals.min_profit += parseFloat(lanes[i].lane_min_profit ?? 0);
			totals.avg_profit += parseFloat(lanes[i].lane_avg_profit ?? 0);
			totals.max_profit += parseFloat(lanes[i].lane_max_profit ?? 0);
		}
		dropdownData[0].data = {
			min: totals.min_profit,
			avg: totals.avg_profit,
			max: totals.max_profit,
			median: totals.avg_profit,
		};
		let dropdown_data = dropdownData;
		dropdown_data[0].data = {
			min: totals.min_profit,
			avg: totals.avg_profit,
			max: totals.max_profit,
			median: totals.avg_profit,
		};
		dropdown_data[1].data = {
			min: totals.min_income,
			avg: totals.avg_income,
			max: totals.max_income,
			median: totals.avg_income,
		};
		dropdown_data[2].data = {
			min: totals.miles,
			avg: totals.miles,
			max: totals.miles,
			median: totals.miles,
		};
		dropdown_data[3].data = {
			min: totals.co2_emission,
			avg: totals.co2_emission,
			max: totals.co2_emission,
			median: totals.co2_emission,
		};
		setDropdownData(dropdown_data);
		setTotals(totals);
		let coordinates = [];
		lanes.length != 0 &&
			coordinates.push(lanes[0]?.lane_departure_city_coordinates);
		lanes.length != 0 &&
			coordinates.push(lanes[0]?.lane_destination_city_coordinates);
		setCoordinates(coordinates);
		setTimeout(() => buildWaypoints(), 500);
	}, [routeDetail]);

	useEffect(() => {
		if (!coordinates) {
			return;
		}
		setLines();
		const getLines = async () => {
			const data = {
				origin: {
					lat: coordinates[0][0],
					lng: coordinates[0][1],
				},
				destination: {
					lat: coordinates[1][0],
					lng: coordinates[1][1],
				},
			};
			const response = await requestGetRouteLines(data, user_token);
			setLines(response.data);
		};
		getLines();
		setTower(coordinates[0]);
	}, [coordinates]);

	const buildWaypoints = () => {
		let points = [];
		const array =
			routeDetail?.get_formatted_route?.get_lanes ??
			routeDetail?.get_lanes ??
			[];

		for (let index = 0; index < array.length; index++) {
			const element = array[index];
			const next_element = array[index + 1];
			console.log("element", element.lane_load_size_name);
			let lane = {
				lane_id: element.lane_id,
				lane_load_size: element.lane_load_size,
				lane_load_size_name: element.lane_load_size_name,
			};
			if (index == 0) {
				const dh_load_size = load_sizes.find(
					(e) => e.pv_name == "DEADHEAD"
				);
				points.push({
					...lane,
					lane_place: `${element.lane_departure_city}, ${element.lane_departure_state}`,
					lane_place_id: element.lane_departure_place_id,
					lane_action: "PICK UP",
					lane_load_size: dh_load_size.pv_id,
					lane_load_size_name: dh_load_size.pv_name,
				});
			}
			element?.lane_destination_actions[0] &&
				points.push({
					...lane,
					lane_place: `${element.lane_destination_city}, ${element.lane_destination_state}`,
					lane_place_id: element.lane_destination_place_id,
					lane_action: element?.lane_destination_actions[0],
				});
			element?.lane_destination_actions[1] &&
				points.push({
					...lane,
					lane_place: `${element.lane_destination_city}, ${element.lane_destination_state}`,
					lane_place_id: element.lane_destination_place_id,
					lane_action: element?.lane_destination_actions[1],
				});
		}

		setWaypoints(points);
	};

	const changeField = (field, index, value) => {
		value = parseFloat(value);
		(field == "lane_driving_time" || field == "lane_operative_time") &&
			(value = value * 3600);
		let data = { ...routeDetail, get_lanes: [...routeDetail?.get_lanes] };
		data.get_lanes[index][field] = value ?? 0;
		data.get_lanes[index]["lane_fuel_cost"] = (
			data.get_lanes[index]["lane_fuel_gal"] *
			(data.get_lanes[index]["lane_fuel_cost_per_gal"] ?? 1)
		)?.toFixed(2);
		data.get_lanes[index]["lane_min_profit"] =
			data.get_lanes[index]["lane_min_income"] -
			data.get_lanes[index]["lane_fuel_cost"] -
			data.get_lanes[index]["lane_sub_total_variable_cost"];
		data.get_lanes[index]["lane_avg_profit"] =
			data.get_lanes[index]["lane_max_profit"] -
			data.get_lanes[index]["lane_fuel_cost"] -
			data.get_lanes[index]["lane_sub_total_variable_cost"];
		data.get_lanes[index]["lane_max_profit"] =
			data.get_lanes[index]["lane_max_income"] -
			data.get_lanes[index]["lane_fuel_cost"] -
			data.get_lanes[index]["lane_sub_total_variable_cost"];
		setRouteDetail(data);
	};

	const saveUserRoute = async (user_route_name) => {
		const route_detail = routeDetail?.get_formatted_route ?? routeDetail;
		const data = {
			...route_detail,
			user_route_name,
			route_miles: totals.miles,
			route_fuel_gal: totals.fuel_gal,
			route_driving_time: totals.driving_time,
			route_operative_time: totals.operative_time,
			route_co2_emission: totals.co2_emission,
			route_fuel_cost: totals.fuel_cost,
			route_total_variable_cost: totals.total_variable_cost,
			route_min_income: totals.min_income,
			route_avg_income: totals.avg_income,
			route_max_income: totals.max_income,
			route_min_profit: totals.min_profit,
			route_avg_profit: totals.avg_profit,
			route_max_profit: totals.max_profit,
		};
		const response =
			by === "user"
				? await requestUserRouteUpdate(data, user_token, routeId)
				: await requestSaveUserRoute(data, user_token);
		console.log(response);
		if (response.state !== 200) {
			return Alerts("Error", response.message);
		}
		setOpenModal(false);
		let { from } = location.state || {
			from: { pathname: "/route-planning" },
		};
		Swal.fire({
			title: "Saved route",
			text: "your route was successfully saved",
			showCancelButton: true,
			showDenyButton: false,
			background: "#121A1F",
			reverseButtons: true,
			cancelButtonText: "STAY ON THE ROUTE",
			confirmButtonText: "NEW SEARCH",
		}).then((result) => {
			if (result.isConfirmed) {
				setTimeout(() => {
					history.replace(from);
				}, 3000);
			}
		});
	};

	const mapIsReadyCallback = (map) => {
		// console.log(map);
	};
	// console.table(dataTable);

	return (
		<>
			<p className="subheading-title">
				{" "}
				<span className="fw-bold">Results</span> - Route{" "}
			</p>
			<div className="row mb-3 gx-2">
				<StatisticCardsSection
					totals={totals}
					benchmarkValues={benchmarkValues}
				/>
			</div>
			<div className="col-12">
				<div className="row mb-3 gx-2">
					<div className="col-md-4">
						<GeneralMap
							mapIsReadyCallback={mapIsReadyCallback}
							coordinates={coordinates}
							lines={lines}
							tower={tower}
							zoom={3}
						/>
					</div>
					<div className="col-md-8 pe-2">
						<div className="card card-graph-bg h-100">
							<div className="card-body p-4">
								<div className="d-flex flex-row flex-wrap justify-content-between">
									<div>
										<p className="card-title-routes fw-bold mb-0">
											The lanes
										</p>
									</div>
									<div className="dropSettings">
										<DropdownButton
											drop={"down"}
											title={
												<FiMoreHorizontal className="icon-route-planning" />
											}
										>
											<Dropdown.Item>
												View operative data
											</Dropdown.Item>
											<Dropdown.Item>
												View financial data
											</Dropdown.Item>
										</DropdownButton>
									</div>
								</div>
								<div className="divider-3"></div>
								<div className="d-flex flex-row flex-wrap gap-3">
									<p className="subheading-top">
										{route_planning_filter?.date ?? "---"}
									</p>
									<p className="subheading-top">
										{(
											routeDetail?.route_driving_time ?? 0
										).secondsToDays()}{" "}
										days en route
									</p>
									<p className="subheading-top">
										{default_details?.rsd_trailer_type_name}
									</p>
								</div>
								<div
									className="table-responsive"
									style={{ height: "120px" }}
								>
									<table className="table table-borderless results-table table-hover">
										<tbody>
											{routeDetail?.get_lanes?.map(
												(item, index) => (
													<tr
														className="my-2"
														key={index}
														onClick={() =>
															setCoordinates([
																item?.lane_departure_city_coordinates,
																item?.lane_destination_city_coordinates,
															])
														}
													>
														<td
															style={{
																verticalAlign:
																	"middle",
															}}
														>
															<p className="route-name fw-bold mb-0">
																{item?.lane_trip_type_name ==
																"deadhead"
																	? "DH"
																	: default_details?.rsd_load_size_name}
															</p>
														</td>
														<td>
															<div className="d-flex flex-row flex-wrap align-items-center">
																<div className="icon-route-planning">
																	<FiArrowUpRight />
																</div>
																<div>
																	<p className="route-name fw-bold mb-0">
																		{item.lane_departure_city ??
																			"---"}
																	</p>
																	<p className="route-desc mb-0">
																		{item.lane_departure_state ??
																			"---"}
																	</p>
																</div>
															</div>
														</td>
														<td>
															<div className="d-flex flex-row flex-wrap align-items-center">
																<div className="icon-route-planning">
																	<FiArrowDownRight />
																</div>
																<div>
																	<p className="route-name fw-bold mb-0">
																		{item.lane_destination_city ??
																			"---"}
																	</p>
																	<p className="route-desc mb-0">
																		{item.lane_destination_state ??
																			"---"}
																	</p>
																</div>
															</div>
														</td>
														<td>
															<div>
																<p className="route-name fw-bold mb-0">
																	$
																	{item.lane_sub_total_variable_cost?.toFixed(
																		2
																	) ?? 0}
																</p>
																<p className="route-desc mb-0">
																	Cost
																</p>
															</div>
														</td>
														<td>
															<div>
																<p className="route-name fw-bold mb-0">
																	$
																	{item.lane_profit?.toFixed(
																		2
																	) ?? 0}
																</p>
																<p className="route-desc mb-0">
																	Profit
																</p>
															</div>
														</td>
														<td>
															<div>
																<p className="route-name fw-bold mb-0">
																	{Math.round(
																		item?.lane_miles ??
																			0
																	)}
																	mi
																</p>
																<p className="route-desc mb-0">
																	Miles
																</p>
															</div>
														</td>
														<td>
															<div>
																<p className="route-name fw-bold mb-0">
																	{item.lane_co2_emission?.toFixed(
																		2
																	) ?? 0}{" "}
																	kgs
																</p>
																<p className="route-desc mb-0">
																	Emissions
																	(co2)
																</p>
															</div>
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
								</div>
								<table className="table table-borderless results-table table-hover mb-0">
									<tbody>
										<tr
											className="my-2 hoverable-td"
											onClick={() =>
												setShowModal(!showModal)
											}
										>
											<td colSpan={6}>
												<div className="d-flex flex-row flex-wrap align-items-center">
													<div className="icon-route-planning">
														<FiPlus />
													</div>
													<div>
														<p className="route-name fw-bold mb-0">
															Custom route
														</p>
														<p className="route-desc mb-0">
															Modify the route
														</p>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<OverlayDetails
									setLoading={setLoading}
									show={showModal}
									setShow={setShowModal}
									calendar={false}
									setRoute={setRouteDetail}
									data={waypoints}
									vehicle={
										default_details?.rsd_trailer_type_name ??
										""
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row flex-wrap mb-3">
					<div className="col-md-12 card card-graph-bg">
						<div className="card-body p-3 pb-1 ps-4 card-hover">
							<div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
								<p className="subheading-title fw-bold">
									Operative route analysis
								</p>
								<button
									type="button"
									aria-controls="one"
									className="btn-clean text-white"
									onClick={() => setOpenOne(!openOne)}
								>
									{!openOne ? (
										<FiChevronDown />
									) : (
										<FiChevronUp />
									)}
								</button>
							</div>
							{loading ? (
								<p>Loading...</p>
							) : (
								<OperativeRouteAnalysisTable
									data={routeDetail}
									defaultData={proposedRoute}
									totals={totals}
									changeField={changeField}
									collapsed={openOne}
									dataIndicators={dataIndicators}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="d-flex flex-row flex-wrap mb-3">
					<div className="col-md-12 card card-graph-bg">
						<div className="card-body p-3 pb-1 ps-4 card-hover">
							<div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
								<p className="subheading-title fw-bold">
									Financial route analysis
								</p>
								<button
									type="button"
									aria-controls="one"
									className="btn-clean text-white"
									onClick={() => setOpenTwo(!openTwo)}
								>
									{!openTwo ? (
										<FiChevronDown />
									) : (
										<FiChevronUp />
									)}
								</button>
							</div>
							<FinancialRouteAnalysisTable
								data={routeDetail}
								defaultData={proposedRoute}
								totals={totals}
								collapsed={openTwo}
							/>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row flex-wrap mb-4">
					<div className="col-md-12 card card-graph-bg">
						<BenchmarkSection
							totals={totals}
							benchmarkValues={benchmarkValues}
							collapsed={openThree}
							setCollapsed={setOpenThree}
							data={dropdownData}
							defaultIncomeTotals={proposedRoute}
							incomeTotals={totals}
						/>
					</div>
				</div>
				<div className="d-flex flex-row flex-wrap justify-content-end mb-4 gap-2">
					<button
						type="button"
						className="btn btn-outline-success btn-route-lg"
					>
						BOOK ROUTE
					</button>
					<button
						type="button"
						className="btn btn-outline-success btn-route-lg"
						onClick={() => setOpenModal(!openModal)}
					>
						SAVE TRIP
					</button>
				</div>
			</div>
			<SmallModal
				showSmallModal={openModal}
				setShowSmallModal={setOpenModal}
				actionModal={saveUserRoute}
				values={routeDetail?.route_name ?? ""}
				title={"Save As"}
				subTitle={"Route name"}
				letterBtn={"SAVE"}
				buttons={true}
			/>
		</>
	);
};

export default CalculatedRoute;
