import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const Payment = () => {
  const history = useHistory();

  return (
    <>
        <div className="mt-3 mb-4">
          <div className="mt-3 flex-system-content">
            <table className="table mt-4 table-sm table-borderless table-payment">
              <thead>
                <tr className="text-thead fw-bold text-center">
                  <th scope="col">Options</th>
                  <th scope="col">Plan</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-tbody text-center">
                  <td>
                    <FiTrash2 className="icon-settings" />
                  </td>
                  <td>FlexPlan</td>
                  <td>5</td>
                  <td>1</td>
                  <td>$500</td>
                </tr>
                <tr className="text-tbody text-center">
                  <td>
                    <FiTrash2 className="icon-settings" />
                  </td>
                  <td>MixPlan</td>
                  <td>5</td>
                  <td>1</td>
                  <td>$1000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-between mt-2 mb-5">
          <div>
            <button type="button" className="btn btn-outline-success btnPayment">
              Clear Shoppin Cart
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-success btnPayment">
              Add Cart
            </button>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-between mb-1">
          <div className="col-6 d-flex flex-row flex-wrap justify-content-center">
            <p className="text-ef-white fw-bold mb-0">Request Note</p>
          </div>
          <div className="col-6 d-flex flex-row flex-wrap justify-content-center">
            <p className="text-ef-white fw-bold mb-0">Cart Totals</p>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap gap-3 cards-content-help mb-2">
          <div className="card p-5 pt-2 pb-2">
            <div className="card-body d-flex flex-row justify-content-between align-items-center">
              <p className="text-ef-white fw-bold mb-0"></p>
              <p className="text-ef-white fw-bold mb-0"></p>
            </div>
          </div>
          <div className="card p-5 pt-2 pb-2">
            <div className="card-body d-flex flex-row justify-content-between align-items-center">
              <p className="text-ef-white fw-bold mb-0">Total</p>
              <p className="text-ef-white fw-bold mb-0">$ 1500</p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-end mt-4 mb-5">
          <div>
            <button type="button" className="btn btn-success btnPayments" onClick={() => history.push('/settings/payment/payment/step_one')}>
              Proceed to Checkout
            </button>
          </div>
        </div>
    </>
  );
};

export default Payment;
