import React, { useState } from 'react';
import NavbarTop from './NavbarTop';
import Sidebar from './Sidebar';
import OffCanvasMenu from '../TabContentOffCanvas/OffCanvasMenu';


const Layout = ({ children, routeList }) => {
    const [collapsed, setCollapsed] = useState(false);
    const activeCollapse = () => setCollapsed(!collapsed);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(!show);

    return (
        <>
            <Sidebar toggle={activeCollapse} active={collapsed} />
            <main className={collapsed ? "main-element ms-sm-auto col-lg-10 full-view" : "main-element ms-sm-auto col-lg-10"}>
                <OffCanvasMenu show={show} handleShow={handleShow}/>
                <NavbarTop />
                {children}
            </main>
        </>
    )
}

export default Layout;