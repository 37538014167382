import React from "react";
import Layout from "../../components/layout/Layout";
import GraphCard from "../../components/GraphCard";
import Advertisement from "./Advertisement";
import { FiAlertCircle, FiHelpCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { selectUserDataState } from "../../redux/user/UserSlice";
import RouteCard from "../../components/RouteCard";
import CustomToolTip from "../../components/layout/CustomToolTip";
import MessageItem from "../Inbox/MessageItem";

const Dashboard = ({routes}) => {
  const userInfo = useSelector(selectUserDataState);

  const dataRouteCard = [
    { title: null, origin: 'Los angeles', origin_destination: 'California' },
    { title: null, origin: 'Los angeles', origin_destination: 'California' },
    { title: null, origin: 'Los angeles', origin_destination: 'California' }
];

  return (
    <Layout routeList={routes}>
      <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-4 pb-2 px-md-4 gap-3 content-main-padding">
        <div>
          <h1 className="heading-h1 fw-bold">Hi, {userInfo?.user_name ?? 'Name'} {userInfo?.user_last_name ?? 'LastName'} 
          <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={"Dashboard"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
          </h1>
          <p className="subheading-top mb-0">Here, you can administrate your routes</p>
        </div>
        <Advertisement />
        <div className="d-flex flex-row flex-wrap">
          <div className="col-md-9 d-flex flex-row flex-wrap mb-2 gap-2">
              {dataRouteCard.map((i, index) =>
                    <div key={index} className="dashboard-items-flex">
                        <RouteCard item={i} />
                    </div>
                )}
            <div className="col-lg-12 d-flex flex-row w-100 flex-gaping card-min-height">
              <div className="col-md-6">
                <div className="card card-info-bg card-height">
                  <div className="card-body px-5 py-4">
                    <div className="content-text">
                      <h2 className="heading-h1">Truck News <FiAlertCircle className="info-icon-advertisement" /> </h2>
                      <p className="route-category-text">your weekly newsletter</p>
                    </div>
                    <div className="translucent"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-height">
                  <div className="card-body card-scroll p-3">
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                    <MessageItem  showCheck={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex flex-column flex-wrap card-route-padding">
              <div className="col-md-12">
                <GraphCard widthCard={'100%'}  btnIcon={true} />
              </div>
              <div className="col-md-12">
                <GraphCard widthCard={'100%'}  btnIcon={true} />
              </div>
              <div className="col-md-12">
                <GraphCard widthCard={'100%'}  btnIcon={true} />
              </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
