import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: [],
};
export const routeFoldersSlice = createSlice({
  name: "route_folders",
  initialState,
  reducers: {
    setRouteFolders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRouteFolders } = routeFoldersSlice.actions;
export const selectRouteFoldersState = (state) => state.route_folders.value;

export default routeFoldersSlice.reducer;
