import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	routes: [],
	default_details: {},
	route_planning_filter: {}
};

export const routeSlice = createSlice({
	name: "route",
	initialState,
	reducers: {
		setDefaultDetails: (state, action) => {
			let data = action.payload;
			state.default_details = data;
		},
		setRoutePlanningFilter: (state, action) => {
			let data = action.payload;
			state.route_planning_filter = data;
		},
	},
});

export const { setDefaultDetails, setRoutePlanningFilter } = routeSlice.actions;
export default routeSlice.reducer;

