/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../../../app/images/90466.svg";
import { useHistory } from "react-router-dom";
import { FiEye, FiHelpCircle } from "react-icons/fi";
import requestForgotPassword from "../../../services/auth/requestForgotPassword";
import Swal from "sweetalert2";
import requestConfirmForgotPassword from "../../../services/auth/requestConfirmForgotPassword";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import CustomToolTip from "../../../components/layout/CustomToolTip";

const RecoveryPassword = () => {
    let history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(0);
    const [email, setEmail] = useState();
    const [resendBtn, setResendBtn] = useState(true);
    const [counter, setCounter] = useState(60);

    useEffect(() => {
        if (!resendBtn && counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
            counter === 1 && setResendBtn(true);
        }
    }, [counter, resendBtn]);

    const forgotPasswordHandler = async (data) => {
        let email = data.email;
        const response = await requestForgotPassword(email);
        if (response.state != 200) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 4000,
                heightAuto: true,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            return Toast.fire({
                icon: "warning",
                title: response.message,
            });
        }
        Swal.fire({
            background: '#121A1F',
            title: 'Wait a minute',
            text: response.message,
            showConfirmButton: false,
            timer: 4000,
            width: '305px',
        });
        setStep(1);
    };

    const updatePasswordHandler = async (data) => {
        const form = {
            user_email: email,
            confirmation_code: data?.confirmation_code,
            password: data?.password
        };
        const response = await requestConfirmForgotPassword(form);
        if (response.state != 200) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                heightAuto: true,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });
            return Toast.fire({
                icon: "warning",
                title: response.message,
            });
        }
        setStep(2);
        setTimeout(() => {
            history.replace('/login');
        }, 4000);
    };

    const resendConfirmationCodeHandler = async () => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 4000,
            heightAuto: true,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });
        const response = await requestForgotPassword(email);
        let message = response.message;
        if (response.state == 200) {
            setResendBtn(false);
            setCounter(60);
            message = 'Code has been resent';
        }
        Toast.fire({
            icon: "warning",
            title: message,
        });

    }

    return (
        <div className="content-login d-flex flex-row flex-wrap">
            <div className="brand-login d-flex flex-column flex-wrap justify-content-center col-lg-5 gap-2 labels-group">
                <Link to={"/login"} >
                    <img src={logo} alt="" className="logo-login" />
                </Link>
                <span className="dialog-login">More than a loadboard</span>
            </div>
            <div className="form-sign-in col-xl-7 col-md-8">
                {
                    step === 0 &&
                    <>
                        <h1 className="h3 mb-3 text-welcome text-center">Recovery password</h1>
                        <p className="orLogin pe-2">Recovery password</p>
                        <form onSubmit={handleSubmit(forgotPasswordHandler)}>
                            <div className="mb-1">
                                <label className="form-label mb-1">Email address *</label>
                                <input
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern:
                                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    })}
                                    type="email"
                                    className={errors.email ? "form-control invalid" : "form-control"}
                                    placeholder="Email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="w-100 btn btn-lg btn-success mb-2 mt-3">
                                CONTINUE
                            </button>
                        </form>
                        <button onClick={() => history.replace("/login")} className="w-100 btn btn-lg btn-secondary mt-2">
                            LOG IN
                        </button>
                        {Object.keys(errors).length > 0 &&
                            Object.entries(errors).map((item) =>
                                <p className="text-danger text-error mb-1" role="alert" key={item[0]}>
                                    {item[1].message}
                                </p>
                            )}
                    </>
                }
                {
                    step === 1 &&
                    <>
                        <h1 className="h3 mb-3 text-welcomeSingUp text-center">Verify your contact</h1>
                        <p className="orLogin pe-2">Confirmation message</p>
                        <p className="form-label mb-1 text-center">Enter the 6 digit code sent to you at {email.hideEmail()}</p>
                        <form onSubmit={handleSubmit(updatePasswordHandler)}>
                            <div className="mb-1">
                                <label className="form-label mb-1">Code *</label>
                                <input
                                    {...register("confirmation_code", { required: 'Code is required' })}
                                    type="number"
                                    className={errors.confirmation_code ? "form-control invalid" : "form-control"}
                                    placeholder="Confirmation code"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Password *</label>
                                <CustomToolTip
                                    classTooltip={'tooltip-content'}
                                    classText={'text-contentTooltip'}
                                    width={'280px'}
                                    height={"200px"}
                                    content={'The password must contain at least numbers, a combination of upper and lower case letters.'}
                                    direction="right" >
                                    <FiHelpCircle className="info-icon-help ms-2" />
                                </CustomToolTip>
                                <div className="input-group mb-3">
                                    <input
                                        {...register("password", {
                                            required: 'Password is required',
                                            minLength: {
                                                value: 6,
                                                message: 'The password must be at least 6 characters long'
                                            },
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?!.*\s).{6,}$/,
                                                message: 'The Password must contain at least one digit, one uppercase letter, one lowercase letter, one special character'
                                            }
                                        })}
                                        type={showPassword ? "text" : "password"}
                                        className={errors.password ? "form-control invalid border-end-0" : "form-control border-end-0"}
                                        placeholder="**********"
                                    />
                                    <span className="input-group-text btn-eye" onClick={() => setShowPassword(!showPassword)}>
                                        <FiEye />
                                    </span>
                                </div>
                            </div>
                            <button type="submit" className="w-100 btn btn-lg btn-success mb-2 mt-2">
                                VERIFY
                            </button>
                        </form>
                        <button type="button" onClick={resendConfirmationCodeHandler} disabled={!resendBtn} className="w-100 btn btn-lg btn-secondary mt-2 mb-3">
                            {resendBtn ? 'Haven’t received a code?' : `you can resend the code on ${counter.secondsToFormattedMinutes()}`}
                        </button>
                        {Object.keys(errors).length > 0 &&
                            Object.entries(errors).map((item) =>
                                <p className="text-danger text-error mb-1" role="alert" key={item[0]}>
                                    {item[1].message}
                                </p>
                            )}
                    </>
                }
                {
                    step === 2 &&
                    <div className="content-login justify-content-center d-flex flex-row flex-wrap align-items-center">
                        <div className="text-center">
                            <h3 className="text-ef-green fw-bolder">Password has been updated</h3>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default RecoveryPassword;
