/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useController, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../../../redux/registerForm/registerFormSlice";
import { FiChevronDown, FiChevronUp, FiHelpCircle } from "react-icons/fi";
import { useParameter } from "../../../hooks/useParameter";
import PlaceAutocomplete from "../../../components/PlaceAutocomplete";
import CustomToolTip from "../../../components//layout/CustomToolTip";

export const CustomSelect = (props) => {
  const { field } = useController(props);

  return (
    <select {...field} className={props.className}>
      <option defaultValue="0">Select trailer type</option>
      {props.trailer_types?.map((e, i) => (
        <option
          key={i}
          defaultValue={e.pv_id}
          selected={props.selected === e.pv_id}
        >
          {e.pv_name}
        </option>
      ))}
    </select>
  );
};

const VariableCost = ({ formStep, nextFormStep }) => {
  const { registerForm } = useSelector((state) => state.registerForm);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [check, setCheck] = useState(registerForm?.check ?? false);
  const [checkTWo, setCheckTwo] = useState(registerForm?.checkTWo ?? false);
  const [total, setTotal] = useState(0);
  const [typicalOrigin, setTypicalOrigin] = useState(
    registerForm?.std_vehicle_values?.std_vehicle_val_typical_origin ?? {}
  );
  const [stdVehValBased, setStdVehValBased] = useState([]);
  const [stdVehValBasedData, setStdVehValBasedData] = useState([]);
  const [instanceKey, setInstanceKey] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
    watch,
    setError,
  } = useForm();

  const load_sizes = useParameter("load_sizes");
  const trailer_types = useParameter("trailer_types");
  const std_veh_val = useParameter("std_veh_val");

  useEffect(() => {
    setValue(
      "std_vehicle_val_load_size",
      registerForm?.std_vehicle_values?.std_vehicle_val_load_size
    );
    setValue(
      "std_vehicle_val_trailer_type",
      registerForm?.std_vehicle_values?.std_vehicle_val_trailer_type
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load_sizes, trailer_types]);

  const section_one = [
    "std_vehicle_val_load_size",
    "std_vehicle_val_trailer_type",
    "std_vehicle_val_fuel_pyear",
    "std_vehicle_val_maintenance",
    "std_vehicle_val_equipment",
    "std_vehicle_val_tire",
    "std_vehicle_val_permits",
    "std_vehicle_val_insurance",
  ];

  const section_two = [
    "std_vehicle_val_annual_wages",
    "std_vehicle_val_annual_benefits",
  ];

  useEffect(() => {
    let data = {};
    // eslint-disable-next-line array-callback-return
    std_veh_val.map((element) => {
      let value = element.pv_data?.value ?? "";
      data[element.pv_name] = value;
    });
    setStdVehValBasedData(data);
    setTotal(registerForm?.total ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [std_veh_val]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => totalHandler(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const totalHandler = (value) => {
    if (value == "std_vehicle_val_typical_origin") {
      return;
    }
    let total = 0;
    let divisor = value.std_vehicle_val_avg_miles_driven_pyear;
    if (!divisor) {
      return setTotal("0");
    }
    delete value.std_vehicle_val_avg_miles_driven_pyear;
    delete value.std_vehicle_val_typical_origin;
    delete value.std_vehicle_val_load_size;
    delete value.std_vehicle_val_trailer_type;
    let keys = Object.keys(value);
    keys.map(
      (element) =>
        value[element] &&
        value[element] != "" &&
        value[element] != "0" &&
        (total += value[element] / divisor)
    );

    setTotal(total.toFixed(2));
  };
  const handleReset = () => setInstanceKey((i) => i + 1);

  const loadStdVehValBased = async ({ checked = false, fields = [] }) => {
    let data = checked
      ? fields.filter((x) => !stdVehValBased.includes(x))
      : stdVehValBased.filter((x) => !fields.includes(x));
    checked && (data = [...stdVehValBased, ...data]);
    setStdVehValBased(data);
    checked
      ? data.map((element) => setValue(element, stdVehValBasedData[element]))
      : fields.map((element) => resetField(element));
    handleReset();
  };

  const handleSubmitter = async (data) => {
    if (Object.keys(typicalOrigin).length === 0) {
      return setError(
        "std_vehicle_val_typical_origin",
        { type: "focus", message: "It is required to select a city" },
        { shouldFocus: true }
      );
    }

    let std_vehicle_values = {
      ...data,
      std_vehicle_val_typical_origin: typicalOrigin,
      std_vehicle_val_total: total,
      std_vehicle_val_name: "Variable cost register",
    };
    let formData = { ...registerForm };
    formData.std_vehicle_values = std_vehicle_values;
    formData.check = check;
    formData.checkTWo = checkTWo;
    formData.total = total;
    dispatch(setForm(formData));
    nextFormStep();
  };

  const onError = (errors) => {
    setOpen(true);
    setOpenTwo(true);
  };

  return (
    <div className={formStep === 2 ? "d-block" : "d-none"}>
      <h1 className="h3 mb-3 text-welcomeSingUp text-center">
        Let’s estimate your variable cost
        <button className="btn-clean">
          <CustomToolTip
            classTooltip={"tooltip-content"}
            classText={"text-contentTooltip"}
            width={"562px"}
            icon={<FiHelpCircle className="text-tooltip me-2" />}
            title={"Variable cost"}
            content={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi. Proin fermentum ipsum pellentesque sodales vulputate. Maecenas vel bibendum enim. Nulla auctor tortor a dolor auctor, ut condimentum est congue. "
            }
            direction="bottom"
          >
            <FiHelpCircle className="info-icon-help mb-1" />
          </CustomToolTip>
        </button>
      </h1>
      <button
        type="button"
        onClick={() => {
          setValue("std_vehicle_val_avg_miles_driven_pyear", 100000);
          setOpen(true);
          setOpenTwo(true);
          setCheck(true);
          loadStdVehValBased({
            checked: true,
            fields: section_one,
          });
          setCheckTwo(true);
          loadStdVehValBased({
            checked: true,
            fields: section_two,
          });
        }}
        className="w-100 btn btn-lg btn-secondary mb-3"
      >
        USE STANDARD VALUES
      </button>
      <p className="orLogin pe-2">Customize your variable cost</p>
      <form
        className="d-flex flex-row flex-wrap form-singUp"
        onSubmit={handleSubmit(handleSubmitter, onError)}
      >
        <div className="col-md-6">
          <label className="form-label">Average miles driven per year</label>
          <input
            {...register("std_vehicle_val_avg_miles_driven_pyear", {
              required: "Average miles is required",
            })}
            type="number"
            className={
              errors.std_vehicle_val_avg_miles_driven_pyear
                ? "form-control invalid mb-2"
                : "form-control mb-4"
            }
            defaultValue={
              registerForm?.std_vehicle_values
                ?.std_vehicle_val_avg_miles_driven_pyear
            }
          />
          {errors.std_vehicle_val_avg_miles_driven_pyear ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.std_vehicle_val_avg_miles_driven_pyear.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Typical origin *</label>
          <PlaceAutocomplete
            extra={{
              ...register("std_vehicle_val_typical_origin", {
                required: "Typical origin is required",
              }),
            }}
            className={
              errors.std_vehicle_val_typical_origin
                ? "form-control invalid mb-2"
                : "form-control mb-4"
            }
            setValue={setTypicalOrigin}
            defaultValue={
              registerForm?.std_vehicle_values?.std_vehicle_val_typical_origin
                ?.name_formatted
            }
          />
          {errors.std_vehicle_val_typical_origin ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.std_vehicle_val_typical_origin.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12">
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2 collapse-border-bottom">
            <div className="d-flex flex-row flex-wrap align-items-center">
              <button
                type="button"
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                className="btn-clean text-white"
              >
                {!open ? <FiChevronDown /> : <FiChevronUp />}
              </button>
              <p className="collapse-title">Vehicle based costs</p>
            </div>
            <div>
              <div className="form-check form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={check}
                  onChange={(event) => {
                    setCheck(event.target.checked);
                    loadStdVehValBased({
                      checked: event.target.checked,
                      fields: section_one,
                    });
                    setOpen(true);
                  }}
                />
                <label className="form-check-label collapse-label">
                  Standard values
                </label>
              </div>
            </div>
          </div>
          <Collapse in={open}>
            <div id="collapse-text-1">
              <div className="d-flex flex-row flex-wrap justify-content-between">
                <div className="col-md-6 pe-2">
                  <label className="form-label">Load size</label>
                  <select
                    {...register("std_vehicle_val_load_size", {
                      required: "Load size is required",
                      validate: (value) => value != 0,
                    })}
                    className={
                      errors.std_vehicle_val_load_size
                        ? "form-select invalid mb-2"
                        : "form-select mb-4"
                    }
                  >
                    <option disabled value="0">
                      Select load size
                    </option>
                    {load_sizes?.map((e, i) =>
                      e.pv_name === "DEADHEAD" ? (
                        ""
                      ) : (
                        <option
                          key={i}
                          value={e.pv_id}
                          selected={
                            registerForm?.std_vehicle_values
                              ?.std_vehicle_val_load_size == e.pv_id
                          }
                        >
                          {e.pv_name}
                        </option>
                      )
                    )}
                  </select>
                  {errors.std_vehicle_val_load_size ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_load_size.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 ps-1">
                  <label className="form-label">Trailer type</label>
                  <select
                    {...register("std_vehicle_val_trailer_type", {
                      required: "Trailer type is required",
                      validate: (value) => value !== 0,
                    })}
                    className={
                      errors.std_vehicle_val_trailer_type
                        ? "form-select invalid mb-2"
                        : "form-select mb-4"
                    }
                  >
                    <option disabled value="0">
                      Select trailer type
                    </option>
                    {trailer_types?.map((e, i) => (
                      <option
                        key={i + "_" + instanceKey}
                        value={e.pv_id}
                        selected={
                          registerForm?.std_vehicle_values
                            ?.std_vehicle_val_trailer_type === e.pv_id
                        }
                      >
                        {e.pv_name}
                      </option>
                    ))}
                  </select>
                  {errors.std_vehicle_val_trailer_type ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_trailer_type.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap form-singUp">
                <div className="col-md-4">
                  <label className="form-label">Fuel per year</label>
                  <div
                    className={
                      errors.std_vehicle_val_fuel_pyear
                        ? "input-group invalid mb-2 mb-2"
                        : "input-group mb-4mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_fuel_pyear", {
                        required: "Fuel per year is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_fuel_pyear
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_fuel_pyear
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_fuel_pyear ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_fuel_pyear.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Maintenance</label>
                  <div
                    className={
                      errors.std_vehicle_val_maintenance
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_maintenance", {
                        required: "Maintenance is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_maintenance
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_maintenance
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_maintenance ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_maintenance.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label me-2">Equipment</label>
                  <CustomToolTip
                    classTooltip={"tooltip-content"}
                    classText={"text-contentTooltip"}
                    width={"421px"}
                    icon={<FiHelpCircle className="text-tooltip me-2" />}
                    title={"Equipment"}
                    content={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi. "
                    }
                    direction="bottom"
                  >
                    <FiHelpCircle className="info-icon-help mb-1" />
                  </CustomToolTip>
                  <div
                    className={
                      errors.std_vehicle_val_equipment
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_equipment", {
                        required: "Equipment is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_equipment
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_equipment
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_equipment ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_equipment.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap form-singUp">
                <div className="col-md-4">
                  <label className="form-label">Tires</label>
                  <div
                    className={
                      errors.std_vehicle_val_tire
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_tire", {
                        required: "Tires is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_tire
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values?.std_vehicle_val_tire
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_tire ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_tire.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Permits</label>
                  <div
                    className={
                      errors.std_vehicle_val_permits
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_permits", {
                        required: "Permits is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_permits
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_permits
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_permits ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_permits.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Insurance</label>
                  <div
                    className={
                      errors.std_vehicle_val_insurance
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_insurance", {
                        required: "Insurance is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_insurance
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_insurance
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_insurance ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_insurance.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>

        <div className="col-md-12">
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2 collapse-border-bottom">
            <div className="d-flex flex-row flex-wrap align-items-center">
              <button
                type="button"
                onClick={() => setOpenTwo(!openTwo)}
                aria-expanded={openTwo}
                className="btn-clean text-white"
              >
                {!openTwo ? <FiChevronDown /> : <FiChevronUp />}
              </button>
              <p className="collapse-title">Driver based costs</p>
            </div>
            <div>
              <div className="form-check form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checkTWo}
                  onChange={(event) => {
                    setCheckTwo(event.target.checked);
                    loadStdVehValBased({
                      checked: event.target.checked,
                      fields: section_two,
                    });
                    setOpenTwo(true);
                  }}
                />
                <label className="form-check-label collapse-label">
                  Standard values
                </label>
              </div>
            </div>
          </div>
          <Collapse in={openTwo}>
            <div id="collapse-text-2">
              <div className="d-flex flex-row flex-wrap form-singUp">
                <div className="col-md-6">
                  <label className="form-label">Annual wages</label>
                  <div
                    className={
                      errors.std_vehicle_val_annual_wages
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_annual_wages", {
                        required: "Annual wages is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_annual_wages
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_annual_wages
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_annual_wages ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_annual_wages.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6">
                  <label className="form-label">Annual benefits</label>
                  <div
                    className={
                      errors.std_vehicle_val_annual_wages
                        ? "input-group invalid mb-2"
                        : "input-group mb-4"
                    }
                  >
                    <span className="input-group-text groupText">$</span>
                    <input
                      {...register("std_vehicle_val_annual_benefits", {
                        required: "Annual benefits is required",
                      })}
                      type="number"
                      step={"0.01"}
                      className={
                        errors.std_vehicle_val_annual_benefits
                          ? "form-control invalidPass"
                          : "form-control groupInput"
                      }
                      defaultValue={
                        registerForm?.std_vehicle_values
                          ?.std_vehicle_val_annual_benefits
                      }
                      placeholder={"0"}
                    />
                  </div>
                  {errors.std_vehicle_val_annual_benefits ? (
                    <p className="text-danger text-error mb-1" role="alert">
                      {errors.std_vehicle_val_annual_benefits.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <p className="orLogin pe-2 w-100">This is your total variable cost</p>
        <div className="mb-3 col-md-12">
          <p className="total-field">$ {total ?? 0}</p>
        </div>
        <button type="submit" className="w-100 btn btn-lg btn-success mb-3">
          CONTINUE
        </button>
      </form>
    </div>
  );
};

export default VariableCost;
