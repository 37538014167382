import React, { useState } from 'react';
import { FiMoreHorizontal } from "react-icons/fi";
import OutsideAlerter from './OutsideAlerter';


const MenuItems = () => {
    const [show, setShow] = useState(false);
    const [showChild, setShowChild] = useState(false);
    const openSwitchSubItem = () => setShowChild(false);
    const openSwitch = () => { setShow(false); setShowChild(false); };

    return (
        <div>
            <button className='btn-clean p-1' onClick={() => setShow(!show)}>
                <FiMoreHorizontal />
            </button>
            {show &&
                <OutsideAlerter classNameElement={"box-drop"} position={'absolute'} listener={show} trigger={openSwitch}>
                    <ul>
                        <li>Show by days</li>
                        <li onClick={() => setShowChild(!showChild)}>
                            Show by lanes
                        </li>
                    </ul>
                    {showChild &&
                        <OutsideAlerter classNameElement={"box-drop sub-drop"} position={'absolute'} 
                            listener={showChild} trigger={openSwitchSubItem}>
                            <ul>
                                <li>Actual</li>
                                <li>7 days</li>
                                <li>15 days</li>
                                <li>30 days</li>
                            </ul>
                        </OutsideAlerter>
                    }
                </OutsideAlerter>
            }
        </div>);
}

export default MenuItems;