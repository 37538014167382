const awsconfig = {
	aws_project_region: "us-east-1",
	// aws_cognito_identity_pool_id:
	// "us-east-1:60e516be-506a-429b-b6ac-f80f575f119d",
	aws_cognito_region: "us-east-1",
	aws_user_pools_id: "us-east-1_6FMhyPTPN",
	aws_user_pools_web_client_id: "1sfl9eqkkmu5jlce4r87n0o6cf",
	oauth: {
		domain: "routing.auth.us-east-1.amazoncognito.com",
		scope: [
			"email",
			"openid",
			"profile",
		],
		redirectSignIn: `${window.location.origin}/`,
		redirectSignOut: `${window.location.origin}/`,
		responseType: "token",
	},
};

export default awsconfig;
