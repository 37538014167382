import React from "react";
import { FiCornerUpLeft } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import FullLayout from "../../components/layout/FullLayout";
import imgGuide from "../../app/images/guide-efdata.png";

const GuideEfData = () => {
  const history = useHistory();
  let { url } = useRouteMatch();

  const goBack = () => {
    if (url) {
      if (url !== "/dashboard") {
        history.goBack();
      }
    }
    return;
  };

  return (
    <FullLayout>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
        <div className="col-md-5 mt-5">
          <div className="d-flex flex-row flex-wrap">
            <FiCornerUpLeft
              onClick={() => goBack()}
              className="go-back-arrow me-3"
            />
            <h1 className="heading-h1 fw-bold text-capitalize">efRouting</h1>
          </div>
          <div className="d-flex flex-row flex-wrap">
            <p className="subheading-top mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              efficitur vulputate maximus. Fusce rutrum fringilla risus, vel
              sodales augue convallis et. Phasellus porta mollis pharetra.
            </p>
            <div className="divider-3 mb-0"></div>
            <div className="col-md-12 d-flex flex-row flex-wrap mt-3">
              <div className="col-md-12">
                <img src={imgGuide} alt="" className="w-100" />
              </div>
              <div className="col-md-12 mt-3">
                <p className="subheading-top mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  a facilisis tortor. Integer posuere eu quam ut rutrum. Nulla
                  rutrum dolor ac velit tempus, eu semper nisl pulvinar. Donec
                  facilisis rhoncus risus non aliquam. Donec diam leo, mattis
                  sit amet leo viverra, pharetra blandit dolor. Vestibulum nec
                  sollicitudin massa. Etiam non dapibus purus, quis lacinia mi.
                </p>
                <p className="subheading-top mb-2">
                  Quisque tristique nisi quam, non laoreet sem ultrices sed. Sed
                  malesuada sapien id quam finibus cursus. Nullam lorem ligula,
                  euismod quis rutrum euismod, lobortis quis risus. Praesent
                  lectus arcu, sagittis id malesuada vitae, efficitur a dui.
                  Donec vitae metus accumsan, efficitur diam at, vestibulum
                  lacus. Morbi cursus odio id imperdiet posuere. Fusce ante
                  nibh, pretium non ullamcorper nec, pharetra sit amet enim.
                  Nunc sit amet viverra lorem. Praesent vitae tempus ipsum.
                  Nullam condimentum non purus eu posuere. Duis feugiat
                  elementum dui vitae blandit. Donec convallis posuere sapien.
                  Etiam vel libero ac est ultrices eleifend non pretium nisl.
                </p>
                <p className="subheading-top mb-2">
                  Maecenas at augue sodales risus lacinia efficitur. Phasellus
                  ut arcu dignissim, mattis nibh in, posuere mauris. Nulla
                  facilisi. Vivamus quis ipsum eros. Phasellus erat ante,
                  elementum id placerat quis, lacinia quis sapien. In facilisis,
                  nunc ac efficitur sagittis, nisi lectus vehicula dui, vitae
                  tempus nulla mauris ut magna. Nullam at ligula at lectus
                  ultrices vehicula. Integer vehicula, lacus et malesuada
                  eleifend, turpis mi eleifend nibh, a feugiat felis mi id leo.
                  Duis in arcu diam. Ut at rutrum est, nec dapibus urna.
                  Suspendisse sit amet dui porta libero consequat dapibus.
                  Vestibulum ut dui diam. Sed sit amet tristique justo. In
                  auctor pellentesque quam quis ullamcorper. Vivamus euismod,
                  massa eleifend varius pretium, dui tortor sodales enim,
                  ultrices consequat neque libero rhoncus lectus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default GuideEfData;
