import React from 'react'
const CustomButton = ({ type, className, title, icon, onClick, btnDisabled }) => {
    return (
        <button type={type} className={className} onClick={onClick} disabled={btnDisabled}>{title} {icon && icon}</button>
    );
}

CustomButton.defaultProps = {
    type: 'button',
    className: 'btn btn-default',
    title: 'Btn Name',
    btnDisabled: false,
}

export default CustomButton;