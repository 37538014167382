import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomRoute from '../../components/CustomRoute';
import RouteCard from '../../components/RouteCard';
import { selectRouteFoldersState } from '../../redux/routeFolders/routeFoldersSlice';
import SearchResultsSetting from './SearchResultsSetting';
import SettingsBar from './SettingsBar';

const RecommendedRoutes = ({ routes }) => {
    const [arrCard, setArrCard] = useState([])
    const dataRouteCard = [
        { title: null, origin: 'Los angeles', origin_destination: 'California' },
        { title: null, origin: 'Los angeles', origin_destination: 'California' },
        { title: null, origin: 'Los angeles', origin_destination: 'California' }
    ];

    const dataRouteCardSave = [
        { title: 'Default name', origin: 'Los angeles', origin_destination: 'California' },
        { title: 'Default name', origin: 'Los angeles', origin_destination: 'California' },
        { title: 'Default name', origin: 'Los angeles', origin_destination: 'California' },
        { title: 'Default name', origin: 'Los angeles', origin_destination: 'California' }
    ];
    const dataRecentRouteCard = [
        { title: null, origin: 'Los angeles', origin_destination: 'California' },
        { title: null, origin: 'Los angeles', origin_destination: 'California' },
        { title: null, origin: 'Los angeles', origin_destination: 'California' },
        { title: null, origin: 'Los angeles', origin_destination: 'California' }
    ];
    let { id } = useParams();
    id = parseInt(id);
    const route_folders = useSelector(selectRouteFoldersState);
    useEffect(() => {
        if (route_folders) {
            let filterArr = route_folders.filter(item => item.route_folder_id === id);
            setArrCard(filterArr);
        }
    }, [route_folders, id]);
    
    // console.log(arrCard);
    return (<>
        <SettingsBar />
        <div className="row">
        {!id ?
            <div>
                <div className="row">
                    <div className="col-md-3 mb-2">
                        <CustomRoute />
                    </div>
                    {dataRouteCard.map((i, index) =>
                        <div className="col-md-3 mb-2" key={index}>
                            <RouteCard item={i} folderList={route_folders} />
                        </div>
                    )}
                </div>
            </div>
            :
            (id === 'recent' ?
                dataRecentRouteCard.map((i, index) =>
                    <div className="col-md-3 mb-2" key={index}>
                        <RouteCard item={i} folderList={route_folders} />
                    </div>
                )
                :''
                // filterArr.map((i, index) =>
                //     <div className="col-md-3 mb-2" key={index}>
                //         <RouteCard item={i} folderList={route_folders} />
                //     </div>
                // )
            )
        }

        </div>
    </>);
}

export default RecommendedRoutes;