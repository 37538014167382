import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FiX } from "react-icons/fi";
import MultiRangeSlider from '../MultiRangeSlider';

const FilterResults = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { showFilter = false, setShowFilter = false, setFilterData = false, filterData = {}, action = false } = props;

    const [data, setData] = useState({});
    const handleClose = () => setShowFilter(false);

    const filterDataHandler = ({ key, value }) => {
        let filterData = data;
        filterData[key] = value;
        setData(filterData);
    }

    return (
        <>
            <Modal show={showFilter} onHide={handleClose}
                centered
                dialogClassName="modal-80w"
                className='modalDetails'
            >
                <Modal.Header>
                    <Modal.Title>Filter</Modal.Title>
                    <FiX className='btn-closeModal' onClick={handleClose} />
                </Modal.Header>
                <div className='d-flex flex-column w-100'>
                    <MultiRangeSlider
                        min={1}
                        max={21}
                        tittle={'Details'}
                        letter={'Days'}
                        letterr={false}
                        onChange={({ min, max }) => filterDataHandler({ key: 'route_days', value: { min, max } })}
                    />
                    <MultiRangeSlider
                        min={0}
                        max={40000}
                        tittle={'Income'}
                        letterr={'$'}
                        letter={false}
                        onChange={({ min, max }) => filterDataHandler({ key: 'route_avg_income', value: { min, max } })}
                    />
                    <MultiRangeSlider
                        min={0}
                        max={20000}
                        tittle={'Profit efficiency'}
                        letterr={'$'}
                        letter={false}
                        onChange={({ min, max }) => filterDataHandler({ key: 'route_avg_profit', value: { min, max } })}
                    />
                    <MultiRangeSlider
                        min={0}
                        max={15000}
                        tittle={'Mileage'}
                        letter={'mi'}
                        letterr={false}
                        onChange={({ min, max }) => filterDataHandler({ key: 'route_miles', value: { min, max } })}
                    />
                    <MultiRangeSlider
                        min={0}
                        max={25000}
                        tittle={'Emissions'}
                        letter={'kgs (co2e)'}
                        letterr={false}
                        onChange={({ min, max }) => filterDataHandler({ key: 'route_co2_emission', value: { min, max } })}
                    />
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <button className='btn-modal w-100' onClick={() => {  setShowFilter(false); setFilterData(data); action() }}>SHOW RESULTS</button>
                </div>
            </Modal>
        </>
    )
}

export default FilterResults