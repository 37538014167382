import React, { useState } from "react";

const HoverableSaveButton = (props) => {
	const { data, placeholder = "", onChange = false, onSave = false } = props;

	const [isShown, setIsShown] = useState(false);

	return (
		<div
			className="col-md-12 dropSave"
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			{/* <span className='letterDetails'>{data?.rsd_name}</span> */}
			<input
				type="text"
				placeholder={placeholder}
				onChange={onChange}
				className="form-control-plaintext"
				defaultValue={data?.rsd_name}
			/>
			{isShown && (
				<button className="btnHoverSave" onClick={onSave}>
					SAVE
				</button>
			)}
		</div>
	);
};

export default HoverableSaveButton;
