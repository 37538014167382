import React from 'react'

const Preferences = () => {
    return (
        <div className='mt-3 mb-5'>
            <div className='mt-3 mb-5 flex-system-content'>
                <div className="d-flex flex-row">
                    <div className="initial-element">Lightness</div>
                    <div className="p-2 middle-element d-flex flex-row">
                        <div className="form-check form-switch me-2">
                            <input className="inputNavbar form-check-input" type="checkbox" role="switch" />
                        </div>
                        <div>
                            <p className='fs-6 middle-element middle-element-text fw-bold mb-0 p-0'>Lightness</p>
                            <p className='fs-6 middle-element middle-element-text mb-0 p-0'>Lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="initial-element">Assistant</div>
                    <div className="p-2 middle-element d-flex flex-row">
                        <div className="form-check form-switch me-2">
                            <input className="inputNavbar form-check-input" type="checkbox" role="switch" defaultChecked/>
                        </div>
                        <div>
                            <p className='fs-6 middle-element middle-element-text fw-bold mb-0 p-0'>Assistant mode</p>
                            <p className='fs-6 middle-element middle-element-text mb-0 p-0'>Lorem ipsum dolor sit amet.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preferences