import { createSlice } from "@reduxjs/toolkit";

export const registerFormSlice = createSlice({
  name: "registerForm",
  initialState: {},
  reducers: {
    setForm: (state, action) => {
      let data = action.payload;
      state.registerForm = data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setForm } = registerFormSlice.actions;
export default registerFormSlice.reducer;
