import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

let publicKey = process.env.REACT_APP_VAPID_KEY;
const firebaseConfig = {
  apiKey: "AIzaSyAnvYdk5PXjhwx0zOUzlBnI2kk5bMTM2Ms",
  authDomain: "efrouting-373913.firebaseapp.com",
  projectId: "efrouting-373913",
  storageBucket: "efrouting-373913.appspot.com",
  messagingSenderId: "974884437121",
  appId: "1:974884437121:web:7f8a8ba0834f1f5d832180",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const messaging = getMessaging();


// export const onSendNotifications = (clientInstance) => {
//   if (firebase && messaging) {
//     Notification.requestPermission().then(() => {
//       // getting FCM token
//       messaging.getToken().then((fcmToken) => {
//       // passing FCM token to the `conversationClientInstance` to register for push notifications
//         clientInstance.setPushRegistrationId('fcm', fcmToken);

//         // registering event listener on new message from firebase to pass it to the Conversations SDK for parsing
//         firebase.messaging().onMessage(payload => {
//             clientInstance.handlePushNotification(payload);
//         });
//         console.log(fcmToken, 'fcmToken');
//       }).catch((err) => {
//         // can't get token
//       });
//     }).catch((err) => {
//       // can't request permission or permission hasn't been granted to the web app by the user
//     });
//   } else {
//     // no Firebase library imported or Firebase library wasn't correctly initialized
//   }
// }


// export const subscribeFcmNotifications = async convoClient => {
//   const permission = await Notification.requestPermission()
//   if (permission !== "granted") {
//     console.log("FcmNotifications: can't request permission:", permission)
//     return
//   }

//   const fcmToken = await getToken(messaging)
//   if (!fcmToken) {
//     console.log("FcmNotifications: can't get fcm token")
//     return
//   }

//   console.log("FcmNotifications: got fcm token", fcmToken)
//   await convoClient.setPushRegistrationId("fcm", fcmToken)
//   onMessage(messaging, payload => {
//     console.log("FcmNotifications: push received", payload)
//     if (convoClient) {
//       convoClient.handlePushNotification(payload)
//     }
//   })
// }

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      if (currentToken) {
        return true;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log("Message received. ", payload);
    });
  });

export const onMessageSender = (message) => {
  // Send a message to devices subscribed to the provided topic.
  getMessaging()
    .send(message)
    .then((response) => {
      // Response is a message ID string.
      console.log("Successfully sent message:", response);
    })
    .catch((error) => {
      console.log("Error sending message:", error);
    });
};
