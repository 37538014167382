import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

const ModalVerify = (props) => {
    const {
        showModalVerify,
        setShowModalVerify,
        message
    } = props;

    const handleClose = () => setShowModalVerify(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const verifyContact = async (data) => {
        // eslint-disable-next-line no-unused-vars
        const form = {
            confirmation_code: data?.confirmation_code,
        };
    }

    return (
        <>
            <Modal
                show={showModalVerify}
                onHide={handleClose}
                centered
                dialogClassName="modal-85w"
                className="modalVerify"
            >
                <Modal.Header>
                    <Modal.Title>Verify your contact</Modal.Title>
                </Modal.Header>

                <div className="d-flex flex-row flex-wrap w-100">
                    <div className="col-md-12">
                        <p className="orLogin pe-2">Confirmation message</p>
                        <p className="form-label mb-1 text-center">{message}</p>
                    </div>
                </div>
                <div className="col-md-12">
                    <form onSubmit={handleSubmit(verifyContact)}>
                        <input
                            {...register("confirmation_code", { required: 'Code is required' })}
                            type="number"
                            className={errors.confirmation_code ? "form-control invalid" : "form-control"}
                            placeholder="Confirmation code"
                        />
                        <button type="submit" className="w-100 btn btn-lg btn-success mt-4">
                            VERIFY
                        </button>
                    </form>
                </div>
                <div className='col-md-12'>
                    <button type="button" className="w-100 btn btn-lg btn-secondary">
                        Haven’t received a code?
                    </button>
                </div>
                {Object.keys(errors).length > 0 &&
                    Object.entries(errors).map((item) =>
                        <p className="text-danger text-error mb-1 w-100 text-center" role="alert" key={item[0]}>
                            {item[1].message}
                        </p>
                    )}
            </Modal>
        </>
    )
}

export default ModalVerify