/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { useController, useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signedIn } from "../../redux/auth/GuardianSlice";
import { setToken } from "../../redux/auth/CognitoSlice";
import Swal from "sweetalert2";
import logo from "../../app/images/90466.svg";
import logo_google from "../../app/images/XMLID_28_.png";
import requestSignIn from "../../services/auth/requestSignIn";
import { FiEye } from "react-icons/fi";
import requestInfoUser from "../../services/user/requestInfoUser";
import { setUserData } from "../../redux/user/UserSlice";
import requestResendConfirmationCode from "../../services/auth/requestResendConfirmationCode";
import { useCookies } from "../../hooks/useCookies";
import requestGetToken from "../../services/inbox/requestGetToken";
import { setTwilioToken } from "../../redux/inbox/InboxSlice";
import auth, { getAuthenticatedUser } from "../../auth";
import CustomSplash from "../../components/layout/CustomSplash";

auth.configure({
  oauth: {
    domain: "routing.auth.us-east-1.amazoncognito.com",
    scope: ["openid", "email", "profile"],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "token",
  },
});

export const Input = (props) => {
  const { field } = useController(props);

  return (
    <div>
      <input {...field} placeholder={props.name} className={props.className} />
    </div>
  );
};

const Login = () => {
  const mounted = useRef(false);

  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [showPass, setShowPass] = useState(false);
  const [verifyAccount, setVerifyAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingSplash, setIsShowingSplash] = useState(false);

  /* A custom hook that I created to set a cookie with the name tokenCounter and the value counter. With 3 hours for expire */
  const { createCookie } = useCookies("tokenCounter", "counter", 1 / 8);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const googleSignIn = () => {
    setIsShowingSplash(true);
    auth.federatedSignIn({ provider: "Google" });
  };

  const loadGoogleData = async () => {
    setIsShowingSplash(false);
    const authenticated_user = await getAuthenticatedUser();
    if (authenticated_user == null) {
      return;
    }
    const user_data = authenticated_user?.signInUserSession?.idToken?.payload;
    const user_google_token = user_data?.sub;
    if (user_google_token) {
      setIsShowingSplash(true);
    }
    console.log(user_google_token);
    logIn({ user_google_token: user_google_token });
  };

  useEffect(() => {
    if (!mounted.current) {
      loadGoogleData();
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function disp(user_token, cognito_token) {
    dispatch(signedIn(user_token));
    dispatch(setToken(cognito_token));
    createCookie();
  }

  const logIn = async (formData) => {
    setIsShowingSplash(true);

    let { from } = location.state || { from: { pathname: "/dashboard" } };
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 9000,
      heightAuto: true,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      const responseData = await requestSignIn(formData);
      setIsLoading(true);
      if (responseData.error === "UNCONFIRMED") {
        return setVerifyAccount(true);
      }
      switch (responseData.state) {
        case 200:
          // window.localStorage.setItem("user_token", ("Bearer " + responseData.data));
          disp(
            "Bearer " + responseData.data.token,
            responseData.data.cognito_token
          );
          let response = await requestInfoUser(
            "Bearer " + responseData.data.token
          );
          if (response.state === 200) {
            let data = response.data;
            const getToken = await requestGetToken(
              "Bearer " + responseData.data.token,
              "test",
              data.user_email
            );
            dispatch(
              setUserData({
                ...data,
                twilio_token: getToken.data,
              })
            );
            dispatch(setTwilioToken(getToken.data));
          }
          history.replace(from);
          break;
        case 400:
          Toast.fire({
            icon: "warning",
            title: responseData.message,
          });
          if (responseData.data.user_google_token) {
            setTimeout(() => history.replace("/sign-up"), 3000);
          }
          break;
        case 401:
          Toast.fire({
            icon: "warning",
            title: responseData.message,
          });
          break;
        case 403:
          Toast.fire({
            icon: "warning",
            title: responseData.message,
          });
          break;
        default:
          Toast.fire({
            icon: "warning",
            title: responseData.message,
          });
          break;
      }
      if (responseData.state !== 200) {
        setIsShowingSplash(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * It takes a form event, prevents the default action, then sends the form data to the server as
   * JSON.
   */
  const handleSubmitter = async (data) => {
    setIsLoading(true);
    await logIn(data);
  };

  const resendConfirmationEmailHandler = async () => {
    const user_email = getValues("user");
    const response = await requestResendConfirmationCode(user_email);
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 9000,
      heightAuto: true,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "warning",
      title: response.message,
    });
    if (response.state == 200) {
      setVerifyAccount(false);
    }
  };

  return (
    <>
      {isShowingSplash ? (
        <CustomSplash />
      ) : (
        <div className="content-login singUp">
          <div className="brand-login d-flex flex-column flex-wrap justify-content-center col-lg-5 gap-2 labels-group">
            <img
              src={logo}
              alt=""
              className="logo-login"
              onClick={() => setVerifyAccount(false)}
            />
            <span className="dialog-login">More than a loadboard</span>
          </div>
          {!verifyAccount && (
            <div className="form-sign-in col-xl-7 col-md-8">
              <h1 className="h3 mb-3 text-welcome text-center">
                ¡Welcome back!
              </h1>
              <button
                className="btn btn-light btn-block w-100 mb-3 btn-google"
                onClick={googleSignIn}
              >
                <img src={logo_google} alt="" className="me-1" /> Login with
                Google
              </button>
              <p className="orLogin pe-2">Or login with e-mail</p>
              <form
                className="d-flex flex-row flex-wrap form-singUp"
                onSubmit={handleSubmit(handleSubmitter)}
              >
                <div className="mb-1 col-md-12">
                  <label className="form-label mb-1">Email address</label>
                  <Input
                    {...register("user", {
                      required: true,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message:
                          "The email field must to have '@' in its sentence",
                      },
                    })}
                    control={control}
                    type="email"
                    className={
                      errors.user ? "form-control invalid" : "form-control"
                    }
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-2 has-show-pass col-md-12">
                  <label className="form-label mb-1">Password</label>
                  <div 
                   className={
                    errors.password
                      ? "input-group mb-3 invalid"
                      : "input-group mb-3"
                   }
                  >
                    <input
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}
                      type={showPass ? "text" : "password"}
                      className={
                        errors.password
                          ? "form-control border-end-0 invalidPass"
                          : "form-control border-end-0"
                      }
                      placeholder="Password"
                    />
                    <span
                      className="input-group-text btn-eye"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <FiEye />
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row flex-wrap justify-content-between col-12">
                  <div className="form-check">
                    <input className="form-check-input me-2" type="checkbox" />
                    <label className="form-check-label">
                      Keep me logged in
                    </label>
                  </div>
                  <div className="mb-3">
                    <Link className="forgot-link" to={"/recovery-password"}>
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-100 btn btn-lg btn-success mb-3"
                >
                  {isLoading ? "LOADING..." : "LOG IN"}
                </button>
              </form>
              {errors.user?.type === "required" ? (
                <p className="text-danger text-error" role="alert">
                  The email/username field is required
                </p>
              ) : (
                ""
              )}
              {errors.password?.type === "required" ? (
                <p className="text-danger text-error" role="alert">
                  The password field is required
                </p>
              ) : (
                ""
              )}
              {Object.keys(errors).length > 0 &&
                Object.entries(errors).map((item) => (
                  <p
                    className="text-danger text-error mb-1"
                    role="alert"
                    key={item[0]}
                  >
                    {item[1].message}
                  </p>
                ))}
              <p className="orLogin pe-2">Don’t have an account?</p>
              <button
                onClick={() => history.replace("/sign-up")}
                className="w-100 btn btn-lg btn-secondary mb-3"
              >
                SIGN UP
              </button>
            </div>
          )}

          {verifyAccount && (
            <div className="form-sign-in col-xl-7 col-md-8">
              <h1 className="h3 mb-3 text-welcome text-center">
                Verify your account
              </h1>
              <p className="orLogin pe-2">Confirmation e-mail</p>
              <p className="form-label mb-3 text-center">
                Which method would you like{" "}
                <span className="text-success">
                  to use to verify your account?
                </span>{" "}
              </p>
              <button
                onClick={resendConfirmationEmailHandler}
                className="w-100 btn btn-lg btn-secondary mb-3"
              >
                SEND MAIL CONFIRMATION
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
