import React, { useEffect, useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import ChartComponent from '../../components/ChartComponent';
import GeneralMap from '../../components/GeneralMap';
import SimpleTable from '../../components/SimpleTable';
import { useQuery } from '../../hooks/useQuery';
import { selectIndicatorsList, setFromExternalAction } from '../../redux/indicators/IndicatorsSlice';
import statesData from '../../statesData';
import IndicatorsDetailBar from './IndicatorsDetailBar';
import requestGetAnnualIndicator from '../../services/indicators/requestGetAnnualIndicator';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const ChartComponent = React.lazy(() =>
    sleep(4000).then(() => import('../../components/ChartComponent'))
);
const IndicatorsDetail = ({ item }) => {

    let param = useQuery();
    let history = useHistory();
    let sect = param.get('section_name');
    const { idIndicator } = useParams();


    const dispatch = useDispatch();
    const arrInd = useSelector(selectIndicatorsList);
    const authCheck = useSelector(selectGuardianState);

    const [element, setElement] = useState({});
    const [hasMap, setHasMap] = useState(false);
    const [dataSerie, setDataSerie] = useState([]);
    const [dataLabel, setDataLabel] = useState([]);
    const [objSearch, setObjSearch] = useState({})

    useEffect(() => {
        const el = arrInd.filter((i) => i.indicator_id === parseInt(idIndicator));
        if (el[0]?.indicator_other_types) {
            let arr = JSON.parse(el[0]?.indicator_other_types);
            let find = arr.find(n => n == "map");
            setHasMap(find ? true : false);
        }
        setElement(el[0]);
    }, []);
    // console.log(element);

    const customAction = (option) => {
        dispatch(setFromExternalAction(option));
        history.push('/indicators');
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        getIndicatorData()
    }, [element, objSearch]);


    const getIndicatorData = async () => {
        if (!element) {
            return;
        }
        try {
            let year =objSearch?.date?.getFullYear() ?? 2023;
            let options = { user_token: authCheck, year: year, reference: element?.indicator_reference, pagination: 8, page: 1, indicator_associate_to: element?.indicator_associate_to };
            const res = await requestGetAnnualIndicator(options, element?.indicator_frequency);
            let arr = [];
            let arrLabels = [];
            if (res.state === 200) {
                res.data.forEach((el) => {
                    arr.push(el.value);
                    var date = el.date;
                   if (!el.state) {
                    if (typeof el.date === 'string' && element?.indicator_frequency === 'monthly') {
                        date = date.replace(/-/g, '\/');
                        date = new Date(date).getMonth();
                        date = months[date];
                    } else if (typeof el.date === 'string' && element?.indicator_frequency === 'weekly') {
                        date = date.replace(/-/g, '\/');
                        date = new Date(date);
                        let month = date.getMonth();
                        date = 'Week' + (new Date(date).getWeekInMonth()) + '/' + months[month];
                    }
                    arrLabels.push(date +' '+year);
                   } else {
                    arrLabels.push(el.state +' '+ year);
                   }
                });
                setDataSerie(arr);
                setDataLabel(arrLabels);
            }
        } catch (error) {
            console.error(error);
        }
    }
    // console.log(dataSerie);

    const setOptionSearch = (data) => {
        if (!data) {
            return;
        }
        setObjSearch(data);
    }

    return (
        <>
            <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
                <IndicatorsDetailBar showSource setSearch={setOptionSearch} />
                <div className="card my-3">
                    <div className="card-body map-body p-4">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-3 px-2">
                                        <div>
                                            <div className='d-flex flex-row'>
                                                <h4 className='mixing-indicator-title mb-0'>{element?.indicator_name ?? "Indicator name"} </h4>
                                                <FiHelpCircle className="icon-h2 ms-1" />
                                            </div>
                                            <p className='mixing-indicator-subtitle mb-0'>{element?.indicator_unit ?? '(Unit)'}</p>
                                        </div>
                                    </div>
                                    <React.Suspense fallback={<p>Loading...</p>}>
                                        <ChartComponent type={element?.indicator_main_type} data={[{name: element?.indicator_name ?? "Indicator name", data: dataSerie }]} labels={dataLabel} height={'350px'} />
                                    </React.Suspense>
                        {/* {
                            hasMap ?
                                <GeneralMap
                                    inLineStyles={styles}
                                    showControls
                                    zoom={4}
                                    tower={[39.381266, -97.922211]}
                                    showStateData
                                    polygonsPoint={statesData} />
                                :
                                <>
                                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-3 px-2">
                                        <div>
                                            <div className='d-flex flex-row'>
                                                <h4 className='mixing-indicator-title mb-0'>{element?.indicator_name ?? "Indicator name"} </h4>
                                                <FiHelpCircle className="icon-h2 ms-1" />
                                            </div>
                                            <p className='mixing-indicator-subtitle mb-0'>{element?.indicator_unit ?? '(Unit)'}</p>
                                        </div>
                                    </div>
                                    <React.Suspense fallback={<p>Loading...</p>}>
                                        <ChartComponent type={element?.indicator_main_type} data={[{ data: dataSerie }]} labels={dataLabel} height={'350px'} />
                                    </React.Suspense>
                                </>
                        } */}
                    </div>
                </div>
                <div className="card">
                    <div className="card-body px-4 pt-4">
                        {dataLabel.length === 0 || dataSerie.length === 0 ? <h4 className='mixing-indicator-title mb-0 w-100 text-center'>No data </h4> :
                            <SimpleTable theadData={dataLabel} tbodyData={[{ items: dataSerie }]} />
                        }
                    </div>
                </div>
                <div className={!sect ? "col-12 my-3 d-flex flex-row flex-wrap flex-gaping" : "col-12 my-3 d-flex flex-row flex-wrap flex-gaping justify-content-end"}>
                    {!sect &&
                        <>
                            <div className="col-md-4">
                                <button type="button" className="btn btn-outline-success btn-green-outline" onClick={() => customAction(1)}>MERGE PLOT</button>
                            </div>
                            <div className="col-md-4">
                                <button type="button" className="btn btn-outline-success btn-green-outline" onClick={() => customAction(2)}>COMPARE INDICATORS</button>
                            </div>
                        </>
                    }
                    <div className="col-md-4">
                        <button type="button" className="btn btn-outline-success btn-green-outline">GENERATE REPORT</button>
                    </div>
                </div>
            </div>
        </>
    );
}
const styles = {
    height: '420px'
}
export default IndicatorsDetail;