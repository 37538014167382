import React from 'react';
import Chart from 'react-apexcharts';

const ChartComponent = ({ data, type, chartOptions, height, labels }) => {
    let series;
    if (type === 'candlestick') {
        series = [{
            data: data,
        }];
    } else {
        series = data
    }
    const options = {
        ...chartOptions,
        xaxis: {
            type: "category",
            categories: labels ?? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            floating: false,
            decimalsInFloat: undefined,
            overwriteCategories: undefined,
            labels: {
                show: true,
                style: {
                    colors: '#CACACA',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
    };
    return (
        <Chart options={options} type={type} series={series} width="100%" height={height} key={type} />
    );
}

ChartComponent.defaultProps = {
    type: "line",
    height: '230px',
    data: [{
        name: "---", //will be displayed on the y-axis
        data: [1000, 750, 150, 160, 900, 270, 880, 400, 410, 180]
    }, {
        name: "---", //will be displayed on the y-axis
        data: [100, 920, 975, 480, 820, 180, 800, 780, 600, 230]
    }],

    chartOptions: {
        chart: {
            id: "simple-bar",
            toolbar: {
                show: false
            },
        },
        noData: {
            align: 'center',
            text: "No data, please select new search params",
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#cacaca",
                fontSize: '24px',
                fontFamily: "Arial",
                fontWeight:  'bold',
            }
        },
        colors: ["#209E59"],
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: ["#209E59"],
            width: 1,
            dashArray: 0,
        },
        title: {
            show: false,
        },
        subtitle: {
            show: false,
        },
        markers: {
            size: 2,
            colors: undefined,
            strokeColor: '#fff',
            strokeWidth: 1,
            strokeOpacity: 0.9,
            fillOpacity: 1,
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            hover: {
                size: 4
            }
        },
        xaxis: {
            type: "category",
            categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            floating: false,
            decimalsInFloat: undefined,
            overwriteCategories: undefined,
            labels: {
                show: true,
                style: {
                    colors: '#CACACA',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        yaxis: {
            show: true,
            floating: false,
            decimalsInFloat: undefined,
            labels: {
                show: true,
                minWidth: 0,
                maxWidth: 160,
                style: {
                    colors: '#CACACA',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: -5,
                offsetY: 0,
            },

        },
        fill: {
            colors: ["#209E59"],
            opacity: 1,
            type: 'solid',
        },
        grid: {
            show: true,
            borderColor: '#CACACA',
            strokeDashArray: 0,
            position: 'back',
            clipMarkers: false,
            padding: {
                left: 0,
                right: 0
            },
            xaxis: {
                lines: {
                    show: true,
                    offsetX: 0.5,
                    offsetY: 0.5
                }
            },
            row: {
                colors: undefined,
                opacity: 0.2
            },
            column: {
                colors: undefined,
                opacity: 0.2
            },
        },
    }
}


export default ChartComponent;