let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestGetParameters = async (reference) => {
  let response;
  await fetch(`${API_URL}getParameters?parameter_reference=${reference}`)
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });

  return response;
};

export default requestGetParameters;
