/* eslint-disable eqeqeq */
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// components
import FilterResults from "../../../components/modals/FilterResults";
import ResultsBar from './ResultsBar';
const CollapsibleRouteItem = React.lazy(() => import( "./CollapsibleRouteItem"));

const RouteResults = ({routes}) => {
  const { blackBoxRoutes = [] } = useSelector((state) => state.blackBoxRoutes);
// console.log(routes);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState();
  const [data, setData] = useState(blackBoxRoutes);

  useEffect(() => {
    setData(blackBoxRoutes);
  }, [blackBoxRoutes]);

  const dataHandler = () => {
    if (filterData == null) {
      return;
    }
    let filtered_data = blackBoxRoutes ?? [];
    const keys = Object.keys(filterData);
    keys.forEach(key => {
      filtered_data = filtered_data.filter(el => el[key] == null || (el[key] <= filterData[key].max && el[key] >= filterData[key].min));
    });
    setData(filtered_data);
  }

  return (
    <>
      <ResultsBar setShowFilter={setShowFilter} showFilter={showFilter} />
      {
        data.length == 0 && 
        <h2 className="heading-h1 mt-5 text-center">Routes not found</h2> 
      }
      <Suspense fallback={<h2 className="heading-h1 mt-5 text-center">Loading...</h2>}>
        {
          data.map((element, index) => (
            <CollapsibleRouteItem route={element} key={index}/>
          ))
        }
      </Suspense>
      < FilterResults showFilter={showFilter} setShowFilter={setShowFilter} setFilterData={setFilterData} action={dataHandler} />
    </>
  );
};

export default RouteResults;
