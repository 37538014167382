/* eslint-disable no-extend-native */
/* the purpose of booster is to modify javascript objects, adding new properties and methods for the developer */
const month_numbers = ['01','02','03','04','05','06','07','08','09','10','11','12'];

const booster = () => {
  Date.prototype.getFullDate = function () {
    return `${this.getFullYear()}-${month_numbers[this.getMonth()]}-${this.getDate()}`;
  };

  Date.prototype.simpleFormatDate = function () {
    const options = { day: 'numeric', month: 'short',};
    return this.toLocaleDateString("en-US", options);
  }

  Date.prototype.simpleFormatFullDate = function () {
    const options = { day: 'numeric', month: 'short', year: 'numeric'};
    return this.toLocaleDateString("en-US", options);
  }

  Date.prototype.toFormatMDY = function () {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return this.toLocaleDateString("en-US", options);
  }

  Date.prototype.getWeekInMonth = function () {
    const firstDayOfMonth = new Date(this.getFullYear(), this.getMonth(), 1);
    const firstWeekOfMonth = Math.ceil((firstDayOfMonth.getDay() + 1 + firstDayOfMonth.getDate()) / 7);
    const currentWeek = Math.ceil((this.getDate() + firstDayOfMonth.getDay()) / 7);
    const weekOfMonth = currentWeek - firstWeekOfMonth + 1;
    return weekOfMonth;
  }

  Number.prototype.secondsToDays = function() {
    return (this / 39600).toFixed(1);
  }

  Number.prototype.secondsToHours = function () {
    return (this / 3600).toFixed(1);
  };

  Number.prototype.secondsToFormattedMinutes = function () {
    let date = new Date(this * 1000);
    let minute = date.getMinutes() < 9 ? "0" + date.getMinutes() : date.getMinutes();
    let second = date.getSeconds() < 9 ? "0" + date.getSeconds() : date.getSeconds();
    return `${minute}:${second}`;
  };

  String.prototype.hideEmail = function () {
    return this.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  };
};
export default booster;
