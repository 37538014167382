let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestGetRouteLines = async (form, user_token) => {
	form = JSON.stringify(form);
	let response;

	await fetch(`${API_URL}get-route-lines`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: user_token,
		},
		body: form,
	})
		.then((response) => response.json())
		.then((data) => {
			response = data;
		})
		.catch((error) => {
			response = {
				state: 500,
				data: null,
				error,
				message: "Connection error, try again later",
			};
		});
	return response;
};

export default requestGetRouteLines;
