import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: null,
};
export const GuardianSlice = createSlice({
  name: "guardian",
  initialState,
  reducers: {
    signedIn: (state, action) => {
      state.value = action.payload;
    },
    signedOut: (state) => {
      state.value = null;
    },
  },
});

export const { signedIn, signedOut } = GuardianSlice.actions;
export const selectGuardianState = (state) => state.guardian.value;

export default GuardianSlice.reducer;
