import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import CustomLink from '../CustomLink';

const SidebarParentItem = ({ item, active }) => {
    const [open, setOpen] = useState(true);

    return (
        <li className="nav-item" key={item.name} onClick={() => setOpen(!open)}>
            <div className='d-flex flex-row flex-wrap justify-content-between align-items-center'>
                <CustomLink
                    activeOnlyWhenExact={false}
                    to={item.path}
                    label={active ? '' : item.name}
                    icon={item.icon}
                    isToggle={active}

                />
                {!open ? <FiChevronDown className='btn-clean text-white me-4 icon-sidebar-drop' /> : <FiChevronUp className='btn-clean text-white me-4 icon-sidebar-drop' />}
            </div>
            <Collapse in={open}>
                <div id="example-collapse-text">
                    <ul className="nav flex-column">
                        {item.Routes.map((i, index) =>
                            <li className="nav-item" key={index}>
                                <CustomLink
                                    activeOnlyWhenExact={false}
                                    to={i.path}
                                    label={active ? '' : i.name}
                                    icon={i.icon}
                                    isToggle={active}
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </Collapse>
        </li>);
}

export default SidebarParentItem;