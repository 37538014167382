import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Client as InitClient } from "@twilio/conversations";
import { selectGuardianState } from '../../../../redux/auth/GuardianSlice';
import { selectUserDataState } from '../../../../redux/user/UserSlice';
import requestGetToken from '../../../../services/inbox/requestGetToken';
import InboxToolBar from '../../InboxToolBar'
// import MessageItem from '../../MessageItem';
import MessageViewer from '../../MessageViewer';
import NewMessageOffCanvas from '../../NewMessageOffCanvas';
import { selectTwilioTokenState, setTwilioToken } from '../../../../redux/inbox/InboxSlice';
import requestDeleteConversation from '../../../../services/inbox/requestDeleteConversation';
import Swal from 'sweetalert2';
import { setClient } from '../../../../redux/inbox/ConversationSlice';
const MessageItem = React.lazy(() => import('../../MessageItem'));

const Principal = () => {
  const [elToView, setElToView] = useState();
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [conversationList, setConversationList] = useState([]);
  const [clientCollector, setClientCollector] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [conversationType, setConversationType] = useState();

  const authCheck = useSelector(selectGuardianState);
  const userInfo = useSelector(selectUserDataState);
  const twilio_token = useSelector(selectTwilioTokenState);
  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: '#121A1F',
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  /**
   * It's a function that gets a token from an API and sets it to a state.
   */
  const gettingToken = async () => {
    const getToken = await requestGetToken(authCheck, 'test', userInfo.user_email);
    setAccessToken(getToken.data);
    dispatch(setTwilioToken(getToken.data))
  }


  useEffect(() => {
    const client = new InitClient(twilio_token);
    setClientCollector(client);
    dispatch(setClient(client));
    client.on('stateChanged', (state) => {
      console.log(state);
      if (state === 'failed') {
        gettingToken();
      }
    });

    client.on('initialized', async () => {
      let conversationsPaginator = await client.getSubscribedConversations();
      const conversations = conversationsPaginator.items;
      const arrConversations = conversations.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      setConversationList(arrConversations);
    });

    client.on("tokenAboutToExpire", () => {
      gettingToken();
      client.updateToken(accessToken);
    });

  }, [newMessage, twilio_token]);




  /**
   * Create a conversation and add the user to it.
   */
  const initConversation = (message) => {
    setNewMessage(message);
    if (conversationType === 'answering') {
      elToView.conversation.sendMessage(message.message_body);
      toViewer(elToView.conversation);
    } else {
      setTimeout(async () => {
        const conversation = await clientCollector.createConversation();
        await conversation.add(message.message_for);
        await conversation.updateFriendlyName(message.message_subject);
        await conversation.updateAttributes({featured: false, user_creator_data: userInfo ?? {},});
        await conversation.join();
        await conversation.sendMessage(message.message_body);
      }, 500);
    }
  }

  setTimeout(() => {
    clientCollector.on('messageAdded', async (message) => {
      // console.log(message);
      let conversationsPaginator = await clientCollector.getSubscribedConversations();
      const conversations = conversationsPaginator.items;
      const arrConversations = conversations.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      setConversationList(arrConversations);
    });

    clientCollector.on('conversationUpdated', async (conversationUpdated) => {
      console.log(conversationUpdated);
      toViewer(conversationUpdated.conversation);
    });
  }, 3000);

  const toViewer = async (conversation) => {
    // get the messages paginator the latest 30 messages
    console.log(conversation);
    let messagesPaginator = await conversation.getMessages();
    // get messages
    const messages = messagesPaginator.items;
    let objConversation = { conversation: conversation, messages: messages };

    setElToView(objConversation)
  };

  const handleShow = (type) => {
    if (type === 'creating') {
      setElToView({});
    }
    setConversationType(type);
    setShowNewMessage(!showNewMessage);
  };

  const deleteConversation = async (conversation) => {
    // await conversation.delete(); 
    const deleteConversation = await requestDeleteConversation(authCheck, conversation.sid);

    if (deleteConversation.state === 200) {
      const arrConv = [...conversationList];
      const deleting = arrConv.filter(function (el) { return el.sid !== conversation.sid; });
      setNewMessage(null);
      setElToView(null);
      console.log(deleting);
      setConversationList(deleting);
      Toast.fire({
        icon: 'success',
        title: 'Conversation has been deleted'
      });
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong!'
      });
    }
  }

  const addToFeatured = async (conversation) => {
    await conversation.updateAttributes({...conversation.attributes, featured: true});
    console.log(conversation);
  }

  return (
    <div className='py-4'>
      <InboxToolBar onShowNewMsg={handleShow} />
      <div className="d-flex flex-row flex-wrap mt-3 inbox-panel gap-3">
        <div className="card">
          <div className="card-body p-3">
            <Suspense fallback={<h2 className="heading-h2 fw-bold my-3">Loading Conversations </h2>}>
              {conversationList.map((i, index) =>
                <MessageItem key={index} userName={i.createdBy} category={i.category} title={i.friendlyName ?? 'Title'} time={i.dateCreated.simpleFormatDate()} conversation={i} onClick={toViewer} onFeatured={addToFeatured} />
              )}
            </Suspense>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <MessageViewer message={elToView} handle={handleShow} deleteConversation={deleteConversation} />
          </div>
        </div>
      </div>
      <NewMessageOffCanvas mailer={initConversation} show={showNewMessage} handle={handleShow} placement={'bottom'} className={'new-message-pane'} title={conversationType !== 'answering' ? 'New message' : 'Answer message'} backdropClassName={'alternative-back-drop'} />
    </div>
  )
}

export default Principal