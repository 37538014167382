let API_URL = process.env.REACT_APP_API_INDICATORS;

const requestGetAnnualIndicator = async (options, type) => {
  if (!options) {
    return;
  }
  let response;
  let url;
  if (type === "annual") {
    url = `${API_URL}annual-metric?year=${options.year}&metric_reference=${options.reference}&pagination=${options.pagination}&page=${options.page}`;
  }
  if (type === "monthly") {
    url = `${API_URL}monthly-metric?metric_reference=${options.reference}&year=${options.year}`;
  }
  if (type === "weekly") {
    url = `${API_URL}weekly-metric?metric_reference=${options.reference}&year=${2023}&month=01`;
  }
  if (options?.indicator_associate_to === 'usda_grain_prices') {
    url = `${API_URL}usda-grain-price-metric?metric_reference=${options.reference}&year=${2023}&month=02`;
  } 

  const controller = new AbortController();

  //   const user_token = window.localStorage.getItem("user_token");

  await fetch(url, {
    headers: {
      Authorization: options.user_token,
      Accept: "application/json",
    },
    signal: controller.signal,
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });
  controller.abort();
  return response;
};

export default requestGetAnnualIndicator;
