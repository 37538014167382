import React, { useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FiMoreVertical, FiPlus } from "react-icons/fi";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import SmallModal from '../../components/modals/SmallModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';
import { selectUserDataState } from '../../redux/user/UserSlice';
import { selectRouteFoldersState, setRouteFolders } from '../../redux/routeFolders/routeFoldersSlice';

//services
import requestRouteFolders from '../../services/route_folders/requestRouteFolders';
import requestCreateRouteFolder from '../../services/route_folders/requestCreateRouteFolder';
import requestDeleteRouteFolder from '../../services/route_folders/requestDeleteRouteFolder';
import requestEditRouteFolder from '../../services/route_folders/requestEditRouteFolder';

const SettingsBar = ({ routeUrl = '/route-planning' }) => {
    const scrollRef = useHorizontalScroll();
    const authCheck = useSelector(selectGuardianState);
    const userInfo = useSelector(selectUserDataState);
    const route_folders = useSelector(selectRouteFoldersState);

    const dispatch = useDispatch();

    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    
    let { nameFolder } = useParams();
    let history = useHistory();
    let {state, search} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    let id = searchParams.get('id');
    
    const [sectionTitle, setSectionTitle] = useState(nameFolder ?? 'Recommended');

    let { from } = state || { from: { pathname: "/route-planning" } };


    async function fetchData() {
        const res = await requestRouteFolders(authCheck, userInfo.user_id);
        console.log(res);
        if (res.state !== 200) {
            return;
        }
        dispatch(setRouteFolders(res.data));
    }
    useEffect(() => {
        fetchData();
    }, [showModalAdd, showModalEdit]);   

    async function createFolder(nameFolder) {
        try {
            let usr_id = userInfo.user_id
            const formData = { route_folder_name: nameFolder, route_folder_user_id: usr_id };
            const responseData = await requestCreateRouteFolder(formData, authCheck);
            console.log(responseData);
            setShowModalAdd(false);
        } catch (error) {
            console.error(error);
        }
    }

    async function editFolder(nameFolder) {
        let usr_id = userInfo.user_id
        try {
            const formData = { ...nameFolder, route_folder_user_id: usr_id};
            const responseData = await requestEditRouteFolder(formData, authCheck, id);
            setShowModalEdit(false);
        } catch (error) {
            console.error(error);
        }
    }

    const deleteFolder = async () => {
        if (!id) {
            return;
        }
        Swal.fire({
            title: 'Are you sure? ',
            text: "Do you want to remove this folder?",
            background: '#121A1F',
            showCancelButton: true,
            showDenyButton: false,
            reverseButtons: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'REMOVE',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const responseDelete = await requestDeleteRouteFolder(id, authCheck);
                history.replace(from);
                fetchData();
            }
        })
    };


    return (
        <div className='setting'>
            <div className='settingSearch'>
                <span style={{ width: '205px' }} className='btnSettings text-capitalize'> <span className='fw-bold'>Search</span>  - {sectionTitle}</span>
                <div className={(sectionTitle === 'Recommended' || sectionTitle === 'recent') ? 'dropSettings d-none' : 'dropSettings'}>
                    <DropdownButton
                        drop={'down'}
                        title={<FiMoreVertical className='icon-route-planning' />}
                    >
                        <Dropdown.Item onClick={() => setShowModalEdit(!showModalEdit)}>Edit folder name</Dropdown.Item>
                        <Dropdown.Item onClick={deleteFolder}>Remove folder</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
            <div ref={scrollRef} className='settingBar'>
                <Link to={`${routeUrl}`} className='sectionSettings text-decoration-none' onClick={(e) =>  setSectionTitle(e.target.textContent)}>
                    <div className=" btnSettings">
                        Recommended
                    </div>
                </Link>
                <Link to={`${routeUrl}/recent`} className='sectionSettings text-decoration-none' onClick={(e) =>  setSectionTitle(e.target.textContent)}>
                    <div className=" btnSettings">
                        Recent
                    </div>
                </Link>

                {route_folders?.map((item) =>
                    <Link to={{pathname:`${routeUrl}/${item?.route_folder_name}`, search: `?id=${item?.route_folder_id}`}} className='sectionSettings text-decoration-none' onClick={(e) =>  setSectionTitle(e.target.textContent)} key={item?.user_route_folder_id}>
                        <div className='btnSettings'>
                            {item?.route_folder_name}
                        </div >
                    </Link>
                )}
            </div>
            <button className='btnBar' onClick={() => setShowModalAdd(true)}>Add folder
                <FiPlus className='btnSettings' />
            </button>
            <SmallModal actionModal={createFolder} showSmallModal={showModalAdd} setShowSmallModal={setShowModalAdd} title={'New folder'} letterBtn={'CREATE FOLDER'} titleAlert={'Created folder'} textAlert={'Now, you can save your settings'} />
            <SmallModal values={nameFolder} actionModal={editFolder} showSmallModal={showModalEdit} setShowSmallModal={setShowModalEdit} title={'Edit folder'} letterBtn={'UPDATE FOLDER'} titleAlert={'Changes saved'} textAlert={'Now, you can use this settings'} />
        </div>

    )
    
}
SmallModal.defaultProps = {
    subTitle: 'Folder name',
    buttons: false
};
  

export default SettingsBar