import React from 'react';
import { createRoot } from 'react-dom/client';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import booster from './booster';
booster();

// eslint-disable-next-line no-unused-vars
const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const container = document.getElementById('root');
const root = createRoot(container);
let persistor = persistStore(store);


root.render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId={clientId}> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
