/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Calendar from 'moedim';
import styled from 'styled-components';
import ChartModal from '../modals/ChartModal';
import { FiCalendar, FiAlertCircle, FiMapPin } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import OutsideAlerter from '../OutsideAlerter';
import { useSelector } from 'react-redux';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';
import requestGetGasPrices from '../../services/tools/requestGetGasPrices';

export const SearchableDropdown = ({ title, icon, itemClass, hoverOnLane, onChange, inputClass, itemId, spacing_top, value, displayLabel }) => {

    const [openMenu, setOpenMenu] = useState(false);
    const [searching, setSearching] = useState(value);
    const [results, setResults] = useState([]);

    useEffect(() => {
        setSearching(value);
    }, [value])

    const openSwitch = () => {
        setOpenMenu(false);
    }


    const fetching = async (value) => {
        if (value) {
            const resp = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&filter=countrycode:us&type=city&lang=en&apiKey=${process.env.REACT_APP_API_KEY_MAP}`);
            const places = await resp.json();
            const currentItems = places.features.filter((item) => item.properties.result_type == 'city');
            setResults(currentItems);
        }
    }

    const selectItem = async (item) => {
        onChange(item);
        setSearching(item.city ?? item.state);
        setOpenMenu(false);
    }

    const writingHandler = () => {
        const timer = setTimeout(() => {
            fetching(searching)
        }, 500);
        if (searching) {
            setOpenMenu(true);
        } else {
            setOpenMenu(false);
        }
        return () => clearTimeout(timer);
    };

    return (
        <Dropdown as={'div'} className={itemClass}>
            {icon && icon}
            <div className='text-start lh-1'>
                {title && <p className='route-name fw-bold mb-0'>{title}</p>}
                <input type="text" autoComplete="off" className={inputClass} onKeyUp={writingHandler} onChange={(event) => setSearching(event.target.value)} id={itemId} value={searching} />
                {displayLabel &&
                    <label htmlFor="departure">{searching == '' && 'California'}</label>
                }
                {hoverOnLane && hoverOnLane}
            </div>
            <OutsideAlerter position={'absolute'} listener={openMenu} trigger={openSwitch}>
                <ul className={openMenu ? 'dropdown-menu show' : 'dropdown-menu'} style={{ top: spacing_top }}>
                    {results.map((item, index) =>
                        <Dropdown.Item as={'div'} className="dropdown-item" key={`s_dropdown${index}`} onClick={() => selectItem(item.properties)}>
                            <div className="d-flex flex-row flex-wrap align-items-center">
                                <FiMapPin className='icon-drop me-2' />
                                <div>
                                    <p className="text-heading-places mb-2">{item.properties.city}</p>
                                    <p className="text-description-places mb-0">{item.properties.state}</p>
                                </div>
                            </div>
                        </Dropdown.Item>
                    )}
                </ul>
            </OutsideAlerter>
        </Dropdown>);
}

const ItemFuelPrices = () => {
    const [dateValue, setDateValue] = useState(new Date());
    const [dateDropdown, setDateDropdown] = useState(false);
    const [open, setOpen] = useState(false);
    const [showChartModal, setShowChartModal] = useState(false);
    const [formattedDate, setFormattedDate] = useState(null);
    const authCheck = useSelector(selectGuardianState);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [pricesList, setPricesList] = useState([]);


    const StyledCalendar = styled(Calendar)`
        --moedim-primary: #1c8c4f;
        width: 260px;
        border: 1px solid #364752;
        margin-top: -0.5rem;
        color: #cacaca;
        border-radius: 16px;
        background: #121A1F;
        padding: 24px 24px 24px 15px;
        position: relative;
        z-index: 99;
    `;

    /* A hook that is called when the component is mounted and when the dateValue changes. */
    useEffect(() => {
        const year = dateValue.getFullYear();
        const month = dateValue.getMonth() + 1; 
        const day = dateValue.getDate();
        
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');
        
        const formatted = `${year}-${formattedMonth}-${formattedDay}`;
        setFormattedDate(formatted); 
    }, [dateValue])
    
    
   /**
    * The formatDate function takes a date object and returns a string in the format of "Month Day,
    * Year".
    * @returns The date in the format of MM/DD/YYYY
    */
    const formatDate = () => {
        var date = dateValue.toLocaleDateString("en-US");
        return date;
    }

    /**
     * The function toGetPrices() is an async function that makes a request to the server to get the
     * gas prices for a given city and date. If the request is successful, the function filters the
     * response data and sets the state of the pricesList and open variables.
     */
    const toGetPrices = async () => {
        let obj_looking_for = {
            gas_price_city_name: selectedPlace?.city,
            date: formattedDate,
        }
        try {
            const res = await requestGetGasPrices(authCheck, obj_looking_for);
            if (res.state === 200) {
                const filtered = Object.entries(res.data[0]).filter((el) =>
                    el[0] === "gas_price_gasoline" || el[0] === "gas_price_mid_grade" || el[0] === "gas_price_premium" || el[0] === "gas_price_diesel"
                )
                setPricesList(filtered);
                setOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * This function takes a string, splits it into an array of words, capitalizes the first letter of
     * each word, and then joins the array back into a string.
     * @returns the result of the function.
     */
    const toFormatTitle = (wordToFormat) => {
        const words = wordToFormat.split("_"); 
        const upperCases = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)); 
        const result = upperCases.join(" "); 
        return result
    }

    return (
        <div className="m-3">
            <div className="col-md-12 pt-2 mb-4">
                <h1 className="heading-h1 fs-2 mb-0 fw-bold">Fuel prices</h1>
                <h6 className="subtitle-graph">Calculate the cost of the lane</h6>
            </div>
            <div className="col-md-12 d-flex flex-row mt-2">
                <div className='me-2'>
                    <span className='text-name-user fw-bold'>Region</span>
                </div>
                <div>
                    <span className="letter-subtitle">Select your region</span>
                </div>
            </div>
            <div className="col-md-12 mt-2">
                <SearchableDropdown onChange={(item) => setSelectedPlace(item)} itemClass={'drop-places mb-2'} itemId={'origin'} />
            </div>
            <div className="col-md-12 d-flex flex-row mt-2">
                <div className='me-2'>
                    <span className='text-name-user fw-bold'>Date</span>
                </div>
                <div>
                    <span className="letter-subtitle">Select your date</span>
                </div>
            </div>
            <div className="col-md-12 d-flex flex-row mt-2 w-100">
                <button onClick={() => dateDropdown ? setDateDropdown(false) : setDateDropdown(true)} type="button" className="dateDrop w-100">
                    <FiCalendar className='letterDeparture' />
                    <span className='letterDeparture'>{formatDate()}</span>
                </button>
            </div>
            {dateDropdown &&
                <div className="col-md-12">
                    <StyledCalendar className='calendar' value={dateValue} onChange={(d) => {setDateValue(d); setDateDropdown(false)}} />
                </div>}

            <div className="col-md-12 mt-3">
                <button type="button" className="btn btn-success btn-route-lg w-100" onClick={() => toGetPrices()}>CALCULATE</button>
            </div>
            <div className="divider-4"></div>

            {open &&
                <div>
                    {pricesList.map((i, index) => <div className="col-md-12 cardOffMenu mt-3 mb-3" key={index}>
                        <div>
                            <div className="col-md-12 mt-2">
                                <div className='d-flex flex-row justify-content-between mb-0'>
                                    <span className='route-name mb-0'>{toFormatTitle(i[0]) ?? 'Title'}</span>
                                    <FiAlertCircle className='icon-circle' />
                                </div>
                                {/* <p className="letter-subtitle mb-0">{i.subTitle}</p> */}
                                <div>
                                    <span className='text-numberSuccess'>${i[1] ?? 0}</span>
                                </div>
                            </div>
                            <div className='col-md-12 d-flex flex-row justify-content-between align-items-center'>
                                <div>
                                    <span className='letter-subtitle'>Yesterday</span>
                                </div>
                                <div>
                                    <span className='letter-numberDanger'>${0}</span>
                                </div>
                            </div>
                            <div className='col-md-12 d-flex flex-row justify-content-between align-items-center'>
                                <div>
                                    <span className='letter-subtitle'>Last week</span>
                                </div>
                                <div>
                                    <span className='letter-numberSuccess'>${i[1] ?? 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    <div className="col-md-12 mt-4">
                        <button type="button" className="btn btn-outline-success btn-route-lg w-100" onClick={() => setShowChartModal(!showChartModal)}>VIEW CHARTS</button>
                    </div>
                    <ChartModal showChartModal={showChartModal} setShowChartModal={setShowChartModal} />
                </div>}
        </div>
    )
}

SearchableDropdown.defaultProps = {
    title: null,
    icon: null,
    itemClass: '',
    hoverOnLane: false,
    onChange: false,
    inputClass: 'form-control-plaintext place-field',
    itemId: null,
    spacing_top: '0.5rem',
    value: '',
    displayLabel: false
}

export default ItemFuelPrices