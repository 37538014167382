import React, { useState } from 'react';
import { FiTruck, FiArrowUpRight, FiArrowDownRight, FiBookmark, FiMoreHorizontal } from "react-icons/fi";
import Swal from "sweetalert2";
import { useHistory, useLocation } from 'react-router-dom';
import OutsideAlerter from './OutsideAlerter';

//modal
import EditSettings from './modals/EditSettings';
import SaveSettings from './modals/SaveSettings';

const RouteCard = ({ item=null, folderList = null }) => {
    const [showEditSettings, setShowEditSettings] = useState(false);
    const [showSaveSettings, setShowSaveSettings] = useState(false);
    const [show, setShow] = useState(false);
    const [subDrop, setSubdrop] = useState(false);
    const openSwitch = () => { setShow(false); setSubdrop(false); };
    const openSwitchSubItem = () => setSubdrop(false);
    const history = useHistory();
    let location = useLocation();
    const alertRemove = () => {
        Swal.fire({
            title: 'Are you sure? ',
            text: "Do you want to remove this setting?",
            background: '#121A1F',
            showCancelButton: true,
            showDenyButton: false,
            reverseButtons: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'REMOVE',
        })
    };

    const alertSaved = () => {
        let timerInterval;
        Swal.fire({
            title: 'Changes saved',
            text: 'your settings were moved to settings 1',
            background: '#121A1F',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 9000,
            willClose: () => {
                clearInterval(timerInterval)
            }
        })
    };

    let { from } = location.state || { from: { pathname: "/route-planning/results" } };

    return (<div className="card card-graph-bg">
        <div className="card-body p-3 pt-4 pe-4">
            <div className="d-flex flex-row flex-wrap w-100 gap-2">
                {item.route_folder_name &&
                    <div className={ [ styles.contentDiv, 'align-items-center' ]}>
                        <div className={styles.contentSubDiv}>
                            <label className='letterModal ms-2'>{item.route_folder_name}</label>
                        </div>
                        <div className="icon-route-planning">
                            <FiMoreHorizontal onClick={() => setShow(!show)} className='icon-route-planning' />
                        </div>
                        {show &&
                            <OutsideAlerter classNameElement={"box-drop"} position={'absolute'} top={'3rem'} listener={show} trigger={openSwitch}>
                                <ul>
                                    <li onClick={() => setSubdrop(!subDrop)}>Move To</li>
                                    <li onClick={alertRemove}>Remove</li>
                                </ul>
                                {subDrop &&
                                    <OutsideAlerter classNameElement={"box-drop sub-drop"} position={'absolute'} listener={subDrop} trigger={openSwitchSubItem}>
                                        <ul>
                                            {folderList?.map((it) =>
                                                <li onClick={alertSaved}>{it.urf_name}</li>
                                            )}
                                        </ul>
                                    </OutsideAlerter>
                                }
                            </OutsideAlerter>
                        }
                    </div>
                }
                <div className={styles.contentDiv}>
                    <div className={styles.contentSubDiv}>
                        <div className="icon-route-planning h-auto me-1 ms-1">
                            <FiArrowUpRight />
                        </div>
                        <div>
                            <p className='route-name fw-bold mb-0'>{item?.get_black_box_route?.get_departure?.city_name ?? '---'}</p>
                            <p className='route-desc mb-0'>{item?.get_black_box_route?.get_departure?.get_state?.state_name ?? '---'}</p>
                        </div>
                    </div>
                    {!item.route_folder_name &&
                        <div className="icon-route-top">
                            <FiBookmark onClick={() => setShowSaveSettings(!showSaveSettings)} />
                        </div>
                    }
                    {item.route_folder_name &&
                        <div>
                            <p className='route-name fw-bold mb-0'>5 Days</p>
                            <p className='route-desc mb-0 text-end'>En route</p>
                        </div>
                    }
                </div>
                <div className={styles.contentDiv}>
                    <div className={styles.contentSubDiv}>
                        <div className="icon-route-planning h-auto me-1 ms-1">
                            <FiArrowDownRight />
                        </div>
                        <div>
                            <p className='route-name fw-bold mb-0'>{item?.get_black_box_route?.get_destination?.city_name ?? '---'}</p>
                            <p className='route-desc mb-0'>{item?.get_black_box_route?.get_destination?.get_state?.state_name ?? '---'}</p>
                        </div>
                    </div>
                    {!item.route_folder_name &&
                        <div>
                            <p className='route-name fw-bold mb-0'>5 Days</p>
                            <p className='route-desc mb-0 text-end'>En route</p>
                        </div>
                    }
                </div>
                <div className={styles.contentDiv}>
                    <div className={styles.contentSubDiv}>
                        <div className="icon-route-FiTruck h-auto me-2 ms-1">
                            <FiTruck />
                        </div>
                        <div>
                            <p className='route-desc mb-0'>Dryvan</p>
                        </div>
                    </div>
                    <div>
                        <p className='route-desc mb-0'>FTL</p>
                    </div>
                </div>

                <div className="col-12 d-flex flex-row flex-wrap justify-content-between ms-1 me-1">
                    <div className="col-md-6 d-flex flex-row flex-wrap justify-content-start">
                        <button type="button" onClick={() => setShowEditSettings(!showEditSettings)} 
                        className="btn btn-outline-secondary btn-route-planning">CUSTOM</button>
                    </div>
                    <div className="col-md-6 d-flex flex-row flex-wrap justify-content-end">
                        <button type="button" className="btn btn-outline-success btn-route-planning" 
                        onClick={() => history.replace(from)}>SEARCH</button>
                    </div>
                </div>
            </div>
        </div>
        <EditSettings showEditSettings={showEditSettings} setShowEditSettings={setShowEditSettings} />
        <SaveSettings folderList={folderList} showSaveSettings={showSaveSettings} setShowSaveSettings={setShowSaveSettings} />

    </div >);
}

const styles = {
    contentDiv: 'col-md-12 d-flex flex-row flex-wrap justify-content-between mb-2',
    contentSubDiv: 'd-flex flex-row flex-wrap align-items-start',
}

export default RouteCard;