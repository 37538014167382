import React from 'react';
import { FiTrash2 } from "react-icons/fi";
import { Link } from 'react-router-dom';

const VariableCostItem = ({ data, actionDelete = false}) => {
    return (
        <>
            <div className="d-flex flex-row justify-content-between w-100 pb-3 mt-0 divider-3 ps-2">
                <Link to={`/settings/default_settings/variable_cost/edit?idStd=${data.std_vehicle_val_id}`} className='p-0 col-md-11' >
                    <div>
                        <p className='fs-6 mb-0 p-0'>{data.std_vehicle_val_name}</p>
                        <p className='fs-6 fw-bold mb-0 p-0'>$ {data.std_vehicle_val_total}</p>
                    </div>
                </Link>
                <div>
                    <FiTrash2 onClick={() => actionDelete(data.std_vehicle_val_id)} className='icon-settings mt-3 me-2' />
                </div>
            </div>
        </>
    )
}

export default VariableCostItem