import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { FiMessageCircle } from "react-icons/fi";
const FaqsItem = ({data}) => {
    
    const [open, setOpen] = useState(false);

  return (
    <>
    <button
      onClick={() => setOpen(!open)}
      aria-expanded={open}
      className={open ? "btn btn-outline-success btnFaqs w-100" : "btn btn-outline-success btnFaqs w-100 border-0"}
    >
        <div className='d-flex flex-row  align-items-center'>
            <FiMessageCircle className='fs-4 ms-3 me-2' />
            <p className='mb-0 fs-6 fw-bold'>{data.title}</p>
        </div>
    </button>
    <Collapse in={open}>
      <div className='p-4'>
        <p className='letterResults fw-bold'>{data.subtitle}</p>
        <p className='text-faqs mb-0'>{data.text}</p>
      </div>
    </Collapse>
  </>
  )
}

export default FaqsItem