import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FiMoreHorizontal, FiBell, FiChevronRight, FiCornerUpLeft, FiChevronDown, FiUser, FiSliders, FiEdit, FiDollarSign, FiSun } from "react-icons/fi";
import imgUser from "../../app/images/mask47.png";
import logoEf from "../../app/images/90466.svg";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import useReactRouterBreadcrumbs from "../../hooks/useBreadCrumbs.tsx";
import { useSelector } from "react-redux";
import { selectUserDataState } from "../../redux/user/UserSlice";
import DropdownNotifications from "../DropdownNotifications";
import { requestForToken, onMessageListener } from "../../app/firebaseInit";
import generalNotifications from "../../services/generalNotifications/requestNotificationsList";
import { selectGuardianState } from "../../redux/auth/GuardianSlice";
import requestSaveNotification from "../../services/generalNotifications/requestSaveNotification";
import requestEditNotificationRead from "../../services/generalNotifications/requestEditNotificationRead";

const NavbarTop = ({ logo = false }) => {
  requestForToken();

  const breadCrumbs = useReactRouterBreadcrumbs();
  const authCheck = useSelector(selectGuardianState);
  const userInfo = useSelector(selectUserDataState);
  const [notificationList, setNotificationList] = useState([]);
  
  const history = useHistory();
  let { url } = useRouteMatch();

  const goBack = () => {
    if (url) {
      if (url !== '/dashboard') {
        history.goBack()
      }
    }
    return;
  }

  let count = 0;
  const arr = notificationList.forEach(element => {
    if (element.notifications_read === 0) {
      count = count + 1;
    }
  });

  /**
   * This function retrieves general notifications for a user and sets them in the notification list
   * state.
   */
  const getNotifications = async () => {
    try {
      const res = await generalNotifications(authCheck, userInfo.user_id);
      if (res.state === 200) {
        setNotificationList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const saveNotification = async (data) => {
    if (!data) {
      return;
    }
    try {
      const res = await requestSaveNotification(authCheck, data);
      if (res.state === 200) {
        let obj = res.data;
        setNotificationList([...notificationList, obj]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const editNotificationRead = async (data) => {
    try {
      const res = await requestEditNotificationRead(authCheck, data);
      if (res.state === 200) {
       getNotifications(); 
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNotifications();
  }, [])


  const itemDrop = [
    {
      title: 'Account',
      icon: <FiUser className="iconNavbar me-2" />,
      link: '/settings/account'
    },
    {
      title: 'Notifications',
      icon: <FiBell className="iconNavbar me-2" />,
      link: '/settings/notifications'
    },
    {
      title: 'Default settings',
      icon: <FiSliders className="iconNavbar me-2" />,
      link: '/settings/default_settings'
    },
    {
      title: 'Preferences',
      icon: <FiEdit className="iconNavbar me-2" />,
      link: '/settings/preferences'
    },
    {
      title: 'Payment',
      icon: <FiDollarSign className="iconNavbar me-2" />,
      link: '/settings/payment'
    },
  ];

  onMessageListener()
    .then((payload) => {
      let obj = {
        notifications_associate_to: 'inbox', //Could be replace by Topics on Google FireBase
        notifications_title: payload?.notification?.title ?? 'New notification',
        notifications_data: JSON.stringify({ date: '', body: (payload?.notification?.body ?? null) }),
        notifications_notifier: 1,
        notifications_notified: 2,
        notifications_read: 0,
        notifications_route: 'inbox',
      }
      saveNotification(obj);
      setNotificationList([...notificationList, obj]);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      <header className="navbar bg-transparent flex-md-nowrap p-0 nav-ef-border">
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-between flex-fill pe-3">
          <div className="p-3 d-flex flex-row flex-wrap align-items-center">
            <img src={logoEf} alt="" className={logo ? "img-sidebar me-3" : "img-sidebar me-3 d-none"} />
            <FiCornerUpLeft onClick={() => goBack()} className="go-back-arrow me-2" />
            {breadCrumbs.map(({
              match,
              breadcrumb,
            }, index) => (
              <span key={match.url}>
                <NavLink className="bread-crumbs fw-bold text-decoration-none" to={match.url} activeStyle={{
                  color: "#BCBCBC"
                }}
                >{breadcrumb}  {(index !== breadCrumbs.length - 1) && (index !== 0) && <FiChevronRight />}</NavLink>
              </span>
            ))}
          </div>
          <div>
            <div className={logo ? "form-group has-search d-none" : "form-group has-search"} >
              <Search className="form-control-feedback m-1 ms-2" />
              <input type="text" className="form-control inputSearch" placeholder="Search now" />
            </div>
          </div>
        </div>
        <div className="navbar-nav flex-row">
          <div className="nav-item text-nowrap mt-2 me-2">
            <FiMoreHorizontal className="iconNavbar" />
          </div>
          <div className='dropNotification'>
            <DropdownNotifications data={notificationList} actionEdit={editNotificationRead}/>
            {count >= 1 ? <div className="circle-notification"></div> : ''}
          </div>
          <div className="nav-item text-nowrap box-user ms-2">
            <div>
              <img src={imgUser} alt="" className="img-user-nav" />
            </div>
            <div>
              <p className="text-name-user text-spacing fw-bold m-0">{userInfo?.user_name ?? 'Name'} {userInfo?.user_last_name ?? 'LastName'}</p>
              <p className="text-name-cargo m-0">Analyst</p>
            </div>
            <div className='dropNav'>
              <DropdownButton
                drop={'start'}
                title={<FiChevronDown className='btn-dark btn-nav' />}
              >
                {itemDrop.map((item, index) =>
                  <Dropdown.Item key={index} as={'div'} className="dropdown-item" onClick={() => history.push(item.link)}>
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                      <div className="d-flex flex-row align-items-center">{item.icon} {item.title}</div>
                      <div><FiChevronRight className="iconNavbar" /></div>
                    </div>
                  </Dropdown.Item>
                )}
                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center itemNavbar border-0">
                  <div className="d-flex flex-row align-items-center"><FiSun className="iconNavbar me-2" /> Light mode</div>
                  <div className="form-check form-switch ms-2">
                    <input className="inputNavbar form-check-input mb-0 mt-0" type="checkbox" role="switch" />
                  </div>
                </div>
                {/* </Dropdown.Item> */}
              </DropdownButton>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavbarTop;
