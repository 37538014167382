import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    featured_conversations: [],
    fcm_token: '',
    global_client: null,
};
export const ConversationSlice = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        setFeaturedConversation: (state, action) => {
            state.featured_conversations = [...state.featured_conversations, action.payload];
        },

        setClient: (state, action) => {
            state.global_client = action.payload;
        },
        setFcmToken: (state, action) => {
            state.fcm_token = action.payload;
        },


        // updateSelectedIndicators: (state, action) => {
        //     let arr = state.selected_values;
        //     let objIndex = arr.findIndex((obj => obj.id === action.payload.id));
        //     arr[objIndex] = action.payload;
        //     state.selected_values = arr;
        // },

        // deleteSelectedIndicators: (state, action) => {
        //     const filtered = state.selected_values.filter(function (el) { return el.id !== action.payload.id; });
        //     state.selected_values = filtered;
        // },
    },
});

export const {
    setFeaturedConversation,
    setClient,
    setFcmToken,
} = ConversationSlice.actions;
export const selectFeaturedConversationsState = (state) => state.conversations.featured_conversations;
export const selectClientState = (state) => state.conversations.global_client;
export const selectFcmTokenState = (state) => state.conversations.fcm_token;


export default ConversationSlice.reducer;
