import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useParameter } from "../../../../hooks/useParameter";
import { selectGuardianState } from "../../../../redux/auth/GuardianSlice";
import requestNotification from "../../../../services/notifications/requestNotification";
import requestSaveNotification from "../../../../services/notifications/requestSaveNotification";

const Notifications = () => {
  const indicator_frequency = useParameter("indicator_frequency");
  const conditional_indicators = useParameter("conditional_indicators");
  const authCheck = useSelector(selectGuardianState);
  const [dataNotificationNotices, setDataNotificationNotices] = useState([]);
  const [dataNotificationIndicators, setDataNotificationIndicators] = useState([]);

  const { 
    register, 
    handleSubmit,
    control,
  } = useForm();
  const {
    // eslint-disable-next-line no-unused-vars
    fields,
  } = useFieldArray({
    control,
    name: "indicators"
  });

  async function fetchData() {
    const res = await requestNotification(authCheck);
    if (res.state === 200) {
      setDataNotificationNotices(res.data.notices);
      setDataNotificationIndicators(res.data.indicators);
    }
  }

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    const arrIndicator = [];
    data.indicators.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      let obj;
      if(!element.uid_conditional && !element.uid_value){
        let obj = { 
          uid_indicator_id: parseInt(element.uid_indicator_id),
          uid_active: element.uid_active,
          uid_frequency: parseInt(element.uid_frequency),
        };
        arrIndicator.push(obj);
      } else {
        let obj = { 
          uid_indicator_id: parseInt(element.uid_indicator_id),
          uid_active: element.uid_active,
          uid_conditional: parseInt(element.uid_conditional),
          uid_value: element.uid_value ? parseInt(element.uid_value) : 0,
        };
        arrIndicator.push(obj);
      }
    });

    const formData = {
      notices: {
        aun_truck_news: data.aun_truck_news ? 1 : 0,
        aun_ef_routing_report: data.aun_ef_routing_report ? 1 : 0,
      },
      indicators: arrIndicator
    };
    
    try {
      const responseData = await requestSaveNotification(formData, authCheck);
      if (responseData.state === 200) {
        Swal.fire({
          background: "#121A1F",
          title: "Wait a minute",
          text: "Successfully saved",
          showConfirmButton: false,
          timer: 1000,
          width: "305px",
        });
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mt-3 mb-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-3 mb-5 flex-system-content">
          <div className="d-flex flex-row">
            <div className="initial-element">Notices</div>
            <div className="d-flex flex-column">
              {dataNotificationNotices.map((item, index) => (
                <>
                  <div className="p-2 middle-element d-flex flex-row" key={index}>
                    <div className="form-check form-switch me-2">
                      <input
                        {...register("aun_truck_news")}
                        className="inputSettings form-check-input"
                        type="checkbox"
                        role="switch"
                        defaultChecked={item.aun_truck_news === 1 }
                      />
                    </div>
                    <div>
                      <p className="fs-6 middle-element middle-element-text fw-bold mb-0 p-0">
                        Truck news
                      </p>
                      <p className="fs-6 middle-element middle-element-text mb-0 p-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>
                  <div className="p-2 middle-element d-flex flex-row">
                    <div className="form-check form-switch me-2">
                      <input
                        {...register("aun_ef_routing_report")}
                        className="inputSettings form-check-input"
                        type="checkbox"
                        role="switch"
                        defaultChecked={item.aun_ef_routing_report === 1 }
                      />
                    </div>
                    <div>
                      <p className="fs-6 middle-element middle-element-text fw-bold mb-0 p-0">
                        Ef routing report
                      </p>
                      <p className="fs-6 middle-element middle-element-text mb-0 p-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="initial-element">Indicators</div>
            <div className="d-flex flex-column">
              {dataNotificationIndicators.map((element, index) => (
                <div className="p-2 middle-element d-flex flex-row" key={index}>
                    <input
                      {...register(`indicators.${index}.uid_indicator_id`)}
                      type="hidden"
                      defaultValue={ element?.uid_indicator_id ? element?.uid_indicator_id : "" }
                    />
                  <div className="form-check form-switch me-2">
                    <input
                      {...register(`indicators.${index}.uid_active`)}
                      className="inputSettings form-check-input"
                      type="checkbox"
                      role="switch"
                      defaultChecked={element?.uid_active === 1 }
                    />
                  </div>
                  <div className="col-md-12 d-flex flex-row gap-3 mt-1">
                    <div className="col-md-3">
                      <p className="fs-6 middle-element middle-element-text fw-bold mb-1 p-0 text-spacing-notification">{element.get_indicator.indicator_name}</p>
                      <select
                        {...register(`indicators.${index}.uid_frequency`)}
                        className="selectSettings w-100"
                        disabled={element?.uid_frequency === null ? true : false}
                      >
                        <option value="0" >Select Frequency</option>
                        {indicator_frequency?.map((e, i) => (
                          <option
                            key={i}
                            value={e.pv_id}
                            selected={ element?.uid_frequency  ? element?.uid_frequency === e.pv_id : 0 }
                          >
                            {e?.pv_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <p className="fs-6 middle-element middle-element-text mb-1 p-0">Category</p>
                      <select className="selectSettings w-100">
                        <option>Volume sales in LA</option>
                        <option>Data 1</option>
                        <option>Data 1</option>
                        <option>Data 1</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <p className="fs-6 middle-element middle-element-text mb-1 p-0">Conditional</p>
                      <select
                        {...register(`indicators.${index}.uid_conditional`)}
                        className="selectSettings w-100"
                        disabled={element?.uid_conditional === null ? true : false}
                      >
                        <option value="0">Select Conditional</option>
                        {conditional_indicators?.map((e, i) => (
                          <option
                            key={i}
                            value={e.pv_id}
                            selected={ element?.uid_conditional ? element?.uid_conditional === e.pv_id : 0}
                          >
                            {e.pv_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <p className="fs-6 middle-element middle-element-text mb-1 p-0">Value</p>
                      <input
                        {...register(`indicators.${index}.uid_value`)}
                        type="number"
                        className="form-control formSettings"
                        placeholder="$100"
                        disabled={element?.uid_value === 0 ? true : false}
                        defaultValue={ element.uid_value ? element.uid_value : "" }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-end gap-3">
          <button type="button" className="btn btn-lg btn-secondary">RESET ALL CHANGES</button>
          <button type="submit" className="btn btn-lg btn-success">SAVE CHANGES </button>
        </div>
      </form>
    </div>
  );
};

export default Notifications;
