const chartsConfig = {
  bar: {
    chart: {
      type: "bar",
      background: "#1B252C",
      toolbar: {
        show: false,
      },
    },
    colors: ["#209E59"],
    fill: {
      colors: ["#209E59"],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: "#686868",
      strokeDashArray: 0,
      position: "back",
      padding: {
        top: -18,
        bottom: 0,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    // yaxis: {
    //   labels: {
    //     formatter: function (y) {
    //       return y.toFixed(0);
    //     },
    //   },
    // },
    xaxis: {
      type: "category",
      categories: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun"],
      labels: {
        rotate: -90,
      },
    },
    markers: {
      size: 2,
      colors: undefined,
      strokeColor: "#fff",
      strokeWidth: 1,
      strokeOpacity: 0.9,
      fillOpacity: 1,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      hover: {
        size: 4,
      },
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: ["#209E59"],
      width: 3,
      dashArray: 0,
    },
    noData: {
      text: "Loading...",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: '14px',
        fontFamily: "Helvetica"
      }
    },
  },
  //Type Line
  line: {
    chart: {
      type: "line",
      background: "#1B252C",
      toolbar: {
        show: false,
      },
    },
    noData: {  
      text: "Loading...",  
      align: 'center',  
      verticalAlign: 'middle',  
      offsetX: 0,  
      offsetY: 0,  
      style: {  
        color: "#000000",  
        fontSize: '14px',  
        fontFamily: "Helvetica"  
      }  
    },
    colors: ["#209E59"],
    fill: {
      colors: ["#209E59"],
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: "#686868",
      strokeDashArray: 0,
      position: "back",
      padding: {
        top: -18,
        bottom: 0,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        rotate: -90,
      },
    },
    markers: {
      size: 2,
      colors: undefined,
      strokeColor: "#fff",
      strokeWidth: 1,
      strokeOpacity: 0.9,
      fillOpacity: 1,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      hover: {
        size: 4,
      },
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: ["#209E59"],
      width: 3,
      dashArray: 0,
    },
  },
  //Type heatmap
  heatmap: {
    chart: {
      height: 350,
      type: "heatmap",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1B252C"],
    title: {
      text: "HeatMap Chart (Single color)",
    },
    noData: {  
      text: "Loading...",  
      align: 'center',  
      verticalAlign: 'middle',  
      offsetX: 0,  
      offsetY: 0,  
      style: {  
        color: "#000000",  
        fontSize: '14px',  
        fontFamily: "Helvetica"  
      }  
    },
  },
  //Type polarArea
  polarArea: {
    chart: {
      width: 380,
      type: "polarArea",
    },
    labels: ["Rose A", "Rose B", "Rose C", "Rose D", "Rose E"],
    fill: {
      opacity: 1,
    },
    stroke: {
      width: 1,
      colors: undefined,
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },
    noData: {  
      text: "Loading...",  
      align: 'center',  
      verticalAlign: 'middle',  
      offsetX: 0,  
      offsetY: 0,  
      style: {  
        color: "#000000",  
        fontSize: '14px',  
        fontFamily: "Helvetica"  
      }  
    },
  },
  candlestick: {
    chart: {
      type: "candlestick",
      height: 350,
    },
    noData: {  
      text: "Loading...",  
      align: 'center',  
      verticalAlign: 'middle',  
      offsetX: 0,  
      offsetY: 0,  
      style: {  
        color: "#000000",  
        fontSize: '14px',  
        fontFamily: "Helvetica"  
      }  
    },
    title: {
      text: "CandleStick Chart",
      align: "left",
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  },
};
export default chartsConfig;
