import React, { useEffect, useState } from "react";
import requestGetUserRoutes from "../../../../services/user_routes/requestGetUserRoutes";
import { useSelector } from "react-redux";
import { selectGuardianState } from "../../../../redux/auth/GuardianSlice";
import DesignedRoutesBar from "../DesignedRoutes/DesignedRoutesBar";
import RouteItem from "../DesignedRoutes/RouteItem";
import Swal from "sweetalert2";
import requestDeleteUserRoute from "../../../../services/user_routes/requestDeleteUserRoute";
import { async } from "q";
import requestAddUserRouteInUserRouteFolder from "../../../../services/user_route_folders/requestAddUserRouteInUserRouteFolder";

const DesignedRoutes = () => {
	const user_token = useSelector(selectGuardianState);

	const [idFolder, setIdFolder] = useState();
	const [userRoutes, setUserRoutes] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!loading) {
			return;
		}
		const getRoutes = async () => {
			const response = await requestGetUserRoutes(user_token);
			if (response?.state != 200) {
				return;
			}
			const routes = response?.data;
			setUserRoutes(routes);
			setFilteredData(routes);
		};
		getRoutes();
		setLoading(false);
	}, [loading]);

	useEffect(() => {
		if (userRoutes.length == 0 || !idFolder) {
			return;
		}
		const data = userRoutes.filter((route) =>
			route.get_folder_ids.includes(idFolder)
		);
		setFilteredData(data);
	}, [userRoutes, idFolder]);

	const moveUserRoute = async (user_route_id, urf_id) => {
		Swal.fire({
			title: "Are you sure? ",
			text: "Do you want to move this route?",
			background: "#121A1F",
			showCancelButton: true,
			showDenyButton: false,
			reverseButtons: true,
			cancelButtonText: "CANCEL",
			confirmButtonText: "MOVE",
		}).then(async (result) => {
			if (!result) {
				return;
			}
			const response = await requestAddUserRouteInUserRouteFolder(
				user_token,
				{ user_route_id, urf_id }
			);
			Swal.fire({
				background: "#121A1F",
				title: "Wait a minute",
				text: response.message,
				showConfirmButton: false,
				timer: 2500,
				width: "305px",
			});
			if (response.state != 200) {
				return;
			}
			setLoading(true);
		});
	};

	const deleteRoute = async (user_route_id) => {
		Swal.fire({
			title: "Are you sure? ",
			text: "Do you want to remove this route?",
			background: "#121A1F",
			showCancelButton: true,
			showDenyButton: false,
			reverseButtons: true,
			cancelButtonText: "CANCEL",
			confirmButtonText: "REMOVE",
		}).then(async (result) => {
			if (!result) {
				return;
			}
			const response = await requestDeleteUserRoute(
				user_route_id,
				user_token
			);
			Swal.fire({
				background: "#121A1F",
				title: "Wait a minute",
				text: response.message,
				showConfirmButton: false,
				timer: 2500,
				width: "305px",
			});
			if (response.state != 200) {
				return;
			}
			setLoading(true);
		});
	};
	return (
		<>
			<div className="divider-3"></div>
			<DesignedRoutesBar idFolder={idFolder} setIdFolder={setIdFolder} />
			{userRoutes?.length == 0 ? (
				<p className="fw-bold text-center text-white">
					Routes not found
				</p>
			) : (
				filteredData.map((route) => (
					<RouteItem
						data={route}
						actionDelete={deleteRoute}
						addUserRoute={moveUserRoute}
					/>
				))
			)}
		</>
	);
};

export default DesignedRoutes;
