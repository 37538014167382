let API_URL = process.env.REACT_APP_API_AUTH_DEV;

const requestEditNotificationRead = async (user_token, ids) => {

  let response;

  await fetch(`${API_URL}edit-notification`, {
    method: "POST",
    // mode: 'no-cors',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user_token,

      // "Accept-Encoding": "gzip, deflate, br"
    },
    body: JSON.stringify({ids}),
  })
    .then((response) => response.json())
    .then((data) => {
      response = data;
    })
    .catch((error) => {
      response = {
        state: 500,
        data: null,
        error,
        message: "Connection error, try again later",
      };
    });
  return response;
};

export default requestEditNotificationRead;
