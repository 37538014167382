import React, { useEffect, useState} from 'react';
import { FiHelpCircle } from "react-icons/fi";
import { Tab } from 'react-bootstrap';
import Swal from 'sweetalert2';
import IndicatorsBar from './IndicatorsBar';
import Layout from '../../components/layout/Layout';
import SidebarIndicators from './SidebarIndicators';
import { ProtectedRoute } from '../../app/ProtectedRoute';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectGuardianState } from '../../redux/auth/GuardianSlice';
import { deleteIndicatorsList, deleteSelectedIndicators, selectExternalAction, setFromExternalAction, setIndicatorsList, setSelectedIndicators } from '../../redux/indicators/IndicatorsSlice';
import requestIndicatorCategory from '../../services/indicators/indicators_folders/requestIndicatorCategory';
import requestCreateIndicatorCategory from '../../services/indicators/indicators_folders/requestCreateIndicatorCategory';
import requestDeleteIndicatorCategory from '../../services/indicators/indicators_folders/requestDeleteIndicatorCategory';
import requestEditIndicatorCategory from '../../services/indicators/indicators_folders/requestEditIndicatorCategory';
import requestGetIndicators from '../../services/indicators/requestGetIndicators';
import requestAddIndicatorToCat from '../../services/indicators/requestAddIndicatorToCat';
import requestRemoveIndicator from '../../services/indicators/requestRemoveIndicator';
import { addCategory, deleteCategory, selectCategoryState, setCategory, updateCategory } from '../../redux/indicators/CategoriesSlice';
import GraphCard from "../../components/GraphCard";
import { useParameter } from '../../hooks/useParameter';
import CustomToolTip from '../../components/layout/CustomToolTip';


const Indicators = ({ routes }) => {

  //By Router
  let history = useHistory();

  //Redux
  const dispatch = useDispatch();
  const extAction = useSelector(selectExternalAction);
  const authCheck = useSelector(selectGuardianState);
  const category_list = useSelector(selectCategoryState);

  //parameter
  const indicator_frequency = useParameter("indicator_frequency");
  const conditional_indicators = useParameter("conditional_indicators");

  //States
  const [showBtnCompare, setShowBtnCompare] = useState(false);
  const [showBtnMerge, setShowBtnMerge] = useState(false);
  const [showChecks, setShowChecks] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [checkList, setCheckList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [arrIndCategories, setArrIndCategories] = useState([]);
  const [keyTab, setKeyTab] = useState();


  /**
   * When the button is clicked, the state of the button is changed to the opposite of what it was
   * before the click.
   */
  const buttonCompare = () => {
    dispatch(setFromExternalAction(0));
    setShowBtnCompare(!showBtnCompare);
    setShowBtnMerge(false);
  }

  /**
   * When the button is clicked, the state is set to 0, the button is toggled, and the other button is
   * hidden.
   */
  const buttonMerge = () => {
    dispatch(setFromExternalAction(0));
    setShowBtnMerge(!showBtnMerge);
    setShowBtnCompare(false);
  }

  /* A useEffect hook that is triggered when the showBtnMerge, showBtnCompare, or extAction state
  changes. */
  useEffect(() => {
    if (showBtnMerge || showBtnCompare) {
      setShowChecks(true);
    }
    if (!showBtnMerge && !showBtnCompare) {
      setShowChecks(false);
      if (extAction === 1) {
        setShowChecks(true);
        setShowBtnMerge(true);
      } else if (extAction === 2) {
        setShowChecks(true);
        setShowBtnCompare(true);
      } else {
        setShowChecks(false);
        setShowBtnMerge(false);
        setShowBtnCompare(false);

      }
    }
  }, [showBtnMerge, showBtnCompare, extAction]);

  /**
   * It fetches data from an API and then sets the state of the data to the data fetched from the API.
   */
  const fetchingData = async () => {
    try {
      const res_categories = await requestIndicatorCategory(authCheck);
      if (res_categories.state === 200) {
        const el = res_categories?.data[0];
        setIsLoading(false);
        fetchingDataByIndicator(el.indicator_category_id);
        return dispatch(setCategory(res_categories.data));
      }
    } catch (error) {
      console.log(false);
    }
  }

  /**
   * I'm going to fetch some data from an API, and if the response is 200, I'm going to set the state
   * of my component to the data I got from the API.
   * @returns the setArrIndCategories function.
   */
  const fetchingDataByIndicator = async (id) => {
    dispatch(deleteIndicatorsList());
    const res_indicators_by_cat = await requestGetIndicators(authCheck, [], id);
    // console.log(res_indicators_by_cat);
    if (res_indicators_by_cat.state === 200) {
      dispatch(setIndicatorsList(res_indicators_by_cat.data));
      return setArrIndCategories(res_indicators_by_cat.data);
    } else {
      dispatch(deleteIndicatorsList(res_indicators_by_cat.data));
      return setArrIndCategories(res_indicators_by_cat.data);
    }
  };

  useEffect(() => {
    if (category_list.length === 0) {
      fetchingData();
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * It takes an idIndicator as an argument and pushes the idIndicator to the end of the URL
   */
  const getDetails = (idIndicator) => history.push("/indicators/" + idIndicator);

  /**
   * If the checkbox is checked, add the value to the array. If the checkbox is unchecked, remove the
   * value from the array.
   */
  const handleChange = (e, item) => {

    if (checkList.length >= 6) {
      let arrTemp = checkList;
      arrTemp.shift();
      setCheckList(arrTemp);
    }
    if (e.target.checked === true) {
      setCheckList([...checkList, Number(e.target.value)]);
      dispatch(setSelectedIndicators(item));
    } else {
      const selectedAcc = checkList.filter((a) => {
        if (a === Number(e.target.value)) return false;
        return true;
      });
      setCheckList([...selectedAcc]);
      dispatch(deleteSelectedIndicators(item));
    }
  }


  /**
   * When the user clicks on a tab, the function will set the state of the keyTab to the item that was
   * clicked on, and then it will fetch data from the API based on the indicator_category_id of the
   * item that was clicked on.
   */
  const getTitle = (e, item) => {
    setKeyTab(item);
    fetchingDataByIndicator(item.indicator_category_id);
  }

  /**
   * It sets the state of the showModalAdd variable to the opposite of what it currently is
   */
  const showCreate = () => setShowModalAdd(!showModalAdd);

  /**
   * It creates a category, and if the response is 200, it dispatches an action to add the category.
   */
  const createCategory = async (data) => {
    try {
      const res_create = await requestCreateIndicatorCategory(data, authCheck);
      if (res_create.state === 200) {
        dispatch(addCategory(res_create.data));
        setKeyTab(res_create.data);
      } else if (res_create.state === 404) {
        dispatch(deleteIndicatorsList());
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * It's an async function that makes an API call to update a category.
   */
  const editCategory = async (data) => {
    try {
      const res_edit = await requestEditIndicatorCategory(authCheck, data, keyTab.indicator_category_id);
      if (res_edit.state === 200) {
        // setIsLoading(false);
        dispatch(updateCategory(res_edit.data));
      }
    } catch (error) {
      console.log(error);
    }
  }


  /**
   * When the user clicks the delete button, a modal pops up asking the user if they want to delete the
   * folder. If the user clicks the delete button, the folder is deleted.
   */
  const deleteToCategory = () => {
    Swal.fire({
      title: 'Are you sure? ',
      text: "Do you want to remove this folder?",
      background: '#121A1F',
      showCancelButton: true,
      showDenyButton: false,
      reverseButtons: true,
      cancelButtonText: 'CANCEL',
      confirmButtonText: 'REMOVE',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res_delete = await requestDeleteIndicatorCategory(authCheck, keyTab.indicator_category_id);
          if (res_delete.state === 200) {
            dispatch(deleteCategory(keyTab.indicator_category_id));
          }
        } catch (error) {
          console.log(error);
        }
        fetchingData();
      }
    })
  };

  /**
   * It takes a dataForm, makes a request to the server, and then fetches the data again.
   */
  const addIndicatorToCat = async (dataForm) => {
    try {
      let formData = { ...dataForm }

      const res = await requestAddIndicatorToCat(authCheck, formData);
      if (res.state === 200) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(false);
    }
    fetchingData();
  }

  /**
   * It takes a dataForm object, makes a copy of it, and then sends it to a function called
   * requestRemoveIndicator.
   */
  const removeIndicator = async (dataForm) => {
    try {
      let formData = { ...dataForm };
      const res = await requestRemoveIndicator(authCheck, formData);
      if (res.state === 200) {
        console.log(res.data);
      }
    } catch (error) {
      console.log(false);
    }
    fetchingData();
  }

  /**
   * The function activeTabs takes an argument called active and sets the state of keyTab to the value
   * of active.
   */
  const activeTabs = (active) => {
    setKeyTab(active);
  }


  return (
    <Layout >
      <ProtectedRoute path={routes?.path}
        routes={routes?.Routes}
        exact={true}>
        <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4 content-main-margin">
          <h1 className="heading-h1 fw-bold">
               View indicators
              <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={"View indicators"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
            </h1>
          <p className="subheading-top">Here, you can view, analyze and compare data</p>
          <Tab.Container
            transition={false}
            activeKey={keyTab?.indicator_category_id ?? category_list[0]?.indicator_category_id}
          >
            <IndicatorsBar
              getTitle={getTitle}
              setSectionTitle={keyTab ? keyTab : category_list[0]}
              settingItem={category_list}
              category={keyTab}
              createCategory={createCategory}
              showCreate={showCreate}
              showModalAdd={showModalAdd}
              editCategory={editCategory}
              deleteCategory={deleteToCategory} />
            <Tab.Content>
              {category_list.map((i, index) =>
                <Tab.Pane eventKey={i.indicator_category_id} key={index} transition={false}>
                  <div className="d-flex flex-row flex-wrap">
                    <div className="col-md-11 mt-2 mb-5 d-grid gap-3">
                      <div className="col-md-12 grid-layout content-grid-width">
                        {arrIndCategories.map((item) =>
                          <GraphCard
                            showChecks={showChecks}
                            actionSave={addIndicatorToCat}
                            actionRemove={removeIndicator}
                            isChecked={checkList.includes(item.indicator_id)}
                            actionKey={activeTabs}
                            item={item}
                            btnIcon={true}
                            widthCard={'auto'}
                            classItem={'box'}
                            classItemElement={'box-active'}
                            key={item.indicator_id}
                            goTo={getDetails}
                            handleChange={handleChange}
                            checkList={checkList} folderList={category_list} category={keyTab}
                            indicator_frequency={indicator_frequency}
                            conditional_indicators={conditional_indicators} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-1 mt-2 mb-1 d-flex flex-row justify-content-end">
                      <SidebarIndicators btnCompare={showBtnCompare} btnMerge={showBtnMerge} buttonCompare={buttonCompare} buttonMerge={buttonMerge} arrToCompare={checkList} />
                    </div>
                  </div>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
      </ProtectedRoute>
      {routes.Routes.map((item, index) =>
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact} />
      )}
    </Layout>
  )
}

export default Indicators