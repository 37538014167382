import React, { useState } from 'react';
import { FiFilter } from "react-icons/fi";
import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';

const ResultsBar = (props) => {
    const { setShowFilter = false, showFilter = false } = props;
    const scrollHorizontalRef = useHorizontalScroll();

    return (
        <div className='setting' >
            <div className='settingResults'>
                <span className='btnSettings'><span className='fw-bold'>Results</span> - Recommended</span>
            </div>
            <div className='settingBar' ref={scrollHorizontalRef}>
                <div className='sectionSettings' >
                    <button className='btnRecommended'>Recommended</button>
                </div>
                <div className='sectionSettings'>
                    <button className='btnSettings'>Max profit</button>
                </div>
                <div className='sectionSettings'>
                    <button className='btnSettings'>Max income</button>
                </div>
                <div className='sectionSettings'>
                    <button className='btnSettings'>Minor mileage</button>
                </div>
                <div className='sectionSettings'>
                    <button className='btnSettings'>Max environment efficiency</button>
                </div>
            </div>
            <button className='btnBar' onClick={() => setShowFilter(!showFilter)}>Filter results
                <FiFilter className='icon-h1' />
            </button>
        </div>
    )
}

export default ResultsBar