const formatRouteData = (data) => {
	let user_route = {
		user_route_name: data?.user_route_name,
		user_route_departure: data?.route_departure,
		user_route_destination: data?.route_destination,
		user_route_miles: data?.route_miles,
		user_route_fuel: data?.route_fuel,
		user_route_driving_time: data?.route_driving_time,
		user_route_operative_time: data?.route_operative_time,
		user_route_days: data?.route_days,
		user_route_co2_emission: data?.route_co2_emission,
		user_route_loading_chances_24h: data?.route_loading_chances_24h,
		user_route_loading_chances_48h: data?.route_loading_chances_48h,
		user_route_ideal_connection: data?.route_ideal_connection,
		user_route_connectivity_index: data?.route_connectivity_index,
		user_route_min_income: data?.route_min_income,
		user_route_avg_income: data?.route_avg_income,
		user_route_max_income: data?.route_max_income,
		user_route_fuel_cost: data?.route_fuel_cost,
		user_route_total_variable_cost: data?.route_total_variable_cost,
		user_route_min_profit: data?.route_min_profit,
		user_route_avg_profit: data?.route_avg_profit,
		user_route_max_profit: data?.route_max_profit,
	};
	let user_lanes = [];
	data.get_lanes.map((e) =>
		user_lanes.push({
			user_lane_id: e?.lane_id,
			user_lane_departure: e?.lane_departure,
			user_lane_departure_city: e?.lane_departure_city,
			user_lane_departure_state: e?.lane_departure_state,
			user_lane_departure_place_id: e?.lane_departure_place_id,
			user_lane_destination: e?.lane_destination,
			user_lane_destination_city: e?.lane_destination_city,
			user_lane_destination_state: e?.lane_destination_state,
			user_lane_destination_place_id: e?.lane_destination_place_id,
			user_lane_miles: e?.lane_miles,
			user_lane_trip_type: e?.lane_trip_type,
			user_lane_trip_type_name: e?.lane_trip_type_name,
			user_lane_load_size: e?.lane_load_size,
			user_lane_load_size_name: e?.lane_load_size_name,
			user_lane_driving_time: e?.lane_driving_time,
			user_lane_operative_time: e?.lane_operative_time,
			user_lane_loading_chances_24h: e?.lane_loading_chances_24h,
			user_lane_loading_chances_48h: e?.lane_loading_chances_48h,
			user_lane_connectivity_index: e?.lane_connectivity_index,
			user_lane_ideal_connection: e?.lane_ideal_connection,
			user_lane_co2_emission: e?.lane_co2_emission,
			user_lane_days: e?.lane_days,
			user_lane_fuel_gal: e?.lane_fuel_gal,
			user_lane_min_income: e?.lane_min_income,
			user_lane_avg_income: e?.lane_avg_income,
			user_lane_max_income: e?.lane_max_income,
			user_lane_fuel_cost: e?.lane_fuel_cost,
			user_lane_total_variable_cost: e?.lane_sub_total_variable_cost,
			user_lane_min_profit: e?.lane_min_profit,
			user_lane_avg_profit: e?.lane_avg_profit,
			user_lane_max_profit: e?.lane_max_profit,
			user_lane_arriving_load: e?.lane_arriving_load,
			user_lane_unloaded: e?.lane_unloaded,
			user_lane_action: e?.lane_action,
		})
	);
	return JSON.stringify({ user_route, user_lanes });
};
export default formatRouteData;
