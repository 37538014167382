/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import requestGetParameters from "../services/parameters/requestGetParameters";

export const useParameter = (reference) => {
  const [parameters, setParameters] = useState([]);

  const getData = async () => {
    const response = await requestGetParameters(reference);
    const data = response.state == 200 ? response.data : [];
    setParameters(data);
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return parameters;
};
