import React from 'react';
import Modal from 'react-bootstrap/Modal';

const CustomModal = ({ showCustomModal, setShowCustomMOdal, dialogClass, classModal, titleModal, children }) => {
    const handleClose = () => setShowCustomMOdal(false);
    return (
        <Modal show={showCustomModal} onHide={handleClose}
            centered
            dialogClassName={dialogClass}
            className={classModal} >
            <Modal.Header>
                <Modal.Title>
                    <span className='w-100'>{titleModal}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal >
    )
}

export default CustomModal