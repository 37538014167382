import React, { useState } from "react";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
import { FiChevronsLeft, FiSettings, FiHelpCircle, FiLogOut, FiChevronsRight } from "react-icons/fi";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { signedOut } from "../../redux/auth/GuardianSlice";
import logo from "../../app/images/90466.svg";
import CustomLink from "../CustomLink";
import SidebarParentItem from "./SidebarParentItem";
import { deleteUserData } from "../../redux/user/UserSlice";
import Routes from '../../Routes';
import { useCookies } from "../../hooks/useCookies";
import { setTwilioToken } from "../../redux/inbox/InboxSlice";
import auth from "../../auth";


const Sidebar = ({ toggle, active }) => {
    const [routes, setRoutes] = useState(Routes);
    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    // const sidebarRoutes =
  const {deleteCookie} = useCookies('tokenCounter');


    function disp() {
        dispatch(signedOut());
        dispatch(deleteUserData());
        dispatch(setTwilioToken());
        deleteCookie();
    }
    const logout = () => {
        let { from } = location.state || { from: { pathname: "/" } };
        Swal.fire({
            title: 'Do you want to Logout?',
            background: '#121A1F',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'CONFIRM',
            cancelButtonText: 'CANCEL',
        }).then((result) => {
            if (result.isConfirmed) {
                // localStorage.removeItem('user_token');
                disp();
                setTimeout(async () => {
                    await auth.signOut();
                }, 400)
            }
        })

    };
    return (
        <nav id="sidebarMenu" className={!active ? "d-md-block sidebar collapse" : "d-md-block sidebar collapse visible"}>
            <div className="d-flex flex-row flex-wrap px-3 justify-content-center align-items-center content-sidebar-logo">
                    {!active ? <div className="img-sidebar"></div> :
                    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.20224 19.5568C4.09799 19.5976 3.00355 19.3361 2.03707 18.8004C1.20441 18.3117 0.582361 17.5318 0.291047 16.6113C-0.039884 15.4853 -0.0894525 14.2953 0.146683 13.1456C0.181186 12.9713 0.224766 12.7498 0.277428 12.4801C0.315902 12.265 0.368377 12.0527 0.43451 11.8445C0.743755 10.6192 1.29309 9.46744 2.05069 8.45597C2.75401 7.52105 3.6692 6.76641 4.72102 6.25413C5.80629 5.73279 6.99736 5.46929 8.20126 5.48418C9.37689 5.42121 10.5429 5.7262 11.5371 6.35674C12.3552 6.94188 12.9338 7.80335 13.166 8.78194C13.4372 9.96031 13.4419 11.1844 13.1797 12.3648L13.0489 12.9095C13.0136 13.0847 12.917 13.2414 12.7765 13.3517C12.6345 13.4716 12.4539 13.5361 12.268 13.5333H3.61421C3.60571 13.6048 3.58832 13.675 3.56245 13.7421C3.47472 14.2998 3.48393 14.8683 3.58969 15.4228C3.67397 15.9017 3.91705 16.3383 4.27975 16.6622C4.67846 16.9894 5.18469 17.1564 5.69981 17.1307C6.13583 17.1395 6.5695 17.0646 6.97732 16.9101C7.301 16.7861 7.60429 16.6144 7.87711 16.4007C8.06284 16.2539 8.24075 16.0976 8.41009 15.9322C8.54511 15.7814 8.70401 15.6537 8.88042 15.5545C9.0226 15.5046 9.17299 15.4824 9.32351 15.4891H11.5934C11.6619 15.4862 11.7301 15.4988 11.793 15.526C11.8559 15.5532 11.9119 15.5943 11.9566 15.6462C12.0001 15.7004 12.0298 15.7642 12.0434 15.8323C12.057 15.9004 12.054 15.9708 12.0347 16.0375C11.8858 16.4158 11.6643 16.7612 11.3828 17.0544C10.9697 17.508 10.4962 17.9024 9.97543 18.2266C9.32247 18.6374 8.61652 18.9572 7.87711 19.1772C7.0099 19.4387 6.10795 19.5667 5.20224 19.5568ZM4.08181 11.346H9.84377L9.86829 11.2679C10.0394 10.6918 10.0619 10.0818 9.93366 9.49467C9.82123 9.01926 9.54993 8.59647 9.16461 8.29617C8.74195 7.98782 8.22734 7.83164 7.70459 7.85308C7.12753 7.84588 6.55993 7.99996 6.06572 8.29797C5.5724 8.60047 5.15346 9.0101 4.83996 9.4965C4.49441 10.0397 4.24845 10.6402 4.1136 11.2698L4.08181 11.346Z" fill="#E6E6E6" />
                        <path d="M15.9423 19.2924C15.8625 19.2999 15.7821 19.2877 15.708 19.2571C15.6339 19.2265 15.5684 19.1784 15.5171 19.1168C15.4658 19.0552 15.4302 18.982 15.4135 18.9037C15.3968 18.8253 15.3994 18.744 15.4211 18.6668L17.5848 8.49761H15.4729C15.3958 8.50177 15.3188 8.48744 15.2484 8.45586C15.178 8.42427 15.1161 8.37633 15.0679 8.31601C15.0202 8.25388 14.9872 8.18169 14.9715 8.10492C14.9557 8.02816 14.9577 7.94882 14.9771 7.87292L15.2904 6.36117C15.3259 6.18589 15.4224 6.02887 15.5628 5.91808C15.7047 5.79872 15.8848 5.73427 16.0703 5.73647H18.1586L18.3938 4.66779C18.5714 3.7337 18.949 2.84909 19.5006 2.07465C20.0016 1.39713 20.6712 0.862487 21.4428 0.523833C22.3076 0.159756 23.2394 -0.0181136 24.1776 0.00176795H26.2659C26.345 -0.00506137 26.4245 0.00796171 26.4973 0.0396738C26.5701 0.0713859 26.6338 0.120766 26.6826 0.183345C26.7266 0.24731 26.7564 0.319927 26.77 0.396344C26.7837 0.472761 26.7808 0.551219 26.7616 0.626439L26.4484 2.13821C26.4125 2.31332 26.316 2.47019 26.176 2.58131C26.042 2.69721 25.871 2.76158 25.6939 2.76288H24.1303C23.5451 2.72601 22.9676 2.91224 22.5142 3.28406C22.1012 3.6899 21.8353 4.2219 21.7587 4.79583L21.5499 5.73376H24.7823C24.8613 5.72701 24.9409 5.74007 25.0136 5.77177C25.0864 5.80348 25.1501 5.85284 25.199 5.91536C25.2425 5.97957 25.2719 6.0522 25.2853 6.12855C25.2988 6.2049 25.296 6.28323 25.2771 6.35843L24.9648 7.8702C24.9302 8.0432 24.838 8.19938 24.7033 8.31329C24.5669 8.43365 24.3903 8.49844 24.2084 8.49487H20.9761L18.787 18.6641C18.7525 18.8369 18.6606 18.9931 18.5264 19.1072C18.398 19.2241 18.2306 19.2888 18.0569 19.2888L15.9423 19.2924Z" fill="#E6E6E6" />
                    </svg>}

                <button className="btn-clean text-white btnFloat" onClick={() => toggle()}>
                    {active ? <FiChevronsRight /> : <FiChevronsLeft />}
                </button>
            </div>
            <div className="divider"></div>
            <div className="position-sticky pt-3 sidebar-sticky d-flex flex-column justify-content-between">
                <ul className="nav flex-column">
                    {routes.map((item, index) => item.icon &&
                        (item.Routes.length === 0 ?
                        <li className="nav-item" key={index}>
                            <CustomLink
                                activeOnlyWhenExact={index === 0 ? true : false}
                                to={item.path}
                                label={active ? '' : item.name}
                                icon={item.icon}
                                isToggle={active}
                            />
                        </li>
                        :
                        <SidebarParentItem item={item} active={active} key={index} />)
                    )}
                </ul>
                <ul className="nav flex-row flex-wrap align-item-center">
                    <li className="nav-item w-100">
                        <NavLink to="/settings/account" className={active ? "nav-link d-flex flex-row flex-wrap align-items-center justify-content-center ps-4" : "nav-link d-flex flex-row flex-wrap align-items-center ps-4"}
                             activeClassName="active"
                        >
                            <FiSettings className={active ? "align-text-bottom icon-sidebar" : "align-text-bottom icon-sidebar me-3"} />
                            {active ? '' : <span className="text-item-sidebar">Settings</span>}
                        </NavLink>
                    </li>
                    <li className="nav-item w-100">
                        <NavLink to="/help" className={active ? "nav-link d-flex flex-row flex-wrap align-items-center justify-content-center ps-4" : "nav-link d-flex flex-row flex-wrap align-items-center ps-4"}
                            activeClassName="active"
                        >
                            <FiHelpCircle className={active ? "align-text-bottom icon-sidebar" : "align-text-bottom icon-sidebar me-3"} />
                            {active ? '' : <span className="text-item-sidebar">Help</span>}
                        </NavLink>
                    </li>
                </ul>

            </div>
            <div className="divider my-2"></div>
            <ul className={active ? "nav flex-row flex-wrap align-item-center justify-content-center" : "nav flex-row flex-wrap align-item-center"}>
                <li className="nav-item">
                    <Link to="#" onClick={logout} className={active ? "nav-link d-flex flex-row flex-wrap align-items-center justify-content-center ps-4" : "nav-link d-flex flex-row flex-wrap align-items-center ps-4"}>
                        <FiLogOut className={active ? "align-text-bottom icon-sidebar" : "align-text-bottom icon-sidebar me-3"} />
                        {active ? '' : <span className="text-item-sidebar">Log out</span>}
                    </Link>
                </li>
            </ul>

        </nav>
    );
};

export default Sidebar;
