import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

const CustomLink = ({ label, to, activeOnlyWhenExact, icon, isToggle }) => {
    let match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });
    return (<div className={match ? "nav-link active ps-4" : "nav-link ps-4"}>
        {to ?
            <Link to={to}>
                <div className={isToggle ? styles.contentDivToggle : styles.contentDivTwo}>
                    {icon}
                    <span className="text-item-sidebar">{label}</span>
                </div>
            </Link>

            : <div className={isToggle ? styles.contentDivToggle : styles.contentDivTwo}>
                {icon}
                <span className="text-item-sidebar">{label}</span>
            </div>}
    </div>);
}

const styles = {
    contentDivToggle: 'd-flex flex-row flex-wrap align-items-center justify-content-center',
    contentDiv: 'd-flex flex-row flex-wrap align-item-center'
}

export default CustomLink;