import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FiBell, FiCircle, FiMoreHorizontal } from "react-icons/fi";
import OutsideAlerter from "./OutsideAlerter";

const DropdownNotifications = ({ data, actionEdit}) => {

  const [show, setShow] = useState(false);
  const openSwitch = () => {setShow(false)};


  let array = [];
  const arr = data.forEach(element => {
     let obj = {notificationId: element?.notifications_id}
     array.push(obj);
  });

  // console.log(array);

  return (
    <DropdownButton drop={"start"} title={<FiBell className="iconNavbar mt-2" />}>
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center itemNavbar w-100">
        <div>
          <label className="letterResults fw-bold">Notifications</label>
        </div>
        <div>  
          <FiMoreHorizontal onClick={() => setShow(!show)} className="icon-sidebar" />
          {show &&
            <OutsideAlerter classNameElement={"box-drop"} position={'absolute'} top={'2rem'} left={'12.5rem'} listener={show} trigger={openSwitch}>
                <ul>
                    <li onClick={() => actionEdit(array)}>Mark all as read</li>
                </ul>
            </OutsideAlerter>
        }
        </div>
      </div>
      {data.map((item, index) => (
        <Dropdown.Item key={index} as={"div"} className="dropdown-item">
          <div className="d-flex flex-row flex-wrap">
            {item?.notifications_read === 0 ? <FiCircle className="icon-circle-notification mt-1 me-3" /> : ''}
            <div className="d-flex flex-column">
              <label>{item?.notifications_title ?? 'Title'}</label>
              <label className="text-sett">{item?.notifications_data?.date ?? 'Date'}</label>
            </div>
          </div>
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DropdownNotifications;
