import React from 'react';
import { FiHelpCircle } from "react-icons/fi";
import Layout from '../../components/layout/Layout';
import GeneralTabs from '../../components/GeneralTabs';
import InfoAccount from './Tabs/Account/InfoAccount';
import Notifications from './Tabs/Notifications/Notifications';
import Preferences from './Tabs/Preferences/Preferences';
import DefaultSetting from './Tabs/DefaultSetting/DefaultSetting';
import Payment from './Tabs/Payment/Payment';
import { ProtectedRoute } from '../../app/ProtectedRoute';
import CustomToolTip from '../../components/layout/CustomToolTip';

const SettingsIndex = ({ routes }) => {

  const infoTabs = {
    "account": {
      id: 1,
      title: 'Account',
      child: <InfoAccount routes={routes} />
    },
    "notifications": {
      id: 2,
      title: 'Notifications',
      child: <Notifications />
    },
    "default_settings": {
      id: 3,
      title: 'Default settings',
      child: <DefaultSetting routes={routes} />
    },
    "preferences": {
      id: 4,
      title: 'Preferences',
      child: <Preferences />
    },
    "payment": {
      id: 5,
      title: 'Payment',
      child: <Payment />
    },
  };

  return (
    <>
      <ProtectedRoute path={routes?.path}
        routes={routes?.Routes}
        exact={true}>
        <Layout>
          <div className="d-flex flex-column flex-wrap flex-md-nowrap pt-3 pb-2 px-md-4">
            <h1 className="heading-h1 fw-bold">
              Admin. your routes
              <CustomToolTip
                classTooltip={"tooltip-content"}
                classText={"text-contentTooltip"}
                width={"421px"}
                height={"250px"}
                icon={<FiHelpCircle className="text-tooltip me-2"  />}
                title={" Admin. your routes"}
                content={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi consectetur adipiscing elit."
                }
                direction="left"
              >
                <FiHelpCircle className="icon-h1 ms-2" />
              </CustomToolTip>
            </h1>
            <p className="subheading-top">Here, you can administrate your routes</p>
            <GeneralTabs data={infoTabs} dataRoute={routes} route={'settings'} icon={false} />
          </div>
        </Layout>
      </ProtectedRoute>
      {routes.Routes.map((item, index) =>
        <ProtectedRoute
          path={item.path}
          routes={item}
          component={item.Component}
          key={index}
          exact={item.isExact} />
      )}
    </>
  )
}

export default SettingsIndex