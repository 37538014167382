import React from 'react'
import { FiAlertCircle, FiX } from "react-icons/fi";


const Advertisement = () => {
    return (
        <div className="card-advertisement">
            <div className="px-5" style={{padding: '2.5rem 0'}}>
                <div>
                    <FiX className="close-icon-advertisement" />
                </div>
                <h2 className='heading-advertisement'>Plan your route  <FiAlertCircle className="info-icon-advertisement" /></h2>
                <p className="subheading-advertisement">Go to efData and find some trips</p>
                <div className='mt-4'>
                    <button className="btn btn-advertisement">FIND ROUTES</button>
                </div>
            </div>
            {/* <div className="float-ellipse"></div> */}
        </div>
    );
}

export default Advertisement;