import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    category_list: [],
};
export const CategoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setCategory: (state, action) => {
            state.category_list = action.payload;
        },

        addCategory: (state, action) => {
            state.category_list = [...state.category_list, action.payload];
        },


        updateCategory: (state, action) => {
            let arr = state.category_list;
            let objIndex = arr.findIndex((obj => obj.id === action.payload.id));
            arr[objIndex] = action.payload;
            state.category_list = arr;
        },

        deleteCategory: (state, action) => {
            const filtered = state.category_list.filter(function (el) { return el.id !== action.payload.id; });
            console.log(filtered);
            state.category_list = filtered;
        },
    },
});

export const { setCategory, updateCategory, deleteCategory, addCategory } = CategoriesSlice.actions;
export const selectCategoryState = (state) => state.categories.category_list;


export default CategoriesSlice.reducer;
