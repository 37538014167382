import React from "react";
import imgEmpty from "../../../../assets/images/empty.svg";

const BookRoutes = () => {
  return (
    <>
      <div className="divider-3"></div>

      <div className="card">
        <div className="card-body">
          <div className="viewer-content">
            <div className="d-flex flex-column flex-wrap align-items-center justify-content-center h-100">
              <img src={imgEmpty} alt="" />
              <h1 className="heading-h1 fw-bold my-3">Section not available</h1>
              <p className="subheading-top text-center">
                If you want to dispatch your own routes, purchase dispatch plans
              </p>
              <button type="button" className="btn btn-success" style={{width: '396px'}}>GET DISPATCH PLANS</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookRoutes;
