import React, { useState } from "react";

const CustomToolTip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"} ${props.classTooltip} d-flex justify-content-center`} style={{width: props?.width, height: props?.height}}>
          <div className="d-flex flex-row flex-wrap w-100">
            {props.classTooltip &&
            <div className="col-md-12 d-flex flex-row">
              {props.icon}
              <h4 className="text-tooltip">{props.title}</h4>
            </div>}
            <div className="col-md-12">
              <label className={` ${props.classText}`}>{props.content}</label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomToolTip;
