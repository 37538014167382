import React, { useState } from "react";
import { Collapse, Dropdown, DropdownButton } from "react-bootstrap";
import CustomToolTip from "../../../components/layout/CustomToolTip";

const OperativeRouteAnalysisTable = (props) => {
  const {
    data,
    defaultData,
    totals,
    changeField,
    collapsed = false,
    dataIndicators = false,
  } = props;

  let lane_departure_city = [];
  let lane_destination_city = [];
  let lane_loading_chances_24h = [];
  let lane_loading_chances_48h = [];
  let lane_connectivity_index = [];
  let lane_ideal_connection = [];
  let custom = [];
  let custom_two = [];

  data?.get_lanes.forEach((element) => {
    lane_departure_city.push(element.lane_departure_city);
    lane_destination_city.push(element.lane_destination_city);
    lane_loading_chances_24h.push(element.lane_loading_chances_24h);
    lane_loading_chances_48h.push(element.lane_loading_chances_48h);
    lane_connectivity_index.push(element.lane_connectivity_index);
    lane_ideal_connection.push(element.lane_ideal_connection);
    custom.push(element.custom);
    custom_two.push(element.custom_two);
  });

  const theadData = [
    {
      id: 1,
      header: "Origin",
    },
    {
      id: 2,
      header: "Destination",
    },
    {
      id: 3,
      header: "24 hrs loading chances",
    },
    {
      id: 4,
      header: "48 hrs loading chances",
    },
    {
      id: 5,
      header: "Connectivity index",
    },
    {
      id: 6,
      header: "Ideal connection",
    },
    {
      id: 7,
      header: "Custom",
    },
    {
      id: 8,
      header: "Custom",
    },
  ];

  const tbodyData = [
    {
      id: 1,
      name: lane_departure_city,
    },
    {
      id: 2,
      name: lane_destination_city,
    },
    {
      id: 3,
      name: lane_loading_chances_24h,
    },
    {
      id: 4,
      name: lane_loading_chances_48h,
    },
    {
      id: 5,
      name: lane_connectivity_index,
    },
    {
      id: 6,
      name: lane_ideal_connection,
    },
    {
      id: 7,
      name: custom,
    },
    {
      id: 8,
      name: custom_two,
    },
  ];

 /* Setting the state of selectedHeaders to the header of theadData. */
  const [selectedHeaders, setSelectedHeaders] = useState(
    theadData.map((column) => column.header)
  );


/**
 * This function updates the selected headers array with a new value at a specific index.
 */
  const handleSelectChange = (index, eventKey) => {
    const newSelectedHeaders = [...selectedHeaders];
    newSelectedHeaders[index] = eventKey;
    setSelectedHeaders(newSelectedHeaders);
  };

/* Mapping the selectedHeaders array to the theadData array. */
  const selectedColumns = selectedHeaders.map((header) =>
    theadData.find((column) => column.header === header)
  );

  return (
    <Collapse in={collapsed}>
      <div id="one">
        <p className="route-name fw-bold mb-2">Route description</p>
        <table className="table table-sm table-bordered border-dark input-table-1">
          <thead>
            <tr className="text-thead fw-bold">
              <th scope="col">Origin</th>
              <th scope="col">Destination</th>
              <th scope="col">Miles</th>
              <th scope="col">Fuel</th>
              <th scope="col">Trip Type</th>
              <th scope="col">Driving Time</th>
              <th scope="col">Operative Time</th>
              <th scope="col">Emissions Co2</th>
              <th scope="col">Size/Weight</th>
            </tr>
          </thead>
          <tbody>
            {data?.get_lanes.map((i, index) => (
              <tr className="text-tbody" key={index}>
                <td>
                  <CustomToolTip
                    content={i?.lane_departure_city ?? "---"}
                    direction="top"
                  >
                    <input
                      disabled
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      value={i?.lane_departure_city ?? ""}
                      name={`route_description.${index}.lane_departure_city`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip
                    content={i?.lane_destination_city ?? "---"}
                    direction="top"
                  >
                    <input
                      disabled
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      value={i?.lane_destination_city ?? ""}
                      name={`route_description.${index}.lane_destination_city`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip content={`${Math.round(i?.lane_miles ?? 0)} mi`}  direction="top">
                    <input
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      onChange={(event) =>
                        changeField("lane_miles", index, event.target.value)
                      }
                      defaultValue={`${Math.round(i?.lane_miles ?? 0)} mi`} 
                      name={`route_description.${index}.lane_miles`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip
                    content={`${i?.lane_fuel_gal?.toFixed(2) ?? 0} gl`}
                    direction="top"
                  >
                    <input
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      onChange={(event) =>
                        changeField("lane_fuel_gal", index, event.target.value)
                      }
                      defaultValue={`${i?.lane_fuel_gal?.toFixed(2) ?? 0} gl`}
                      name={`route_description.${index}.lane_fuel_gal`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip
                    content={i?.lane_trip_type_name ?? "---"}
                    direction="top"
                  >
                    <input
                      disabled
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      defaultValue={i?.lane_trip_type_name ?? ""}
                      name={`route_description.${index}.lane_trip_type`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip
                    content={
                      (parseFloat(i?.lane_driving_time)?.secondsToHours() ??
                        0) + " hr"
                    }
                    direction="top"
                  >
                    <input
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      onChange={(event) =>
                        changeField(
                          "lane_driving_time",
                          index,
                          event.target.value
                        )
                      }
                      defaultValue={
                        (parseFloat(i?.lane_driving_time)?.secondsToHours() ??
                          0) + " hr"
                      }
                      name={`route_description.${index}.lane_driving_time`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip
                    content={
                      (parseFloat(i?.lane_operative_time)?.secondsToHours() ??
                        0) + " hr"
                    }
                    direction="top"
                  >
                    <input
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      onChange={(event) =>
                        changeField(
                          "lane_operative_time",
                          index,
                          event.target.value
                        )
                      }
                      defaultValue={
                        (parseFloat(i?.lane_operative_time)?.secondsToHours() ??
                          0) + " hr"
                      }
                      name={`route_description.${index}.lane_operative_time`}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip
                    content={Math.round(i?.lane_co2_emission ?? 0)}
                    direction="top"
                  >
                    <input
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      onChange={(event) =>
                        changeField(
                          "lane_co2_emission",
                          index,
                          event.target.value
                        )
                      }
                      name={`route_description.${index}.custom`}
                      defaultValue={Math.round(i?.lane_co2_emission ?? 0)}
                    />
                  </CustomToolTip>
                </td>
                <td>
                  <CustomToolTip content={`${i?.lane_size ?? 0}/${i?.lane_weight ?? 0}`} direction="top">
                    <input
                      className="form-control-plaintext p-0 lh-1 text-tbody"
                      type="text"
                      name={``}
                      defaultValue={`${i?.lane_size ?? 0}lft/${i?.lane_weight ?? 0}kg`}
                    />
                  </CustomToolTip>
                </td>
              </tr>
            ))}

            <tr className="text-tbody bg-tbody-success">
              <td colSpan={2}>Total proposed route</td>
              <td>
                <CustomToolTip
                  content={defaultData?.route_miles + "mi" ?? 0}
                  direction="top"
                >
                  {defaultData?.route_miles?.toFixed(2) + "mi" ?? 0}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={defaultData?.route_fuel ?? 0}
                  direction="top"
                >
                  {defaultData?.route_fuel?.toFixed(2) ?? "---" + "gl"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip content={"---"} direction="top">
                  {"---"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={
                    defaultData?.route_driving_time?.secondsToHours() + "hr" ??
                    0
                  }
                  direction="top"
                >
                  {defaultData?.route_driving_time?.secondsToHours() + "hr" ??
                    0}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={
                    defaultData?.route_operative_time?.secondsToHours() +
                      "hr" ?? 0
                  }
                  direction="top"
                >
                  {defaultData?.route_operative_time?.secondsToHours() + "hr" ??
                    0}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={defaultData?.route_co2_emission + "kg" ?? 0}
                  direction="top"
                >
                  {defaultData?.route_co2_emission?.toFixed(2) + "kg" ?? 0}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip content={"---"} direction="top">
                  {"---"}
                </CustomToolTip>
              </td>
            </tr>

            <tr className="text-tbody">
              <td colSpan={2}>Total custom</td>
              <td>
                <CustomToolTip content={totals?.miles ?? 0} direction="top">
                  {totals?.miles?.toFixed(2) ?? "---" + "mi"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip content={totals?.fuel_gal ?? 0} direction="top">
                  {totals?.fuel_gal?.toFixed(2) ?? "---" + "gl"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip content={"---"} direction="top">
                  {"---"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={totals?.driving_time?.secondsToHours() ?? "---"}
                  direction="top"
                >
                  {(totals?.driving_time?.secondsToHours() ?? 0) + "hr"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={totals?.operative_time?.secondsToHours() ?? "---"}
                  direction="top"
                >
                  {(totals?.operative_time?.secondsToHours() ?? 0) + "hr"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip
                  content={totals?.co2_emission ?? "---"}
                  direction="top"
                >
                  {Math.round(totals?.co2_emission ?? 0) + "kg"}
                </CustomToolTip>
              </td>
              <td>
                <CustomToolTip content={"---"} direction="top">
                  {"---"}
                </CustomToolTip>
              </td>
            </tr>
          </tbody>
        </table>

        <p className="route-name fw-bold mb-2">Indicators analysis</p> 
        <table className="table table-sm table-bordered border-dark">
          <thead>
            <tr className="text-thead fw-bold">
              {selectedColumns.map((column, index) => (
                column.id === 1 || column.id === 2 ? <th key={column.id + Math.random()}>{column.header}</th>
                 :<th key={column.id + Math.random()}>
                  <div className="dropCustom">
                    <DropdownButton
                        title={selectedHeaders[index]}
                        onSelect={(e) => handleSelectChange(index, e)}
                      >
                        {theadData.map((col) => (
                           col.id === 1 || col.id === 2 ? '' 
                        :<Dropdown.Item  key={col.id + Math.random()} eventKey={col.header}>
                          {col.header}
                        </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tbodyData[0].name.map((_, index) => (
              <tr key={index + Math.random()} className="text-tbody">
                {selectedColumns.map((column) => (
                  <td key={column.id + Math.random()}>
                    <CustomToolTip
                      content={tbodyData[column.id - 1].name[index] ?? '---'}
                      direction="top"
                    >
                      <input
                        className="form-control-plaintext p-0 lh-1 text-tbody"
                        type="text"
                        defaultValue={tbodyData[column.id - 1].name[index] ?? '---'}
                        name={`indicators_analysis.${column.id}.origin`}
                      />
                    </CustomToolTip>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Collapse>
  );
};

export default OperativeRouteAnalysisTable;
