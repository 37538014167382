import React from "react";
import { FiCornerUpLeft } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import FullLayout from "../../components/layout/FullLayout";
import FaqsItem from "./FaqsItem";

const Faqs = () => {
  const history = useHistory();
  let { url } = useRouteMatch();

  const goBack = () => {
    if (url) {
      if (url !== "/dashboard") {
        history.goBack();
      }
    }
    return;
  };

  const contentItem = [
    {
        title: "What is efRouting",
        subtitle: "efRouting",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut efficitur vulputate maximus. Fusce rutrum fringilla risus, vel sodales augue convallis et. Phasellus porta mollis pharetra. Aliquam erat volutpat. Fusce accumsan nisi sem, porttitor cursus felis accumsan eget. Cras iaculis faucibus arcu vel maximus. Vivamus sollicitudin massa ut augue molestie, quis aliquet felis volutpat. Nulla rhoncus et odio ultrices bibendum."
    },
    {
        title: "What is efRouting",
        subtitle: "efRouting",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut efficitur vulputate maximus. Fusce rutrum fringilla risus, vel sodales augue convallis et. Phasellus porta mollis pharetra. Aliquam erat volutpat. Fusce accumsan nisi sem, porttitor cursus felis accumsan eget. Cras iaculis faucibus arcu vel maximus. Vivamus sollicitudin massa ut augue molestie, quis aliquet felis volutpat. Nulla rhoncus et odio ultrices bibendum."
    },
    {
        title: "What is efRouting",
        subtitle: "efRouting",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut efficitur vulputate maximus. Fusce rutrum fringilla risus, vel sodales augue convallis et. Phasellus porta mollis pharetra. Aliquam erat volutpat. Fusce accumsan nisi sem, porttitor cursus felis accumsan eget. Cras iaculis faucibus arcu vel maximus. Vivamus sollicitudin massa ut augue molestie, quis aliquet felis volutpat. Nulla rhoncus et odio ultrices bibendum."
    },
    {
        title: "What is efRouting",
        subtitle: "efRouting",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut efficitur vulputate maximus. Fusce rutrum fringilla risus, vel sodales augue convallis et. Phasellus porta mollis pharetra. Aliquam erat volutpat. Fusce accumsan nisi sem, porttitor cursus felis accumsan eget. Cras iaculis faucibus arcu vel maximus. Vivamus sollicitudin massa ut augue molestie, quis aliquet felis volutpat. Nulla rhoncus et odio ultrices bibendum."
    },
    {
        title: "What is efRouting",
        subtitle: "efRouting",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut efficitur vulputate maximus. Fusce rutrum fringilla risus, vel sodales augue convallis et. Phasellus porta mollis pharetra. Aliquam erat volutpat. Fusce accumsan nisi sem, porttitor cursus felis accumsan eget. Cras iaculis faucibus arcu vel maximus. Vivamus sollicitudin massa ut augue molestie, quis aliquet felis volutpat. Nulla rhoncus et odio ultrices bibendum."
    },
  ]
  return (
    <FullLayout>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
        <div className="col-md-5 mt-5">
          <div className="d-flex flex-row flex-wrap">
            <FiCornerUpLeft
              onClick={() => goBack()}
              className="go-back-arrow me-3"
            />
            <h1 className="heading-h1 fw-bold text-capitalize">FAQs</h1>
          </div>
          <div className="d-flex flex-row flex-wrap mt-3">
            {contentItem.map((item, index) => (
                <div className="col-md-12 mb-3 contentCollapse">
                    <FaqsItem data={item} key={index} />
                </div>
            ))}
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default Faqs;
