import React, {useState} from 'react';
import NavbarTop from './NavbarTop';
import OffCanvasMenu from '../TabContentOffCanvas/OffCanvasMenu';

const FullLayout = ({children}) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);
    return (
        <>
            <main className="main-element ms-sm-auto col-lg-12 w-100" >
                <OffCanvasMenu show={show} handleShow={handleShow} />
                <NavbarTop logo={true} />
                {children}
            </main>
        </>
    )
}

export default FullLayout