import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import guardianReducer from '../redux/auth/GuardianSlice';
import cognitoReducer from  '../redux/auth/CognitoSlice';
import registerFormReducer from '../redux/registerForm/registerFormSlice';
import blackBoxRoutesReducer from '../redux/blackBoxRoutes/blackBoxRoutesSlice';
import userDataReducer from '../redux/user/UserSlice';
import userRouteFoldersReducer from '../redux/userRouteFolders/UserRouteFoldersSlice'
import routeFoldersReducer from '../redux/routeFolders/routeFoldersSlice'
import IndicatorReducer from '../redux/indicators/IndicatorsSlice';
import routeReducer from '../redux/route/routeSlice';
import InboxReducer from '../redux/inbox/InboxSlice';
import CategoryReducer from '../redux/indicators/CategoriesSlice';
import ConversationReducer from '../redux/inbox/ConversationSlice';
import NotificationReducer from '../redux/notifications/NotificationsSlice';





import {
  persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';

const reducers = combineReducers({
  guardian: guardianReducer,
  cognito: cognitoReducer,
  registerForm: registerFormReducer,
  blackBoxRoutes: blackBoxRoutesReducer,
  user_data: userDataReducer,
  user_route_folders: userRouteFoldersReducer,
  route_folders: routeFoldersReducer,
  indicator_list: IndicatorReducer,
  route: routeReducer,
  inbox: InboxReducer,
  categories: CategoryReducer,
  conversations: ConversationReducer,
  notifications: NotificationReducer,
});

const persistConfig = {
  key: "root_guardian",
  storage,
  whitelist: ['guardian', 'cognito', 'user_data', 'route', 'inbox', 'conversations', 'notifications'],
};

const rootReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],

});

export default store;
