import React from "react";
import { Collapse } from "react-bootstrap";
import CustomToolTip from "../../../components/layout/CustomToolTip";

const FinancialRouteAnalysisTable = (props) => {
	const { data, defaultData, totals, collapsed = false } = props;
	return (
		<Collapse in={collapsed}>
			<div id="two">
				<p className="route-name fw-bold mb-2">Cost</p>
				<table className="table table-sm table-bordered border-dark">
					<thead>
						<tr className="text-thead fw-bold">
							<th scope="col">Origin</th>
							<th scope="col">Destination</th>
							<th scope="col">Fuel cost</th>
							<th scope="col">Tolls</th>
							<th scope="col">Total variable cost</th>
							<th scope="col">Custom cost</th>
							<th scope="col">Custom cost</th>
						</tr>
					</thead>

					<tbody>
						{data?.get_lanes?.map((i, index) => (
							<tr className="text-tbody" key={index}>
								<td>
									<CustomToolTip
										content={i.lane_departure_city}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={i?.lane_departure_city ?? ""}
											name={`financial_cost.${index}.origin`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_destination_city}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={i?.lane_destination_city ?? ""}
											name={`financial_cost.${index}.destination`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_fuel_cost}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={i?.lane_fuel_cost ?? 0}
											name={`financial_cost.${index}.fuel_cost`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i?.lane_tolls ?? 0}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={i?.lane_tolls ?? "---"}
											name={`financial_cost.${index}.tolls`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_sub_total_variable_cost}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_sub_total_variable_cost ?? ""
											}
											name={`financial_cost.${index}.total_variable_cost`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.custom_cost}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											defaultValue={i?.custom_cost ?? ""}
											name={`financial_cost.${index}.custom_cost`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip content={"---"} direction="top">
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											defaultValue={"---"}
											name={``}
										/>
									</CustomToolTip>
								</td>
							</tr>
						))}
						<tr className="text-tbody bg-tbody-success">
							<td colSpan={2}>Total proposed route</td>
							<td>
								<CustomToolTip
									content={defaultData?.route_fuel_cost ?? ""}
									direction="top"
								>
									{"$" + defaultData?.route_fuel_cost ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip content={defaultData?.route_tolls ?? ""} direction="top">
								{"$" + defaultData?.route_tolls ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={
										defaultData?.route_total_variable_cost ?? ""
									}
									direction="top"
								>
									{"$" + defaultData?.route_total_variable_cost ??
										""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip content={"-"} direction="top">
									{"-"}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip content={"-"} direction="top">
									{"-"}
								</CustomToolTip>
							</td>
						</tr>

						<tr className="text-tbody">
							<td colSpan={2}>Total custom</td>
							<td>
								<CustomToolTip
									content={totals?.fuel_cost ?? ""}
									direction="top"
								>
									{"$" + (totals?.fuel_cost?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip content={totals?.tolls ?? ""} direction="top">
								{"$" + (totals?.tolls?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={totals?.total_variable_cost ?? ""}
									direction="top"
								>
									{"$" +
										(totals?.total_variable_cost?.toFixed(2) ??
											"")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip content={"-"} direction="top">
									{"-"}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip content={"-"} direction="top">
									{"-"}
								</CustomToolTip>
							</td>
						</tr>
					</tbody>
				</table>

				<p className="route-name fw-bold mb-2">Profit</p>
				<table className="table table-sm table-bordered border-dark">
					<thead>
						<tr className="text-super-thead fw-bold">
							<th colSpan={2}> </th>
							<th colSpan={3}>Income</th>
							<th colSpan={3}>Gross profit</th>
						</tr>
						<tr className="text-thead fw-bold">
							<th scope="col">Origin</th>
							<th scope="col">Destination</th>
							<th scope="col">25 % low</th>
							<th scope="col">Average</th>
							<th scope="col">25 % high</th>
							<th scope="col">25 % low</th>
							<th scope="col">Average</th>
							<th scope="col">25 % high</th>
						</tr>
					</thead>
					<tbody>
						{data?.get_lanes?.map((i, index) => (
							<tr className="text-tbody" key={index}>
								<td>
									<CustomToolTip
										content={i.lane_departure_city}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={i?.lane_departure_city ?? ""}
											name={`financial_profit.${index}.origin`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_destination_city}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={i?.lane_destination_city ?? ""}
											name={`financial_profit.${index}.destination`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_min_income}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_min_income?.toFixed(2) ??
												"---"
											}
											name={`financial_profit.${index}.lane_min_income`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_avg_income}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_avg_income?.toFixed(2) ??
												"---"
											}
											name={`financial_profit.${index}.lane_avg_income`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_max_income}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_max_income?.toFixed(2) ??
												"---"
											}
											name={`financial_profit.${index}.lane_max_income`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_min_profit}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_min_profit?.toFixed(2) ??
												"---"
											}
											name={`financial_profit.${index}.lane_min_profit`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_avg_profit}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_avg_profit?.toFixed(2) ??
												"---"
											}
											name={`financial_profit.${index}.lane_avg_profit`}
										/>
									</CustomToolTip>
								</td>
								<td>
									<CustomToolTip
										content={i.lane_max_profit}
										direction="top"
									>
										<input
											className="form-control-plaintext p-0 lh-1 text-tbody"
											type="text"
											disabled
											value={
												i?.lane_max_profit?.toFixed(2) ??
												"---"
											}
											name={`financial_profit.${index}.lane_max_profit`}
										/>
									</CustomToolTip>
								</td>
							</tr>
						))}
						<tr className="text-tbody bg-tbody-success">
							<td colSpan={2}>Total proposed route</td>
							<td>
								<CustomToolTip
									content={defaultData?.route_min_income ?? ""}
									direction="top"
								>
									{"$" + defaultData?.route_min_income ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={
										defaultData?.route_average_income ?? ""
									}
									direction="top"
								>
									{"$" + defaultData?.route_average_income ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={defaultData?.route_max_income ?? ""}
									direction="top"
								>
									{"$" + defaultData?.route_max_income ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={defaultData?.route_min_profit ?? ""}
									direction="top"
								>
									{"$" + defaultData?.route_min_profit ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={
										defaultData?.route_average_profit ?? ""
									}
									direction="top"
								>
									{"$" + defaultData?.route_average_profit ?? ""}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={defaultData?.route_max_profit ?? ""}
									direction="top"
								>
									{"$" + defaultData?.route_max_profit ?? ""}
								</CustomToolTip>
							</td>
						</tr>

						<tr className="text-tbody">
							<td colSpan={2}>Total custom</td>
							<td>
								<CustomToolTip
									content={totals?.min_income ?? ""}
									direction="top"
								>
									{"$" + (totals?.min_income?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={totals?.avg_income ?? ""}
									direction="top"
								>
									{"$" + (totals?.avg_income?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={totals?.max_income ?? ""}
									direction="top"
								>
									{"$" + (totals?.max_income?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={totals?.min_profit ?? ""}
									direction="top"
								>
									{"$" + (totals?.min_profit?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={totals?.avg_profit ?? ""}
									direction="top"
								>
									{"$" + (totals?.avg_profit?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
							<td>
								<CustomToolTip
									content={totals?.max_profit ?? ""}
									direction="top"
								>
									{"$" + (totals?.max_profit?.toFixed(2) ?? "")}
								</CustomToolTip>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Collapse>
	);
};

export default FinancialRouteAnalysisTable;
