import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiHelpCircle, FiX } from 'react-icons/fi';
import Calendar from 'moedim';
import OutsideAlerter from '../../components/OutsideAlerter';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';


const IndicatorsDetailBar = ({ showSource, showIndicatorType, setSearch }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateValue, setDateValue] = useState(new Date());
    const [frequency, setFrequency] = useState('weekly');

    let history = useHistory();
    let location = useLocation();
    let sectionName = useQuery();
    let { from } = location.state || { from: { pathname: "/indicators" } };
    const openCal = () => {
        setShowCalendar(false);
    }

    useEffect(() => {
       if (!setSearch) {
        return;
       }
       let objDataSearch = { frequency: frequency, date: dateValue}
       if (frequency && dateValue) {
           setSearch(objDataSearch);
       }
    }, [frequency, dateValue])


    return (<div className="card w-100">
        <div className="card-body">
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-start indicator-detail-bar-content">
                    <div className='indicator-detail-bar-item'>
                        <span className="heading-h2 fw-bold px-3 text-capitalize">{sectionName.get('section_name') ?? 'Indicator details'} <FiHelpCircle className="icon-h1 ms-2" /></span>
                    </div>
                    {
                        showSource &&
                        <div className='indicator-detail-bar-item'>
                            <span className='indicator-info-text px-2'>Source: efRouting</span>
                        </div>
                    }

                    <div className='indicator-detail-bar-item'>
                        <span className='indicator-info-text px-2' onClick={() => setShowCalendar(!showCalendar)}> {dateValue.simpleFormatFullDate()} <FiChevronDown style={{ fontSize: '22px' }} /> </span>
                        {showCalendar &&
                            <OutsideAlerter position={'absolute'} listener={showCalendar} trigger={openCal}>
                                <Calendar className='calendar indicator-calendar' value={dateValue} onChange={(d) => setDateValue(d)} />
                            </OutsideAlerter>
                        }
                    </div>
                    <div className='indicator-detail-bar-item'>
                        <select name="frequency" className='form-select-sm transparent-select' value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                            <option value="annual">Annual</option>
                        </select>
                    </div>
                    {
                        showIndicatorType &&
                        <div className='indicator-detail-bar-item'>
                            <select name="frequency" className='form-select-sm transparent-select'>
                                <option value="0">Charts</option>
                                <option value="1">Boards</option>
                            </select>
                        </div>
                    }
                </div>
                <button className="pe-3 btn-clean text-white" onClick={() => history.replace(from)}>
                    <FiX />
                </button>
            </div>
        </div>
    </div>);
}

IndicatorsDetailBar.defaultProps = {
    setSearch: false,
}

export default IndicatorsDetailBar;