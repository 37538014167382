/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FiHelpCircle } from "react-icons/fi";
import { setForm } from "../../../redux/registerForm/registerFormSlice";
import { useParameter } from "../../../hooks/useParameter";
import CustomToolTip from "../../../components/layout/CustomToolTip";
import Form from "react-bootstrap/Form";

const PersonalInformation = ({ formStep, nextFormStep }) => {
  const { registerForm } = useSelector((state) => state.registerForm);
  // [0 = Independent dispatcher], [1 = Carrier dispatcher], [2 = Owner operator], [3 = Other]
  const [showOtherFields, setShowOtherFields] = useState(
    registerForm?.showOtherFields ? registerForm?.showOtherFields : 0
  );
  const dispatch = useDispatch();

  const [trucksNumber, setTrucksNumber] = useState([]);

  const company_sizes = useParameter("company_sizes");
  const trucks_number = useParameter("trucks_number");

  useEffect(() => {
    changeFields(registerForm?.user_job ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_sizes, trucks_number]);

  const changeFields = (value) => {
    let data = [];
    switch (value) {
      case "Independent dispatcher":
        data = trucks_number.filter(
          (element) => element.pv_data?.type == "Independent dispatcher"
        );
        setTrucksNumber(data);
        setValue("user_truck_number", registerForm?.user_truck_number);
        return setShowOtherFields(0);
      case "Carrier dispatcher":
        data = trucks_number.filter(
          (element) => element.pv_data?.type == "Carrier dispatcher"
        );
        setTrucksNumber(data);
        setValue("user_company_size", registerForm?.user_company_size);
        setValue("user_truck_number", registerForm?.user_truck_number);
        return setShowOtherFields(1);
      case "Owner operator":
        setValue("user_company_size", registerForm?.user_company_size);
        return setShowOtherFields(2);
      case "Other":
        setValue("user_company_size", registerForm?.user_company_size);
        return setShowOtherFields(3);
      default:
        return setShowOtherFields(0);
    }
  };
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    // watch,
  } = useForm();

  const handleSubmitter = async (data) => {
    if (data.user_job == "Other") {
      data.user_job = data.user_data.user_role_name;
    }
    data = {
      ...data,
      user_data: JSON.stringify(data.user_data),
      showOtherFields: showOtherFields,
    };
    const formData = { ...registerForm, ...data };
    dispatch(setForm(formData));
    nextFormStep();
  };

  return (
    <div className={formStep === 1 ? "d-block" : "d-none"}>
      <h1 className="h3 mb-2 text-welcomeSingUp text-center">
        Complete your personal information
      </h1>
      <p className="orLogin pe-2">Personal information</p>
      <form
        className="d-flex flex-row flex-wrap form-singUp"
        onSubmit={handleSubmit(handleSubmitter)}
      >
        <div className="col-md-6">
          <input
            {...register("user_role_id", {
              required: "Role is required",
            })}
            value={2}
            type="text"
            hidden
          />
          <label className="form-label">Name *</label>
          <input
            {...register("user_name", {
              required: "Name is required",
            })}
            defaultValue={registerForm?.user_name ?? ""}
            type="text"
            className={
              errors.user_name
                ? "form-control invalid mb-2"
                : "form-control mb-4"
            }
            placeholder="Name"
          />
          {errors.user_name ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.user_name.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Last name *</label>
          <input
            {...register("user_last_name", {
              required: "Last Name is required",
            })}
            type="text"
            defaultValue={registerForm?.user_last_name ?? ""}
            className={
              errors.user_last_name
                ? "form-control invalid mb-2"
                : "form-control mb-4"
            }
            placeholder="Last Name"
          />
          {errors.user_last_name ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.user_last_name.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12">
          <label className="form-label">Select your role *</label>
          <select
            {...register("user_job", {
              required: "Role is required",
              validate: (value) => value !== "0" || "Role is required",
            })}
            defaultValue={registerForm?.user_job ?? 0}
            className={
              errors.user_job ? "form-select invalid mb-2" : "form-select mb-4"
            }
            onChange={(e) => changeFields(e.target.value)}
          >
            <option value="0">Select your role</option>
            <option value="Independent dispatcher">
              Independent dispatcher
            </option>
            <option value="Carrier dispatcher">Carrier dispatcher</option>
            <option value="Owner operator">Owner operator</option>
            <option value="Other">Other</option>
          </select>
          {errors.user_job ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.user_job.message}
            </p>
          ) : (
            ""
          )}
        </div>
        {showOtherFields === 3 && (
          <div className="col-md-4">
            <label className="form-label">Type your role *</label>
            <input
              {...register("user_data.user_role_name", {
                required: "Role name is required",
              })}
              type="text"
              className={
                errors.user_role_name
                  ? "form-control invalid mb-2"
                  : "form-control mb-4"
              }
              aria-describedby="nameHelp"
              placeholder="Type your role"
              defaultValue={
                registerForm?.user_data
                  ? JSON.parse(registerForm?.user_data).user_role_name ?? ""
                  : ""
              }
            />
            {errors.user_role_name ? (
              <p className="text-danger text-error mb-1" role="alert">
                {errors.user_role_name.message}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
        <div
          className={
            showOtherFields !== 2 && showOtherFields !== 0
              ? "mb-2 col-md-4"
              : "mb-2 col-md-6"
          }
        >
          <label className="form-label"> Company name *</label>
          <input
            {...register("user_company_name", {
              required: "Company name is required",
            })}
            type="text"
            className={
              errors.user_company_name
                ? "form-control invalid mb-2"
                : "form-control mb-4"
            }
            placeholder="Company name"
            defaultValue={registerForm?.user_company_name ?? ""}
          />
          {errors.user_company_name ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.user_company_name.message}
            </p>
          ) : (
            ""
          )}
        </div>
        {showOtherFields !== 0 && (
          <div
            className={
              showOtherFields !== 2 ? "mb-2 col-md-4" : "mb-2 col-md-6"
            }
          >
            <label className="form-label">Size of the company</label>
            <Controller
              name="user_company_size"
              control={control}
              defaultValue={registerForm?.user_company_size ?? 0}
              render={({ field: { onChange, value, ref } }) => (
                <Form.Select
                  value={value}
                  onChange={(val) => onChange(val)}
                  className={
                    errors.user_company_size
                      ? "form-select invalid mb-2"
                      : "form-select mb-4"
                  }
                >
                  <option value={0}>Select size of the company</option>
                  {company_sizes?.map((e, i) => (
                    <option key={i} value={e.pv_id}>
                      {e.pv_name}
                    </option>
                  ))}
                </Form.Select>
              )}
              rules={{
                required: "Company size is required",
                validate: (value) =>
                  value !== "0" || "Company size is required",
              }}
            />
            {errors.user_company_size ? (
              <p className="text-danger text-error mb-1" role="alert">
                {errors.user_company_size.message}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
        {showOtherFields !== 3 && showOtherFields !== 2 && (
          <div
            className={
              showOtherFields !== 0 ? "mb-2 col-md-4" : "mb-2 col-md-6"
            }
          >
            <label className="form-label me-2">Number of trucks</label>
            <CustomToolTip
              classTooltip={"tooltip-content-media"}
              classText={"text-contentTooltip"}
              width={"421px"}
              height={"220px"}
              icon={<FiHelpCircle className="text-tooltip me-2" />}
              title={"Number of trucks"}
              content={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc ligula, sit amet elementum massa consectetur non. Proin luctus, felis ut facilisis scelerisque, ipsum enim imperdiet magna, at cursus nibh quam egestas mi. "
              }
              direction="bottom"
            >
              <FiHelpCircle className="info-icon-help mb-1" />
            </CustomToolTip>
            <Controller
              name="user_truck_number"
              control={control}
              defaultValue={registerForm?.user_truck_number ?? 0}
              render={({ field: { onChange, value, ref } }) => (
                <Form.Select
                  value={value}
                  onChange={(val) => onChange(val)}
                  className={
                    errors.user_truck_number
                      ? "form-select invalid mb-2"
                      : "form-select mb-4"
                  }
                >
                  <option value={0}>Select number of trucks</option>
                  {trucksNumber?.map((e, i) => (
                    <option key={i} value={e.pv_id}>
                      {e.pv_name}
                    </option>
                  ))}
                </Form.Select>
              )}
              rules={{
                required: "Truck number is required",
                validate: (value) =>
                  value !== "0" || "Truck number is required",
              }}
            />
            {errors.user_truck_number ? (
              <p className="text-danger text-error mb-1" role="alert">
                {errors.user_truck_number.message}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
        <button type="submit" className="w-100 btn btn-lg btn-success mb-3">
          CONTINUE
        </button>
      </form>
    </div>
  );
};

export default PersonalInformation;
