import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FiMoreVertical } from 'react-icons/fi';

const CustomDropList = ({ options, type, title }) => {
    return (
        <div className='dropSettings'>
            <DropdownButton drop={type} title={title}>
                {options.map((item, index) =>
                    <Dropdown.Item key={index} onClick={() => item.action(item)}>{item.name}</Dropdown.Item>
                )}
            </DropdownButton>
        </div>);
}

CustomDropList.defaultProps = {
    options: [],
    type: 'down',
    title: <FiMoreVertical className='icon-more-options' />,
}

export default CustomDropList;