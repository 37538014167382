import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FiX } from "react-icons/fi";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';

const SimpleModal = ({ showSmallModal, setShowSmallModal, title, subTitle, letterBtn, buttons, titleAlert, textAlert, actionModal = false, values, formName }) => {

    const handleClose = () => setShowSmallModal(false);
    const { register, handleSubmit } = useForm();
    let history = useHistory();
    let {state} = useLocation();

    const alertSave = () => {
        let { from } = state || { from: { pathname: "/route-planning" } };
        Swal.fire({
            title: 'Saved route',
            text: "your route was successfully saved",
            showCancelButton: true,
            showDenyButton: false,
            background: '#121A1F',
            reverseButtons: true,
            cancelButtonText: 'STAY ON THE ROUTE',
            confirmButtonText: 'NEW SEARCH',
        }).then((result) => {
            if (result.isConfirmed) {
                setTimeout(() => {
                    history.replace(from);
                }, 4000)
            }
        })

    };


    const onSubmit = (data) => {
        console.log(data);
    }

    return (
        <Modal show={showSmallModal} onHide={handleClose}
            centered
            dialogClassName="modal-70w"
            className={(title === 'Save As') ? 'modalSave' : 'modalSmall'}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <FiX className='btn-closeModal' onClick={handleClose} />
            </Modal.Header>
            <>
                <form onSubmit={handleSubmit(actionModal)} className='d-flex flex-row flex-wrap w-100'>
                    <div className='col-md-12 mb-3'>
                        <p className='letterSettings'>{subTitle}</p>
                        <input type="text" {...register(formName, { required: true })} id="" className='form-control formModal' defaultValue={values ?? ''} placeholder='Default name' />
                    </div>
                    {!buttons &&
                        <button type='submit' className='btn-modal w-100' onClick={handleClose}>{letterBtn}</button>}
                {buttons &&
                    <div className='d-flex flex-row justify-content-between align-items-center w-100 gap-4'>
                        <button type='button' className='btn-modalSave' onClick={handleClose}>CANCEL</button>
                        <button type='submit' className='btn-modal w-50' onClick={() => handleClose()}>{letterBtn}</button>
                    </div>
                }
                </form>
            </>
        </Modal >
    )
}

export default SimpleModal