import React from 'react';
import PropTypes from 'prop-types';


/**
 * This function takes in an item and returns a table head item with the item as the title and the item
 * as the content.
 * @returns A function that returns a component.
 */
export const TableHeadItem = ({ item }) => {
    return (
        <th scope='col' title={item}>
            {item}
        </th>
    );
};

/* A function that takes in an object with a property called data. It then returns a table row with the
data. */
export const TableRow = ({ data }) => {
    return (
        <tr className='d-flex'>
            {data.map((item, index) => {
                return <td className="text-tbody" key={index}>{item}</td>;
            })}
        </tr>
    );
};

const SimpleTable = ({ theadData, tbodyData, customClass }) => {
    return (
        <div className='h-75'>
            <table className={customClass}>
                <thead>
                    <tr className='text-thead fw-bold d-flex'>
                        {theadData.map((h, index) => {
                            return <TableHeadItem key={index} item={h} />;
                        })}
                    </tr>
                </thead>

                <tbody>
                    {tbodyData.map((item, index) => {
                        return <TableRow key={index} data={item.items} />;
                    })}
                </tbody>
            </table>
        </div>
    );
}


/* Setting the prop types for the component. */
SimpleTable.propTypes = {
    customClass: PropTypes.string,
    theadData: PropTypes.array,
    tbodyData: PropTypes.array,
};


/* Setting the default props for the component. */
SimpleTable.defaultProps = {
    customClass: 'table table-sm border-dark fixed_header',
    theadData: ["Title", "Title", "Title", "Title"],
    tbodyData: [
        {
            id: "1",
            items: ["Default", "Default", "Default", "Default"]
        },
        {
            id: "2",
            items: ["Default", "Default", "Default", "Default"]
        },
        {
            id: "3",
            items: ["Default", "Default", "Default", "Default"]
        },
        {
            id: "4",
            items: ["Default", "Default", "Default", "Default"]
        }, {
            id: "5",
            items: ["Default", "Default", "Default", "Default"]
        }, {
            id: "6",
            items: ["Default", "Default", "Default", "Default"]
        }, {
            id: "7",
            items: ["Default", "Default", "Default", "Default"]
        },
    ],
}
export default SimpleTable;