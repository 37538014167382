import React from "react";
import { useSelector } from "react-redux";

const FormCardWizard = ({ children, currentStep, prevFormStep }) => {
  const { registerForm } = useSelector((state) => state.registerForm);
  return (
    <div className="">
      {currentStep < 4 && (
        <>
          <div className="row justify-content-center mb-3 mobileWizard">
            <div className={(currentStep + 1) >= 1 ? 'current-step me-2':'current-step me-2'} onClick={() => !registerForm.isGoogleRegister && ((currentStep + 1) > 1) && prevFormStep()}></div>
            <div className={(currentStep + 1) >= 2 ? 'current-step me-2':'next-step me-2'} onClick={() => (currentStep + 1) > 2 && prevFormStep()}></div>
            <div className={(currentStep + 1) >= 3 ? 'current-step me-2':'next-step me-2'} onClick={() => (currentStep + 1) > 3 && prevFormStep()}></div>
          </div>
        </>
      )}
      {children}
    </div>
  );
};

export default FormCardWizard;
