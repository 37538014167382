/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setForm } from "../../../redux/registerForm/registerFormSlice";

import ReCAPTCHA from "react-google-recaptcha";
import auth, { getAuthenticatedUser } from "../../../auth";

import logo_google from "../../../app/images/XMLID_28_.png";

import { FiEye, FiHelpCircle } from "react-icons/fi";
import { PatternFormat } from "react-number-format";

import CustomModal from "../../../components/modals/CustomModal";
import CustomToolTip from "../../../components/layout/CustomToolTip";

const GeneralInformation = ({ formStep, nextFormStep }) => {
  const mounted = useRef(false);

  auth.configure({
    oauth: {
      domain: "routing.auth.us-east-1.amazoncognito.com",
      scope: ["openid", "email", "profile"],
      redirectSignIn: `${window.location.origin}/sign-up`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: "token",
    },
  });

  let history = useHistory();
  const user_password = useRef({});
  const [phoneBase, setPhoneBase] = useState(0);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const { registerForm } = useSelector((state) => state.registerForm);
  const dispatch = useDispatch();

  const [showModalService, setShowModalService] = useState(false);
  const [showModalPolicy, setShowModalPolicy] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm({ mode: "all" });

  const googleSignIn = () => {
    auth.federatedSignIn({ provider: "Google" });
  };

  const loadGoogleData = async () => {
    const authenticated_user = await getAuthenticatedUser();
    if (authenticated_user == null) {
      return;
    }
    const user_data = authenticated_user?.signInUserSession?.idToken?.payload;
    const data = {
      user_name: user_data?.given_name ?? "",
      user_last_name: user_data?.family_name ?? "",
      user_email: user_data?.email ?? "",
      user_google_token: user_data?.sub ?? "",
      user_cognito_username: user_data["cognito:username"] ?? "",
    };
    console.log(data);
    setValue("user_email", data.user_email);
    const formData = {
      ...registerForm,
      ...data,
      isGoogleRegister: true,
    };
    dispatch(setForm(formData));

    formStep == 0 && nextFormStep();
  };

  useEffect(() => {
    if (!mounted.current) {
      loadGoogleData();
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  user_password.current = watch("user_password", "");

  const handleSubmitter = async (data) => {
    if ((phoneBase?.length ?? "") !== 10) {
      return setError(
        "user_cellphone",
        { type: "focus", message: "Cellphone must to have 10 digits" },
        { shouldFocus: true }
      );
    }
    data = { ...data, user_cellphone: phoneBase.substring(0, 10) };
    const formData = { ...registerForm, ...data };
    dispatch(setForm(formData));
    nextFormStep();
  };

  const arrErrors = Object.entries(errors).filter(
    (element) =>
      element[0] !== "user_password" &&
      element[0] !== "user_password_confirmation"
  );

  console.log(arrErrors);

  return (
    <div className={formStep === 0 ? "d-block" : "d-none"}>
      <h1 className="h3 mb-2 text-welcomeSingUp text-center">
        ¡Welcome to efRouting!
      </h1>
      <button
        className="btn btn-light btn-block w-100 mb-2 btn-google"
        onClick={googleSignIn}
      >
        <img src={logo_google} alt="" /> Sign up with Google
      </button>
      <p className="orLogin pe-2">General information</p>
      <form
        className="d-flex flex-row flex-wrap form-singUp"
        onSubmit={handleSubmit(handleSubmitter)}
      >
        <div className="col-md-6 col-sm-6">
          <label className="form-label">Email address *</label>
          <input
            {...register("user_email", {
              required: "Email is required",
            })}
            type="email"
            className={
              errors.user_email
                ? "form-control invalid mb-2"
                : "form-control mb-4"
            }
            placeholder="Email address"
            defaultValue={registerForm?.user_email ?? ""}
          />
          {errors.user_email ? (
            <p className="text-danger text-error mb-0" role="alert">
              {errors.user_email.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-sm-6">
          <label className="form-label">Cellphone number *</label>
          <div
            className={
              errors.user_cellphone
                ? "input-group mb-2 invalid"
                : "input-group mb-4"
            }
          >
            <span className="input-group-text">+1</span>
            <PatternFormat
              {...register("user_cellphone", {
                required: phoneBase ? false : "Cellphone is required",
                minLength: 10 || "Cellphone ",
                maxLength: 10,
              })}
              format="(###) ### ####"
              onValueChange={(value) => setPhoneBase(value.value)}
              className={
                errors.user_cellphone
                  ? "form-control invalidPass"
                  : "form-control"
              }
              placeholder="(###) ### ####"
              value={registerForm?.user_cellphone ?? ""}
            />
          </div>
          {errors.user_cellphone ? (
            <p className="text-danger text-error mb-0" role="alert">
              {errors.user_cellphone.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-sm-6">
          <label className="form-label">Password *</label>
          <CustomToolTip
            classTooltip={"tooltip-content"}
            classText={"text-contentTooltip"}
            width={"280px"}
            height={"200px"}
            content={
              "The Password must contain at least one digit, one uppercase letter, one lowercase letter, one special character."
            }
            direction="right"
          >
            <FiHelpCircle className="info-icon-help ms-2" />
          </CustomToolTip>
          <div
            className={
              errors.user_password
                ? "input-group mb-2 invalid"
                : "input-group mb-4"
            }
          >
            <input
              {...register("user_password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "The password must be at least 6 characters long",
                },
                pattern: {
                  value:
                    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?!.*\s).{6,}$/,
                  message:
                    "The Password must contain at least one digit, one uppercase letter, one lowercase letter, one special character",
                },
              })}
              type={showPass ? "text" : "password"}
              className={
                errors.user_password
                  ? "form-control invalidPass border-end-0"
                  : "form-control border-end-0"
              }
              placeholder="Password"
              defaultValue={registerForm?.user_password ?? ""}
            />
            <span
              className="input-group-text btn-eye"
              onClick={() => setShowPass(!showPass)}
            >
              <FiEye />
            </span>
          </div>
          {errors.user_password ? (
            <p className="text-danger text-error mb-0" role="alert">
              {errors.user_password.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 col-sm-6">
          <label className="form-label">Confirm password *</label>
          <div
            className={
              errors.user_password_confirmation
                ? "input-group mb-2 invalid"
                : "input-group mb-4"
            }
          >
            <input
              {...register("user_password_confirmation", {
                required: "Password confirmation is required",
                minLength: {
                  value: 6,
                  message:
                    "The password confirmation must be at least 6 characters long",
                },
                pattern: {
                  value:
                    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?!.*\s).{6,}$/,
                  message:
                    "The Password confirmation must contain at least one digit, one uppercase letter, one lowercase letter, one special character",
                },
                validate: (value) =>
                  value === user_password.current ||
                  "The passwords do not match",
              })}
              type={showPassConfirm ? "text" : "password"}
              className={
                errors.user_password_confirmation
                  ? "form-control invalidPass border-end-0"
                  : "form-control border-end-0"
              }
              placeholder="Confirmation password"
              defaultValue={registerForm?.user_password_confirmation ?? ""}
            />
            <span
              className="input-group-text btn-eye"
              onClick={() => setShowPassConfirm(!showPassConfirm)}
            >
              <FiEye />
            </span>
          </div>
          {errors.user_password_confirmation ? (
            <p className="text-danger text-error" role="alert">
              {errors.user_password_confirmation.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12">
          <div className="form-check">
            <input
              {...register("iAgree", {
                required: "Please agree to Terms of Service and Privacy Policy",
              })}
              type="checkbox"
              defaultChecked={registerForm?.iAgree ?? false}
              className={
                errors.iAgree ? "form-check-input invalid" : "form-check-input"
              }
            />
            <label className="form-check-label">
              I agree to our{" "}
              <span
                className="text-link"
                onClick={() => setShowModalService(!showModalService)}
              >
                Terms of Service
              </span>{" "}
              and{" "}
              <span
                className="text-link"
                onClick={() => setShowModalPolicy(!showModalPolicy)}
              >
                Privacy Policy
              </span>
            </label>
          </div>
          {errors.iAgree ? (
            <p className="text-danger text-error" role="alert">
              {errors.iAgree.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <input
          type="hidden"
          {...register("verified", {
            required: "Check the verification box",
          })}
        />
        <div className="d-flex flex-row flex-wrap justify-content-center w-100 mb-2 mt-3">
          <ReCAPTCHA
            sitekey="6LcTYOkjAAAAAFFq6kM_DKdI4tvNNI6UsJJ6hP0S"
            hl="en"
            size="normal"
            theme="dark"
            onChange={(value) => setValue("verified", true)}
            onExpired={() => setValue("verified", false)}
          />
        </div>
        <div className="col-md-12">
          {errors.verified ? (
            <p className="text-danger text-error mb-1" role="alert">
              {errors.verified.message}
            </p>
          ) : (
            ""
          )}
        </div>
        <button type="submit" className="w-100 btn btn-lg btn-success mb-2">
          SIGN UP
        </button>
      </form>
      <label className="orLogin pe-2">Already have an account?</label>
      <button
        onClick={() => history.replace("/login")}
        className="w-100 btn btn-lg btn-secondary mt-2"
      >
        LOG IN
      </button>

      <CustomModal
        showCustomModal={showModalService}
        setShowCustomMOdal={setShowModalService}
        dialogClass="modal-95w"
        classModal="modalPolicity"
      >
        <div className="d-flex flex-row flex-wrap w-100">
          <div className="col-md-12">
            <h4 className="titlePolicity">Terms of service</h4>
          </div>
          <div className="col-md-12">
            <p className="textContentPolicity">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido el texto de relleno
              estándar de las industrias desde el año 1500, cuando un impresor
              (N. del T. persona que se dedica a la imprenta) desconocido usó
              una galería de textos y los mezcló de tal manera que logró hacer
              un libro de textos especimen. No sólo sobrevivió 500 años, sino
              que tambien ingresó como texto de relleno en documentos
              electrónicos, quedando esencialmente igual al original. Fue
              popularizado en los 60s con la creación de las hojas "Letraset",
              las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el
              cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
          <div className="col-md-12">
            <h4 className="titlePolicity">Terms of service</h4>
          </div>
          <div className="col-md-12">
            <p className="textContentPolicity">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido el texto de relleno
              estándar de las industrias desde el año 1500, cuando un impresor
              (N. del T. persona que se dedica a la imprenta) desconocido usó
              una galería de textos y los mezcló de tal manera que logró hacer
              un libro de textos especimen. No sólo sobrevivió 500 años, sino
              que tambien ingresó como texto de relleno en documentos
              electrónicos, quedando esencialmente igual al original. Fue
              popularizado en los 60s con la creación de las hojas "Letraset",
              las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el
              cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
          <div className="col-md-12">
            <h4 className="titlePolicity">Terms of service</h4>
          </div>
          <div className="col-md-12">
            <p className="textContentPolicity">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido el texto de relleno
              estándar de las industrias desde el año 1500, cuando un impresor
              (N. del T. persona que se dedica a la imprenta) desconocido usó
              una galería de textos y los mezcló de tal manera que logró hacer
              un libro de textos especimen. No sólo sobrevivió 500 años, sino
              que tambien ingresó como texto de relleno en documentos
              electrónicos, quedando esencialmente igual al original. Fue
              popularizado en los 60s con la creación de las hojas "Letraset",
              las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el
              cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
          <div className="degraded"></div>
        </div>
      </CustomModal>
      <CustomModal
        showCustomModal={showModalPolicy}
        setShowCustomMOdal={setShowModalPolicy}
        dialogClass="modal-95w"
        classModal="modalPolicity"
      >
        <div className="d-flex flex-row flex-wrap w-100">
          <div className="col-md-12">
            <h4 className="titlePolicity">Privacy policy</h4>
          </div>
          <div className="col-md-12">
            <p className="textContentPolicity">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido el texto de relleno
              estándar de las industrias desde el año 1500, cuando un impresor
              (N. del T. persona que se dedica a la imprenta) desconocido usó
              una galería de textos y los mezcló de tal manera que logró hacer
              un libro de textos especimen. No sólo sobrevivió 500 años, sino
              que tambien ingresó como texto de relleno en documentos
              electrónicos, quedando esencialmente igual al original. Fue
              popularizado en los 60s con la creación de las hojas "Letraset",
              las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el
              cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
          <div className="col-md-12">
            <h4 className="titlePolicity">Privacy policy</h4>
          </div>
          <div className="col-md-12">
            <p className="textContentPolicity">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido el texto de relleno
              estándar de las industrias desde el año 1500, cuando un impresor
              (N. del T. persona que se dedica a la imprenta) desconocido usó
              una galería de textos y los mezcló de tal manera que logró hacer
              un libro de textos especimen. No sólo sobrevivió 500 años, sino
              que tambien ingresó como texto de relleno en documentos
              electrónicos, quedando esencialmente igual al original. Fue
              popularizado en los 60s con la creación de las hojas "Letraset",
              las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el
              cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
          <div className="col-md-12">
            <h4 className="titlePolicity">Privacy policy</h4>
          </div>
          <div className="col-md-12">
            <p className="textContentPolicity">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto. Lorem Ipsum ha sido el texto de relleno
              estándar de las industrias desde el año 1500, cuando un impresor
              (N. del T. persona que se dedica a la imprenta) desconocido usó
              una galería de textos y los mezcló de tal manera que logró hacer
              un libro de textos especimen. No sólo sobrevivió 500 años, sino
              que tambien ingresó como texto de relleno en documentos
              electrónicos, quedando esencialmente igual al original. Fue
              popularizado en los 60s con la creación de las hojas "Letraset",
              las cuales contenian pasajes de Lorem Ipsum, y más recientemente
              con software de autoedición, como por ejemplo Aldus PageMaker, el
              cual incluye versiones de Lorem Ipsum.
            </p>
          </div>
          <div className="degraded"></div>
        </div>
      </CustomModal>
    </div>
  );
};

export default GeneralInformation;
