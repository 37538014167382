import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    twilio_token: null,
};
export const InboxSlice = createSlice({
    name: "inbox",
    initialState,
    reducers: {
        setTwilioToken: (state, action) => {
            state.twilio_token = action.payload;
        },
    },
});

export const { setTwilioToken } = InboxSlice.actions;
export const selectTwilioTokenState = (state) => state.inbox.twilio_token;

export default InboxSlice.reducer;
