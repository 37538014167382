import React, { useEffect, useState } from 'react';
import { FiLogIn, FiBookmark, FiFolderPlus, FiDownload, FiBell, FiChevronDown } from "react-icons/fi";
// import Chart from 'react-apexcharts';
import Swal from "sweetalert2";
import DetailsGraphIndicators from './modals/DetailsGraphIndicators';
import ModalSave from './modals/ModalSave';
import CustomDropList from './layout/CustomDropList';
import requestGetAnnualIndicator from '../services/indicators/requestGetAnnualIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { selectGuardianState } from '../redux/auth/GuardianSlice';
import { selectIndicatorsList, setIndicatorsList } from '../redux/indicators/IndicatorsSlice';
import NotificationsModal from './modals/NotificationsModal';
import OutsideAlerter from './OutsideAlerter';
import { useElementOnScreen } from '../hooks/useElementOnScreen';
import requestSaveNotification from '../services/notifications/requestSaveNotification';
import chartsConfig from '../chartsConfig.template';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const Chart = React.lazy(() =>
    sleep(2000).then(() => import("react-apexcharts"))
);


const GraphCard = (props) => {

    const dispatch = useDispatch();

    const config = {

    }

    /* Using the useElementOnScreen hook to check if the element is visible on the screen. */
    const [containerRef, isVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    });

    const indicators_list = useSelector(selectIndicatorsList);
    const authCheck = useSelector(selectGuardianState);
    const [showSave, setShowSave] = useState(false);
    const [showSubItems, setShowSubItems] = useState(false);
    const [showDetailsGraph, setShowDetailsGraph] = useState(false);
    const [indicatorCat, setIndicatorCat] = useState(null);
    const [objIndicators, setObjIndicators] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [show, setShow] = useState(false);
    const [subDrop, setSubDrop] = useState(false);
    const openSwitch = () => { setShow(false); setSubDrop(false); };
    const openSwitchSubItem = () => setSubDrop(false);

    const [ind_reference, setInd_reference] = useState(props.item?.indicator_reference ?? null)
    const [dataSerie, setDataSerie] = useState([]);
    const [dataLabel, setDataLabel] = useState([]);
    const [chartType, setChartType] = useState('bar');
    const [options, setOptions] = useState({});

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    /* Updating the state of the component. */
    useEffect(() => {
        let indicator_arr = [...indicators_list];
        let objItem = { ...props.item, dataGraph: { values: dataSerie, labels: dataLabel } };
        let indexList = indicator_arr?.findIndex(function (el) { return el.indicator_id === props.item?.indicator_id; });
        indicator_arr[indexList] = objItem;
        dispatch(setIndicatorsList(indicator_arr));
        setObjIndicators(objItem);
    }, [dataSerie]);

    /**
     * It's an async function that makes a request to an API, then it takes the response and puts it
     * into an array.
     */
    const getIndicatorData = async () => {
        try {
            let options = { user_token: authCheck, year: 2020, reference: ind_reference, pagination: 4, page: 1, indicator_associate_to: props.item?.indicator_associate_to };
            const res = await requestGetAnnualIndicator(options, props.item?.indicator_frequency);
            let arr = [];
            let arrLabels = [];
            if (res.state === 200) {
                res.data.forEach((el, index) => {
                    if (index > 12) {
                        return;
                    }
                    if(Number.isInteger(el.value)) {
                        arr.push(el.value.toFixed(0));
                      } else {
                        arr.push(el.value.toFixed(2));
                      }
                    var date = el.date;
                    if (el.state) {
                        date = el.state;
                    }
                    if (typeof el.date === 'string' && props.item?.indicator_frequency === 'monthly') {
                        date = date.replace(/-/g, '\/');
                        date = new Date(date).getMonth();
                        date = months[date];
                    } else if (typeof el.date === 'string' && props.item?.indicator_frequency === 'weekly') {
                        date = date.replace(/-/g, '\/');
                        date = new Date(date);
                        let month = date.getMonth();
                        date = 'Week' + (new Date(date).getWeekInMonth()) + '/' + months[month];
                    }


                    arrLabels.push(date);
                });
                setDataSerie(arr);
                setDataLabel(arrLabels);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (dataSerie.length !== 0 || !isVisible) {
            return;
        }
        getIndicatorData();
    }, [props.item?.indicator_reference, isVisible]);

    const series = [{
        name: props.item?.indicator_name ?? 'Indicator Name',
        data: dataSerie ?? [-40, 25, -15, 10, 12.6, 10]
    }];

    useEffect(() => {
        // console.log(props.item);
        let type = props.item?.indicator_main_type;
        setChartType(type);


    }, [props.item])


    useEffect(() => {
        // console.log(chartsConfig[chartType]);
        setOptions({
            ...chartsConfig[chartType], xaxis: {
                type: 'category',
                categories: dataLabel ?? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                labels: {
                    rotate: -90,
                }
            },
        });

    }, [chartType, dataLabel])


    const alert = () => {
        Swal.fire({
            position: 'bottom',
            backdrop: false,
            background: '#121A1F',
            customClass: {
                container: 'alertClass',
            },
            html: 'your <label>sales indicator report</label> was downloaded',
            showConfirmButton: false,
            timer: 9000
        })
    }

    const saveIndicator = (indicator_cat) => {
        let obj = { indicator_category_id: indicator_cat.indicator_category_id, indicator_id: props.item.indicator_id };
        if (!indicator_cat) {
            console.log('Error Adding Indicator');
        }
        Swal.fire({
            background: "#121A1F",
            title: "Wait a minute",
            text: "Indicator saved successfully",
            showConfirmButton: false,
            timer: 2000,
            width: "305px",
        });
        setShowSave(false);
        props.actionKey(indicator_cat);
        props.actionSave(obj);
    }

    const removeIndicator = (indicator_cat) => {
        let obj = { indicator_category_id: props.category.indicator_category_id, indicator_id: props.item.indicator_id };
        if (!indicator_cat) {
            console.log('Error Adding Indicator');
        }
        props.actionRemove(obj);
    }

    const test = (info) => console.log(info);

    const arr_options = [
        { id: 1, name: 'Remove Indicator', action: removeIndicator },
        { id: 2, name: 'Move Indicator', action: test }
    ]

    const saveNotification = async (idFrecuendy, itemIndicator) => {
        const formData = {
            indicators: [
                {
                    uid_active: 1,
                    uid_indicator_id: itemIndicator,
                    uid_frequency: idFrecuendy,
                    uid_value: 0,
                },
            ],
        };
        try {
            const responseData = await requestSaveNotification(formData, authCheck);
            if (responseData) {
                Swal.fire({
                    background: "#121A1F",
                    title: "Wait a minute",
                    text: "Successfully saved",
                    showConfirmButton: false,
                    timer: 1000,
                    width: "305px",
                });
            } else {
                console.log("error");
            }
        } catch (error) {
            console.error(error);
        }
        setShow(false);
    };


    return (
        <div ref={containerRef} className={showSubItems ? ("card card-graph-bg" + ' ' + props.classItemElement) : ("card card-graph-bg" + ' ' + props.classItem)} style={{ width: props.widthCard }}>
            <div className="card-body">
                <div className="d-flex flex-row flex-wrap mb-2">
                    <div className="col-10">
                        <h5 className="card-title title-graph text-spacing fw-bold mb-1">{props.item?.indicator_name ?? 'Indicator Name'}</h5>
                        <h6 className="card-subtitle mb-2 subtitle-graph text-capitalize">{props.item?.indicator_frequency ?? 'Frequency'}</h6>
                    </div>
                    {props.showChecks ?
                        <div className="col-2 text-end d-flex flex-row flex-wrap justify-content-end">
                            <div className="form-check">
                                <input className="form-check-input check-to-compare me-2" type="checkbox" value={props.item.indicator_id} checked={props.isChecked} onChange={(e) => props.handleChange(e, objIndicators)} />
                            </div>
                        </div>
                        :
                        <div className="col-2 text-end">
                            <FiLogIn className="tool-icon" onClick={() => props.goTo && props.goTo(props?.item?.indicator_id ?? 1)} />
                        </div>
                    }
                </div>
                {(dataSerie !== []) && (
                    <React.Suspense fallback={<p>Loading...</p>}>
                        <Chart options={options} type={chartType} series={series} width="100%" height="172" />
                    </React.Suspense>
                )}
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row align-items-center'>
                        <button className="btn-clean text-beige">
                            <FiBookmark onClick={() => setShowSave(!showSave)} />
                        </button>
                        {!props.btnIcon &&
                            <button className="btn-clean text-beige">
                                <FiFolderPlus />
                            </button>}
                        <button className="btn-clean text-beige">
                            <FiDownload onClick={alert} />
                        </button>
                        {props.btnIcon &&
                            <>
                                <FiBell onClick={() => setShow(!show)} className='btn-clean text-beige' />
                                {show &&
                                    <OutsideAlerter classNameElement={"box-drop"} position={'absolute'} top={'16rem'} left={'6.5rem'} listener={show} trigger={openSwitch}>
                                        <ul>
                                            <li onClick={() => setSubDrop(!subDrop)}>Frequent report</li>
                                            <li onClick={() => setShowNotification(!showNotification)}>Personalized</li>
                                        </ul>
                                        {subDrop &&
                                            <OutsideAlerter classNameElement={"box-drop sub-drop frequency"} position={'absolute'} top={'0rem'} left={'11.5rem'} listener={subDrop} trigger={openSwitchSubItem}>
                                                <ul>
                                                    {props.indicator_frequency?.map((e, i) =>
                                                        <li key={i} onClick={() => saveNotification(e.pv_id, props.item?.indicator_id)}>{e.pv_name}</li>
                                                    )}
                                                </ul>
                                            </OutsideAlerter>
                                        }
                                    </OutsideAlerter>
                                }
                            </>
                        }
                        {props?.category?.indicator_category_user_id &&
                            <CustomDropList options={arr_options} />
                        }
                    </div>
                    {props.btnIcon &&
                        <div>
                            <button className="btn-clean text-beige">
                                <FiChevronDown onClick={() => setShowSubItems(!showSubItems)} />
                            </button>
                        </div>}
                </div>
                {showSubItems &&
                    <div className="d-flex flex-row flex-wrap mt-3">
                        <div className="col-8">
                            <h5 className="card-title title-graph">Sales volume</h5>
                            <h6 className="card-subtitle mb-2 subtitle-graph">Source: efrouting</h6>
                        </div>
                        <div className="col-4 text-end">
                            <h5 className="card-title title-graph">05/06/22</h5>
                        </div>
                        <div className="col-md-12 multiLineLabel d-flex flex-row align-items-end">
                            <p className="card-subtitle subtitle-graph text-spacingGraph">Lorem ipsum dolor sit amet consectetur adipisicing elit ipsa. Provident ipsa ea neque veritatis veniam. Sint, voluptate dignissimos animi dolorem quaerat aut soluta laborum distinctio velit a itaque, omnis odio laboriosam.</p>
                            <p className='subtitle-graph mb-0 text-green nav-item' onClick={() => setShowDetailsGraph(!showDetailsGraph)}>Learn More</p>
                        </div>
                    </div>}
                <ModalSave actionSaveToCat={saveIndicator} showSaveSettings={showSave} setShowSaveSettings={setShowSave} setIndicatorCat={setIndicatorCat} folderList={props.folderList} />
                <DetailsGraphIndicators showModal={showDetailsGraph} setShowModal={setShowDetailsGraph} />
                <NotificationsModal showNotificationsModal={showNotification} setShowNotificationsModal={setShowNotification} categoryList={props?.folderList} itemIndicator={props.item?.indicator_id} conditional_indicators={props.conditional_indicators} />
            </div>
        </div>
    );
}

GraphCard.defaultProps = {
    btnIcon: null,
    widthCard: 'calc((100%/3) - 0.5rem)',
    classItemElement: '',
    classItem: '',
    goTo: null,
};

export default GraphCard;