import React, { useEffect, useState } from 'react';
import { FiBookmark } from 'react-icons/fi';
const MessageItem = ({ userName, category, title, time, conversation, onClick, onFeatured, isOnFeatured, onUnFeatured, showCheck}) => {
    const [author, setAuthor] = useState(null);
    useEffect(() => {
        setAuthor(conversation?.attributes?.user_creator_data);
    }, [conversation]);

    return (
        <div className="message-item d-flex flex-row flex-wrap align-items-center justify-content-between px-3" onClick={(e) => {
            if (Object.keys(e.target.dataset).length === 0) {
                onClick(conversation)
            }
        }}>
            {showCheck &&
                <div className="form-check">
                <input
                    className="form-check-input check-to-compare me-2"
                    type="checkbox" value={1} defaultChecked={false}
                // onChange={(e) => props.handleChange(e, props.item)} 
                />
            </div>
            }
            {!isOnFeatured ?
                <FiBookmark className='save-icon-item' data-id="no-onclick" onClick={() => onFeatured(conversation)} />
                : 
                <FiBookmark className='save-icon-item to-unfeatured' data-id="no-onclick" onClick={() => onUnFeatured(conversation)} />
            }
            <span className='username-message-item'>{author?.user_name ?? '---'} {author?.user_last_name ?? '---'}</span>
            <div className='category-message-item'>{category}</div>
            <div className='title-message-item'>{title}</div>
            <div className='time-message-item'>{time}</div>
        </div>
    );
}

MessageItem.defaultProps = {
    userName: 'Name User',
    category: 'Feature',
    title: 'Lorem ipsum dolor sit amet.',
    time: '02:20',
    onFeatured: false,
    onUnFeatured: false,
    isOnFeatured: false,
    showCheck: true,
}

export default MessageItem;