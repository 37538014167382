import React from "react";
import { useHistory } from "react-router-dom";
import FullLayout from "../../../../components/layout/FullLayout";

const StepTwo = () => {
  const history = useHistory();
  
  return (
    <>
      <FullLayout>
        <div className="d-flex flex-row flex-wrap justify-content-center align-items-center vh-100">
          <div className="col-md-5">
            <div className="d-flex flex-row flex-wrap">
              {/* <FiCornerUpLeft
                onClick={() => goBack()}
                className="go-back-arrow me-3"
              /> */}
              <h1 className="heading-h1 fw-bold text-capitalize">
                Your payment was successful
              </h1>
            </div>
            <div className="d-flex flex-row flex-wrap">
              <p className="subheading-top mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                efficitur vulputate maximus. Fusce rutrum fringilla risus, vel
                sodales augue convallis et. Phasellus porta mollis pharetra.
              </p>
              <div className="divider-3 mb-0"></div>
              <div className="col-md-12 d-flex flex-row flex-wrap flex-gaping mt-4">
                <div className="col-md-6">
                  <button type="button" className="btn btn-secondary w-100">
                    ADMN YOUR PLAN
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-success w-100"
                    onClick={() =>
                      history.push("/settings/payment")
                    }
                  >
                    BOOK OTHER PLAN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FullLayout>
    </>
  );
};

export default StepTwo;
