import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FiHelpCircle, FiMoreHorizontal } from 'react-icons/fi';
import ChartComponent from '../../../components/ChartComponent';
import SimpleTable from '../../../components/SimpleTable';
import requestGetAnnualIndicator from '../../../services/indicators/requestGetAnnualIndicator';
import { useSelector } from 'react-redux';
import { selectGuardianState } from '../../../redux/auth/GuardianSlice';
const MixingIndicator = ({ update_action, item, type, objSearch }) => {

    const authCheck = useSelector(selectGuardianState);
    const [dataSerie, setDataSerie] = useState([]);
    const [dataLabel, setDataLabel] = useState([]);

    const [swapper, setSwapper] = useState(true);
    const [typeChart, setTypeChart] = useState('line');

    const [arrValues, setArrValues] = useState(item?.dataGraph.values);
    const [labels, setLabels] = useState(item?.dataGraph.labels);

    const [arrTable, setArrTable] = useState(item?.dataGraph.values);
    // console.log(objSearch);



    useEffect(() => {
        getIndicatorData(item)
    }, [objSearch]);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const getIndicatorData = async (element) => {
        if (!element) {
            return;
        }
        try {
            let options = { user_token: authCheck, year: objSearch.date.getFullYear(), reference: element?.indicator_reference, pagination: 8, page: 1, indicator_associate_to: element?.indicator_associate_to };
            const res = await requestGetAnnualIndicator(options, element?.indicator_frequency);
            let arr = [];
            let arrLabels = [];
            if (res.state === 200) {
                res.data.forEach((el) => {
                    arr.push(el.value);
                    var date = el.date;
                    if (!el.state) {
                        if (typeof el.date === 'string' && element?.indicator_frequency === 'monthly') {
                            date = date.replace(/-/g, '\/');
                            date = new Date(date).getMonth();
                            date = months[date];
                        } else if (typeof el.date === 'string' && element?.indicator_frequency === 'weekly') {
                            date = date.replace(/-/g, '\/');
                            date = new Date(date);
                            let month = date.getMonth();
                            date = 'Week' + (new Date(date).getWeekInMonth()) + '/' + months[month];
                        }
                        arrLabels.push(date);
                    } else {
                        arrLabels.push(el.state);
                    }
                });
                setDataSerie(arr);
                setDataLabel(arrLabels);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-3 px-2">
                    <div>
                        <div className='d-flex flex-row'>
                            <h4 className='mixing-indicator-title mb-0'>{item?.indicator_name}</h4>
                            <FiHelpCircle className="icon-h2 ms-1" />
                        </div>
                        <p className='mixing-indicator-subtitle mb-0'>{item?.indicator_unit}</p>
                    </div>
                    <div className="dropSettings neutral-drop">
                        <DropdownButton
                            drop={'down'}
                            title={<FiMoreHorizontal className='icon-h1 align-self-start' />}
                        >
                            <Dropdown.Item onClick={() => { setTypeChart('line'); setSwapper(true) }}>Chart Line</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSwapper(false)}>Board</Dropdown.Item>
                        </DropdownButton>

                    </div>
                </div>
                {swapper ? <ChartComponent data={[{ name: '---', data: arrValues }]} labels={labels} type={typeChart} /> : <SimpleTable theadData={labels} tbodyData={[{ items:  arrTable}]} />}
            </div>
        </div>
    );
}

export default MixingIndicator;